<template>
  <div v-if="recipe" id="recipe-details" class="container is-max-widescreen">
    <div  class="StoreDetailsView__content">
      <div class="StoreDetailsView__details">
        <div class="StoreDetailsView__banner">
          <div class="StoreDetailsView__bannerGroup">
            <h1 class="StoreDetailsView__title">{{ recipe.title }}</h1>
          </div>
          <b-button class="StoreDetailsView__bannerBack"
                    icon-left="arrow-left"
                    type="is-light"
                    @click="goBack"
                    :rounded="true"
          >
          </b-button>
          <b-button v-if="isMobile"
                    class="StoreDetailsView__share"
                    icon-left="share-variant"
                    type="is-primary is-light"
                    @click="share"
                    :rounded="true"
          >
          </b-button>
          <b-button class="StoreDetailsView__likes"
                    :icon-left="hasLike ? 'thumb-up' : 'thumb-up-outline'"
                    :disabled="loadingLike"
                    type="is-primary is-light"
                    :rounded="true"
                    @click="toggleLike"
          >
            {{ recipe.likes.length }}
          </b-button>
          <b-image
            class="StoreDetailsView__bg"
            :src="imageUrl"
            :alt="recipe.title"
            :src-fallback="require('../assets/images/fallback_store.png')"
          >
          </b-image>
          <favorite :entity-id="parseInt(id)" entity-name="recipes"></favorite>
        </div>
      </div>
    </div>
    <div class="container__form">
      <b-button v-if="isOwner"
                icon-left="cog"
                class="is-text mb-2"
                @click="$router.push({ name: 'modify-recipe', params: { id }})"
      >
        Editar receta
      </b-button>
      <div class="recipe__data --bordered">
        <div>
          <div class="has-text-weight-bold mb-1">Por</div>
          <div class="is-flex">
            <avatar :member="recipe.author" size="24" :is-clickable="true" ref="avatar"></avatar>
            <a class="ml-3 is-clickable" @click="$refs.avatar.click()">{{ recipe.author.name }}</a>
          </div>
        </div>
        <div>
          <span class="has-text-weight-bold">Creada</span>
          <div>{{ recipe.created | moment('DD/MM/YYYY, HH:mm:ss ') }}</div>
        </div>
      </div>
      <div class="recipe__data --bordered">
        <div>
          <div class="has-text-weight-bold">Dificultad</div>
          <div class="subtitle">
            {{ recipe.difficulty.name }}
          </div>
        </div>
        <div>
          <div class="has-text-weight-bold mb-2">Categorias</div>
          <categories-box :ids="recipe.categories.map(x => x.id)"></categories-box>
        </div>
      </div>
      <div class="ingredients --bordered">
        <div class="subtitle">Ingredientes</div>
        <li v-for="(item, idx) in recipe.ingredients"
            :key="`ingredient${idx}`"
        >
          {{ item }}
        </li>
      </div>
      <div class="description --bordered ql-container ql-snow">
        <div class="subtitle">Descripción</div>
        <div @click.capture.prevent="clickExternalLink"
             class="ql-editor"
             v-html="recipe.description"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import Avatar from '../components/Common/Avatar'
import CategoriesBox from '@/components/Recipe/CategoriesBox'
import Favorite from '@/components/Common/Favorite'
import { needLogin } from '@/modules/auth'
import { getMetaInfo } from '@/modules/meta'

export default {
  name: 'RecipeDetailsView',
  props: {
    id: {
      required: true
    }
  },
  components: {
    Avatar,
    CategoriesBox,
    Favorite
  },
  inject: ['isMobile'],
  metaInfo () {
    const title = this.recipe ? `${this.recipe.title} de ${this.recipe.author.name}` : 'Recetas'
    const image = this.recipe ? this.imageUrl : 'assets/images/hero-01.svg'
    return getMetaInfo(
      title,
      `${title} es una receta libre de gluten. Busca muchas recetas mas en Celisana!`,
      image
    )
  },
  data () {
    return {
      recipe: undefined,
      loadingLike: false
    }
  },
  methods: {
    clickExternalLink: function (event) {
      if (event.target.href) {
        this.$buefy.dialog.confirm({
          title: 'Estás saliendo de Celisana',
          message: `Vas a ingresar al siguiente sitio: <b>${event.target.href}</b>. ¿Deseas continuar?`,
          cancelText: 'Cancelar',
          confirmText: 'Continuar',
          onConfirm: () => window.open(event.target.href, 'blank')
        })
      }
    },
    share: function () {
      if (navigator.share) {
        navigator.share({
          title: `${this.$metaInfo.title} - Celisana `,
          text: `${this.$metaInfo.title} - Celisana `,
          url: window.location.href
        })
      }
    },
    toggleLike: function () {
      if (!this.getUser) {
        needLogin(this)
        return
      }
      this.loadingLike = true
      axios.post(`recipes/${this.recipe.id}/like/`)
        .then(({ data }) => {
          const userId = this.getUser.user
          if (data.result === 'add') {
            this.recipe.likes.push(userId)
          } else {
            this.recipe.likes = this.recipe.likes.filter(x => x !== userId)
          }
        })
        .catch(e => {
          this.$buefy.snackbar.open({
            duration: 3000,
            message: 'Ocurrió un error. Por favor vuelva a intentar más tarde.',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
        })
        .finally(() => (this.loadingLike = false))
    },
    goBack: function () {
      if (window.history.length > 2) {
        this.$router.go(-1)
      } else {
        this.$router.push({ name: 'recipes' })
      }
    }
  },
  computed: {
    ...mapGetters(['getUser']),
    imageUrl: function () {
      if (!this.recipe) return
      return process.env.VUE_APP_IMAGES_URL + `/recipes/${this.recipe.id}.jpg`
    },
    hasLike: function () {
      return this.getUser && this.recipe.likes.includes(this.getUser.user)
    },
    isOwner: function () {
      return this.getUser ? this.recipe.author.pk === this.getUser.user : false
    }
  },
  created () {
    const loadingComponent = this.$buefy.loading.open({})
    axios.get(`recipes/${this.id}/`)
      .then(r => {
        this.recipe = r.data
      })
      .finally(() => loadingComponent.close())
  }
}
</script>

<style scoped lang="sass">
@import '~quill/dist/quill.snow.css'

:deep(.image img)
  height: 100%
.ql-editor
  min-height: initial
.header__top
  display: none
.container__form
  padding: 0 0.5rem 2rem
  .recipe__data
    display: flex
    flex-direction: column
    gap: 0.5rem 0
.--bordered
  background-color: white
.StoreDetailsView__container
  display: flex
  flex-direction: column
  width: 100%
.StoreDetailsView__details
  display: flex
  flex-direction: column
  width: 100%
  overflow-y: auto
.StoreDetailsView__content
  display: flex
  flex-direction: column
  width: 100%
.StoreDetailsView__banner
  display: flex
  position: relative
  min-height: 251px
  width: 100%
  border-radius: 0
  margin-bottom: 1rem
  overflow: hidden
  background: #242424
  :deep(.button)
    height: 2.5rem
  :deep(.favorite)
    left: 5rem
    top: 1rem
.StoreDetailsView__bannerBack
  position: absolute
  top: 1rem
  left: 1rem
  z-index: 10
.StoreDetailsView__share
  position: absolute
  right: 1rem
  bottom: 1rem
  z-index: 10
.StoreDetailsView__likes
  position: absolute
  right: 1rem
  top: 1rem
  z-index: 10
.StoreDetailsView__title
  font-size: 1.5rem
  font-weight: 900
  padding: 1rem
  color: #FFF
  text-shadow: 0 2px 0 black
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
.StoreDetailsView__bg
  position: absolute
  left: 0
  top: 0
  height: 251px
  width: 100%
  z-index: 1
  opacity: .7
.StoreDetailsView__bannerGroup
  display: flex
  flex-direction: column
  position: relative
  z-index: 2
  justify-content: flex-end
  width: 85%
@media (min-width: 768px)
  .header__top
    display: inherit
  .StoreDetailsView__content
    padding: 1rem 1rem 0 1rem
    .StoreDetailsView__banner
      border-radius: 1rem
  .container__form
    .recipe__data
      text-align: center
      flex-direction: row
      justify-content: space-evenly
      align-items: center
      :deep(.categories)
        justify-content: center
    .description, .ingredients
      text-align: center
</style>
