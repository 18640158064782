<template>
  <b-loading :is-full-page="true" :active="true" :can-cancel="false"></b-loading>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ActivateAccountView',
  props: {
    token: {
      required: true
    }
  },
  created () {
    axios.post('signup/activate/',
      { token: this.token }
    )
      .then(({ data }) => {
        const host = window.location.origin
        window.location.href = `${host}?is_new=1&token=${data.token}`
      })
      .catch(() => {
        this.$buefy.snackbar.open({
          duration: 3000,
          message: 'La url ya no es válida.',
          type: 'is-danger',
          position: 'is-bottom-right'
        })
      })
      .finally(() => {
        this.$router.push({ name: 'home' })
      })
  }
}
</script>

<style scoped>

</style>
