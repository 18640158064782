<template>
  <div class="container">
    <section class="section">
      <h1 class="title">Registrate en celisana</h1>
      <SignUpForm   v-if="member"
                    v-model="member"
                    ref="form"
                    :is-creation="true"
                    :is-loading="isLoading"
                    @save="saveProfile"
      ></SignUpForm>
      <div class="signup__divider">
        <hr>
        <span class="signup__dividerLabel">ó continuar con</span>
        <hr>
      </div>
      <div class="my-3 signUp__gbtn">
        <button class="button extended"
                type="button"
                @click="callOauth('google')"
        >
          <span class="icon m-0 mr-3">
            <svg width="32" height="32" fill="none" viewBox="0 0 22 20">
            <path fill="#4285F4" fill-rule="evenodd" d="M19.981 10.21c0-.654-.06-1.283-.172-1.886h-8.911v3.566h5.092c-.22 1.152-.886 2.128-1.888 2.782v2.313h3.058c1.79-1.605 2.821-3.968 2.821-6.776z" clip-rule="evenodd"></path><path fill="#34A853" fill-rule="evenodd" d="M10.898 19.219c2.555 0 4.696-.826 6.262-2.234l-3.058-2.313c-.847.553-1.931.88-3.204.88-2.465 0-4.55-1.621-5.295-3.8H2.442v2.388c1.557 3.013 4.757 5.079 8.456 5.079z" clip-rule="evenodd"></path><path fill="#FBBC05" fill-rule="evenodd" d="M5.603 11.752A5.414 5.414 0 015.307 10c0-.607.107-1.198.296-1.752V5.86H2.442a9.025 9.025 0 000 8.28l3.161-2.388z" clip-rule="evenodd"></path><path fill="#EA4335" fill-rule="evenodd" d="M10.898 4.448c1.389 0 2.636.465 3.617 1.378l2.714-2.644c-1.64-1.487-3.78-2.4-6.331-2.4-3.699 0-6.9 2.065-8.456 5.078l3.161 2.388c.744-2.178 2.83-3.8 5.295-3.8z" clip-rule="evenodd"></path>
          </svg>
          </span>
          <span class="">Cuenta de Google</span>
        </button>
        <button class="button extended mt-3"
                type="button"
                @click="callOauth('facebook')"
        >
          <span class="icon m-0 mr-3">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="32px" height="32px"><linearGradient id="Ld6sqrtcxMyckEl6xeDdMa" x1="9.993" x2="40.615" y1="9.993" y2="40.615" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#2aa4f4"/><stop offset="1" stop-color="#007ad9"/></linearGradient><path fill="url(#Ld6sqrtcxMyckEl6xeDdMa)" d="M24,4C12.954,4,4,12.954,4,24s8.954,20,20,20s20-8.954,20-20S35.046,4,24,4z"/><path fill="#fff" d="M26.707,29.301h5.176l0.813-5.258h-5.989v-2.874c0-2.184,0.714-4.121,2.757-4.121h3.283V12.46 c-0.577-0.078-1.797-0.248-4.102-0.248c-4.814,0-7.636,2.542-7.636,8.334v3.498H16.06v5.258h4.948v14.452 C21.988,43.9,22.981,44,24,44c0.921,0,1.82-0.084,2.707-0.204V29.301z"/></svg>
          </span>
          <span class="">Cuenta de Facebook</span>
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import SignUpForm from '@/components/Users/SignUpForm'
import Recaptcha from '@/components/Mixins/Recaptcha'
import { loginOauth } from '@/modules/auth'
import { getMetaInfo } from '@/modules/meta'

export default {
  name: 'SignUpView',
  metaInfo () {
    return getMetaInfo(
      'Registrate',
      'Registrate en Celisana, sumate a nuestra comunidad y compartí tus experiencias',
      'assets/images/hero-01.svg'
    )
  },
  mixins: [
    Recaptcha
  ],
  components: {
    SignUpForm
  },
  data () {
    return {
      member: {
        province_id: '1',
        bio: '-',
        gender: 'I'
      },
      isLoading: false
    }
  },
  methods: {
    callOauth: function (provider) {
      this.trackSignUp(provider)
      loginOauth(provider)
    },
    saveProfile: async function () {
      this.isLoading = true
      this.member.token = await this.getRecaptchaToken('sign_up')
      this.member.name = this.member.user.split('@')[0]
      axios.post('signup/', this.member)
        .then(({ data }) => {
          this.trackSignUp('email')
          this.showSnackbar({
            message: 'Tu cuenta se ha creado correctamente! Te enviamos un email para que puedas activar tu cuenta y terminar el proceso de alta.',
            type: 'is-success',
            duration: 10000
          })
          this.$router.push({ name: 'home' })
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            this.$refs.form.$refs.validator.setErrors(response.data.errors)
          } else {
            this.showSnackbar({
              message: response.data.message,
              type: 'is-danger'
            })
          }
        })
        .finally(() => { this.isLoading = false })
    },
    showSnackbar: function (payload) {
      this.$buefy.snackbar.open({
        duration: payload.duration || 3000,
        message: payload.message,
        type: payload.type,
        position: 'is-bottom-right'
      })
    },
    trackSignUp: function (method) {
      this.$gtm.trackEvent({
        event: 'sign_up',
        value: method
      })
    }
  }
}
</script>

<style scoped lang="sass">
.signup__divider
  display: flex
  align-items: center
  max-width: 500px
  width: 100%
  margin: 0 auto
  text-align: center
  margin-top: 1rem
  &Label
    padding: 0 2rem
  hr
    height: 1px
    background-color: black
    flex: 1
.title
  text-align: center
  width: 100%
  margin: 4rem 0

.signUp__gbtn
  max-width: 380px
  margin: 0 auto
  .button
    width: 100%
:deep()
  .createAcc
    .button
      align-self: flex-end
</style>
