import { render, staticRenderFns } from "./FounderMember.vue?vue&type=template&id=26426776&scoped=true&"
import script from "./FounderMember.vue?vue&type=script&lang=js&"
export * from "./FounderMember.vue?vue&type=script&lang=js&"
import style0 from "./FounderMember.vue?vue&type=style&index=0&id=26426776&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26426776",
  null
  
)

export default component.exports