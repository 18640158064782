<template>
  <div class="container">
    <b-loading :is-full-page="true" v-model="isFetching" :can-cancel="false"></b-loading>
    <store-form v-if="store"
                ref="form"
                :is-modify="true"
                :initial-store="store"
                :initial-province-id="provinceId"
                :initial-location-name="locationName"
                :is-loading="isLoading"
                @submit="callApi"
    >
    </store-form>
  </div>
</template>

<script>
import axios from 'axios'
import { cloneDeep, omit, isEqual } from 'lodash'
import StoreForm from '@/components/NewStore/StoreForm'

export default {
  name: 'UpdateStore',
  props: {
    id: {
      required: true
    }
  },
  components: {
    StoreForm
  },
  metaInfo () {
    return {
      title: 'Solicitud de cambio'
    }
  },
  data () {
    return {
      isFetching: true,
      isLoading: false,
      initialStore: undefined,
      store: undefined,
      locationName: undefined,
      provinceId: undefined
    }
  },
  methods: {
    callApi: function () {
      const newStore = this.$refs.form.store
      const keys = Object.keys(omit(this.initialStore, ['types', 'author', 'location']))
      let diff = keys.map(key => {
        if (!isEqual(this.initialStore[key], newStore[key])) {
          const item = {}
          item[key] = newStore[key]
          return item
        }
      })
      diff = diff.filter(Boolean)
      if (!diff.length) {
        this.$router.go(-1)
        return
      }

      this.isLoading = true
      axios.post('stores/update/', {
        store_data: diff,
        store_id: this.id
      })
        .then(async ({ data }) => {
          this.$buefy.dialog.alert({
            title: 'Alta exitosa',
            message: 'Tu solicitud se cargó <b>exitosamente</b>.<br> En las próximas horas nuestros moderadores van a ' +
              'revisar tu solicitud para comprobar que la información cargada sea válida y completa para su modificación.' +
              '<br>¡Muchas gracias por colaborar con nosotros!',
            confirmText: 'Aceptar'
          })
          this.$router.push({ name: 'home' })
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            this.$refs.form.$refs.validator.setErrors(response.data.errors)
          } else {
            this.$buefy.snackbar.open({
              duration: 5000,
              message: response.data.message,
              type: 'is-danger',
              position: 'is-bottom-right'
            })
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getStore: function () {
      axios.get(`stores/${this.id}/`)
        .then(({ data }) => {
          this.locationName = data.location.name
          this.provinceId = data.location.province.toString()
          data.location_id = data.location.id
          data.types_ids = data.types.map(x => { return x.id })
          this.store = data
          this.initialStore = cloneDeep(this.store)
        })
        .catch(({ response }) => {
          this.$buefy.snackbar.open({
            duration: 3000,
            message: 'Ocurrió un error inesperado, reintente más tarde',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
        })
        .finally(() => {
          this.isFetching = false
        })
    }
  },
  mounted () {
    this.getStore()
  }
}
</script>

<style scoped>

</style>
