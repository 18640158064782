<template>
  <b-dropdown :value="params.ordering"
              @input="e => $emit('changeSelect', {
                      val: e,
                      key: 'ordering'
                    })"
  >
    <template #trigger>
      <b-icon icon="filter-variant"></b-icon>
    </template>
    <b-dropdown-item v-for="item in [
                      { id: '-created', icon: 'new-box', label: 'Recientes', description: 'Ordenar las recetas por fecha' },
                      { id: '-likes', icon: 'thumb-up-outline', label: 'Populares', description: `Ordenar las recetas por ME GUSTA` },
                    ]"
                     :value="item.id" :key="item.id"
    >
      <div class="media">
        <b-icon class="media-left" :icon="item.icon"></b-icon>
        <div class="media-content">
          <h3>{{ item.label }}</h3>
          <small>{{ item.description }}</small>
        </div>
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  name: 'MobileOrdering',
  props: {
    params: {
      required: true
    }
  }
}
</script>

<style scoped>

</style>
