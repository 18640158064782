<template>
  <ValidationProvider v-slot="{ errors }" :name="label" :rules="validate" :vid="name">
    <b-field :label="hideLabel ? '' : label"
             :type="errors[0] ? 'is-danger' : (value ? fieldType : '')"
             :message="errors[0] || message"
             :expanded="expanded"
             :class="{'has-right-click': iconRight}"
    >
      <b-input
        :id="name"
        :type="type"
        :icon="icon"
        :icon-right="iconRight"
        :icon-right-clickable="Boolean(iconRight)"
        :value="value"
        :placeholder="placeholder"
        :password-reveal="type=== 'password'"
        :maxlength="maxlength"
        :disabled="disabled"
        @input="e => $emit('input', e)"
        @blur="$emit('blur')"
        @icon-right-click="$emit('icon-right-click')"
      >
      </b-input>
      <slot></slot>
    </b-field>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'InputText',
  props: {
    label: {
      required: false
    },
    name: {
      required: true
    },
    value: {
      required: true
    },
    placeholder: {
      required: false
    },
    validate: {
      required: false,
      default: ''
    },
    type: {
      required: false,
      default: 'text'
    },
    fieldType: {
      required: false
    },
    maxlength: {
      required: false
    },
    disabled: {
      required: false
    },
    message: {
      required: false
    },
    hideLabel: {
      required: false
    },
    expanded: {
      required: false
    },
    icon: {
      required: false
    },
    iconRight: {
      required: false
    }
  }
}
</script>

<style scoped lang="sass">
.field
  margin-bottom: 0.5rem
.has-right-click
  :deep()
    .icon.is-right
      background: var(--color-primary)
      margin: 0.25rem
      height: calc(100% - 0.5rem) !important
      border-radius: .75rem
    .control.has-icons-right .input, .control.has-icons-right .select select
      padding-right: 3rem
</style>
