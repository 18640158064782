<template>
  <div :class="['avatar', { 'is-clickable': isClickable }]" @click="isClickable ? click() : null"
  >
    <b-image
      :rounded="isRounded"
      :title="member.name"
      :alt="member.name"
      :src="imageUrl"
      :src-fallback="fallbackProfileImage"
    >
    </b-image>
  </div>
</template>

<script>
export default {
  props: {
    member: {
      required: true
    },
    size: {
      required: false,
      default: 64
    },
    isClickable: {
      required: false
    },
    isRounded: {
      required: false,
      default: true
    }
  },
  name: 'Avatar',
  methods: {
    click: function () {
      this.$router.push({ name: 'profile', params: { id: this.member.pk } })
    }
  },
  computed: {
    fallbackProfileImage: function () {
      return require(`@/assets/images/profile-skeletons/${this.member.gender.toLowerCase()}.jpg`)
    },
    imageUrl: function () {
      return `https://www.gravatar.com/avatar/${this.member.image_hash}?s=${this.size}&d=identicon`
    }
  },
  mounted () {
    this.$emit('mounted', this.imageUrl)
  }
}
</script>

<style lang="sass" scoped>
.avatar
  border-radius: 50%
  overflow-y: hidden
  box-shadow: 0 0 0 2px #4a4a4a
</style>
