import { render, staticRenderFns } from "./NewRecipeView.vue?vue&type=template&id=38436118&scoped=true&"
import script from "./NewRecipeView.vue?vue&type=script&lang=js&"
export * from "./NewRecipeView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38436118",
  null
  
)

export default component.exports