<template>
  <section  v-if="members.length"
            class="section top__members"
  >
    <div class="title">Top colaboradores</div>
    <div class="subtitle">Usuarios que mas comentaron en<br><b>los últimos 7 días</b></div>
    <div class="member__container" :class="{'--is-mobile': isMobile }">
      <top-member v-for="member in topMembers"
                  :key="member.pk"
                  :member="member"
                  @click="click"
      >
      </top-member>
    </div>
  </section>
</template>

<script>
import TopMember from './TopMember'
import { orderBy } from 'lodash'

export default {
  name: 'TopMembers',
  props: {
    members: {
      required: true,
      default: () => []
    }
  },
  inject: ['isMobile'],
  components: {
    TopMember
  },
  methods: {
    click: function (id) {
      this.$router.push({ name: 'profile', params: { id } })
    }
  },
  computed: {
    topMembers: function () {
      return orderBy(this.members, 'comments', 'desc')
    }
  }
}
</script>

<style scoped lang="sass">
.top__members
  width: 100%
  display: flex
  flex-direction: column
  text-align: center
.subtitle
  b
    font-weight: 900
    color: #7555f6
.member__container
  display: flex
  align-content: space-between
  flex-direction: column
  align-items: center
  margin-top: 2rem
  &.--is-mobile
    justify-content: center
    flex-wrap: wrap
    flex-direction: row
.member_item
  border-radius: 8px
  border: 1px solid #e3e3e3
  padding: 1rem
  box-shadow: 2px 4px 15px #c7c7c7
  transition: box-shadow 0.2s ease-in-out
  cursor: pointer
  margin: 1rem
  &:hover
    box-shadow: 4px 8px 20px #a5a5a5
</style>
