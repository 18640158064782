import { mapGetters } from 'vuex'
import { needLogin } from '@/modules/auth'

export default {
  computed: {
    ...mapGetters(['getToken'])
  },
  watch: {
    getToken: {
      immediate: true,
      handler () {
        needLogin(this, { redirectOnClose: 'home' })
      }
    }
  }
}
