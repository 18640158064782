import Vue from 'vue'
import Buefy from 'buefy'
import '@/assets/buefy.scss'

Vue.use(Buefy, {
  defaultIconPack: 'mdi',
  defaultTooltipType: 'is-black',
  defaultContainerElement: '#content',
  defaultDialogCancelText: 'Cancelar',
  defaultDialogConfirmText: 'Confirmar'
})
