<template>
  <div id="store-details" class="StoreDetailsView">
    <b-loading :is-full-page="true" v-model="isFetching" :can-cancel="false"></b-loading>
    <div v-if="store" class="StoreDetailsView__container">
      <div class="header__top" v-if="!isMobile">
        <div class="header">
          <div class="container">
            <SearchFormResults></SearchFormResults>
            <div class="back_button"
                 @click="goBack"
            >
              <b-icon icon="arrow-left"></b-icon>
              <div>Volver a resultados</div>
            </div>
          </div>
        </div>
      </div>
      <div class="StoreDetailsView__content container">
        <div class="StoreDetailsView__details">
          <div class="StoreDetailsView__pane">
            <div class="StoreDetailsView__banner">
              <div class="StoreDetailsView__bannerGroup">
                <h1 class="StoreDetailsView__title">{{ store.name }}</h1>
              </div>
              <div v-if="isMobile">
                <b-button class="StoreDetailsView__bannerBack"
                          icon-left="arrow-left"
                          type="is-light"
                          @click="goBack"
                          :rounded="true"
                >
                </b-button>
                <b-button class="StoreDetailsView__share"
                          icon-left="share-variant"
                          type="is-primary is-light"
                          @click="share"
                          :rounded="true"
                >
                </b-button>
              </div>
              <b-button class="StoreDetailsView__bannerComments"
                        icon-left="message-reply-text"
                        type="is-primary is-light"
                        :rounded="true"
                        @click="scrollTo('comments')"
              >
                {{ store.comments_count }}
              </b-button>
              <favorite :entity-id="parseInt(id)" entity-name="stores"></favorite>
              <b-image
                class="StoreDetailsView__bg"
                :src="imageUrl"
                :src-fallback="require('../assets/images/fallback_store.png')"
                :alt="store.name"
              >
              </b-image>
            </div>
            <b-message v-if="store.draft"
                       type="is-warning"
                       has-icon
            >
              ¡ATENCION! Este comercio aun no ha sido moderado, por lo cual no aparecerá en las búsquedas temporalmente.<br>
              Por favor aguarde que nuestros moderadores comprueben los datos del lugar para su correcta alta en el sitio.
            </b-message>
            <div class="StoreDetailsView__form">
              <div class="StoreDetailsView__score">
                <b-field label="Reputación">
                  <b-rate :show-score="true"
                          :value="parseFloat(store.avg_score)"
                          :disabled="true"
                          spaced
                          size="is-medium">
                  </b-rate>
                </b-field>
                <div class="StoreDetailsView__categories">
                  <b-field label="Clasificación">
                    <categories :store="store"></categories>
                  </b-field>
                </div>
              </div>
              <div class="StoreDetailsView__info"
                   :class="{ 'mt-4': isMobile }"
              >
                <div class="StoreDetailsView__infoRow">
                  <b-field>
                    <template #label>
                      <div class="StoreDetailsView__inputLabel">
                        <b-icon icon="map-marker"></b-icon>
                        <a @click="searchLocation">{{ store.location.name }}</a>
                      </div>
                    </template>
                    <b-input disabled expanded :value="(store.address)"></b-input>
                  </b-field>
                </div>
                <div class="StoreDetailsView__infoRow --col">
                  <b-field v-if="store.email">
                    <template #label>
                      <div class="StoreDetailsView__inputLabel">
                        <b-icon icon="email"></b-icon>
                        <span>Email</span>
                      </div>
                    </template>
                    <b-input disabled :value="(store.email)"></b-input>
                  </b-field>
                  <b-field v-if="store.telephone">
                    <template #label>
                      <div class="StoreDetailsView__inputLabel">
                        <b-icon icon="phone"></b-icon>
                        <span>Telefono</span>
                      </div>
                    </template>
                    <b-input disabled :value="(store.telephone)"></b-input>
                  </b-field>
                  <b-field v-if="store.site_url" class="StoreDetailsView__inputInput" grouped>
                    <template #label>
                      <div class="StoreDetailsView__inputLabel">
                        <b-icon icon="web"></b-icon>
                        <span>Sitio Web</span>
                      </div>
                    </template>
                    <b-input expanded disabled :value="store.site_url"></b-input>
                    <span class="StoreDetailsView__InputBtn">
                    <b-button type="is-primary" size="is-medium" icon-left="open-in-new" @click="goToWeb(store.site_url)"></b-button>
                  </span>
                  </b-field>
                  <b-field v-if="store.facebook" class="StoreDetailsView__inputInput" grouped>
                    <template #label>
                      <div class="StoreDetailsView__inputLabel">
                        <b-icon icon="facebook"></b-icon>
                        <span>Facebook</span>
                      </div>
                    </template>
                    <b-input expanded disabled :value="store.facebook"></b-input>
                    <span class="StoreDetailsView__InputBtn">
                    <b-button type="is-primary" size="is-medium" icon-left="open-in-new" @click="goToWeb(store.facebook)"></b-button>
                  </span>
                  </b-field>
                  <b-field v-if="store.instagram" class="StoreDetailsView__inputInput" grouped>
                    <template #label>
                      <div class="StoreDetailsView__inputLabel">
                        <b-icon icon="instagram"></b-icon>
                        <span>Instagram</span>
                      </div>
                    </template>
                    <b-input expanded disabled :value="store.instagram"></b-input>
                    <span class="StoreDetailsView__InputBtn">
                    <b-button type="is-primary" size="is-medium" icon-left="open-in-new" @click="goToWeb(store.instagram)"></b-button>
                  </span>
                  </b-field>
                </div>
                <div class="StoreDetailsView__infoRow --btns">
                  <b-button type="is-ghost is-rounded"
                            :expanded="isMobile"
                            icon-left="alert"
                            @click="goWithLogin('complaint-store')">
                    Solicitud de baja
                  </b-button>
                  <b-button type="is-primary is-rounded"
                            :expanded="isMobile"
                            icon-left="pencil"
                            @click="goWithLogin('update-store')">
                    Solicitud de cambio
                  </b-button>
                </div>
              </div>
            </div>
          </div>
          <div class="StoreDetailsView__community">
            <!-- <b-field>
              <template #label>
                <div class="StoreDetailsView__inputLabel">
                  <b-icon icon="account-group"></b-icon>
                  <span>Comunidad</span>
                </div>
              </template>
            </b-field> -->
            <div class="StoreDetailsView__communityContainer">
              <div class="StoreDetailsView__communityUser">
                <avatar :member="store.author" :is-clickable="true" size="32"></avatar>
                <div class="StoreDetailsView__communityInfo">
                  <div>
                    <b>Aporte de </b>
                    <a @click.prevent="goProfile(store.author.pk)">{{ store.author.name }}</a>
                  </div>
                  <small>
                    <b>Fecha de creación: </b>{{ store.created | moment("DD MMMM YYYY HH:mm") }}
                  </small>
                </div>
              </div>
              <div class="StoreDetailsView__communityUser" v-if="store.updated">
                <avatar :member="store.modified_by" :is-clickable="true" size="32"></avatar>
                <div class="StoreDetailsView__communityInfo">
                  <div>
                    <b>Actualizado por </b>
                    <a @click.prevent="goProfile(store.modified_by.pk)">{{ store.modified_by.name }}</a>
                  </div>
                  <small>
                    <b>Última modificación: </b>{{ store.updated | moment("DD MMMM YYYY HH:mm")}}
                  </small>
                </div>
              </div>
              <div class="StoreDetailsView__communityUser" v-else>
                <b-icon icon="account-circle"></b-icon>
                <div class="StoreDetailsView__communityInfo">
                  <div>
                    <b>Actualizado por </b>
                    <span @click.prevent>--</span>
                  </div>
                  <small>
                    <b>Última modificación: </b>--
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div class="StoreDetailsView__comments">
            <comments-box v-if="store" :store="store"></comments-box>
          </div>
        </div>
        <div class="StoreDetailsView__aside">
          <div class="StoreDetailsView__asideLocation">
            <span class="StoreDetailsView__asideIcon"><b-icon icon="compass" class="mr-2"></b-icon> Distancia Aproximada</span>
            <span v-if="store.distance !== undefined">{{ store.distance }}Km</span>
            <span class="p-3" v-else>
              Activa tu gps y hace click <a href="#" @click="getCoords">aquí</a> para ver mas detalles
            </span>
          </div>
          <map-component
            :center="store.coords"
            :zoom="16"
            :coords-list="[store]"
          ></map-component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import mapComponent from '@/components/MapComponent'
import commentsBox from '@/components/StoreDetails/CommentsBox'
import favorite from '@/components/Common/Favorite'
import Avatar from '@/components/Common/Avatar'
import Categories from '@/components/StoreDetails/Categories'
import SearchFormResults from '../components/Layout/SearchFormResults'
import { needLogin } from '@/modules/auth'
import { getDistance, getActualCoords } from '../modules/coords'
import { round } from 'lodash'
import { mapGetters } from 'vuex'
import { getMetaInfo } from '@/modules/meta'

export default {
  name: 'StoreDetailsView',
  props: {
    id: {
      required: true
    }
  },
  components: {
    mapComponent,
    commentsBox,
    favorite,
    Avatar,
    Categories,
    SearchFormResults
  },
  inject: ['isMobile'],
  metaInfo () {
    const fullLocation = [this.getCurrentLocation, this.getCurrentProvince].filter(x => x).join(' ')
    const title = `Comida sin TACC de ${fullLocation}`

    return getMetaInfo(
      this.storeName || title,
      `${this.storeName} tiene opciones sin tacc. Sumate a nuestra comunidad y contanos experiencias de tus lugares favoritos!`,
      this.imageUrl
    )
  },
  data () {
    return {
      storeName: undefined,
      store: undefined,
      isFetching: false
    }
  },
  methods: {
    searchLocation: function () {
      this.$store.commit('setSearch', undefined)
      this.$store.commit('setLastResults', undefined)
      // this.$store.dispatch('search', { provinceId: this.store.location.province, locationId: this.store.location.id })
      window.location.href = `/stores?locationId=${this.store.location.id}&provinceId=${this.store.location.province}`
    },
    goBack: function () {
      if (window.history.length > 2) {
        this.$router.go(-1)
      } else {
        this.searchLocation()
      }
    },
    goProfile: function (id) {
      this.$router.push({ name: 'profile', params: { id } })
    },
    goWithLogin: function (routerName) {
      needLogin(this, {
        redirectOnSuccess: routerName
      })
    },
    goToWeb: function (siteUrl) {
      window.open(siteUrl, '_blank').focus()
    },
    scrollTo: function (id) {
      document.getElementById(id).scrollIntoView({
        behavior: 'smooth'
      })
    },
    getCoords: function () {
      getActualCoords()
    },
    share: function () {
      if (navigator.share) {
        navigator.share({
          title: `${this.$metaInfo.title} - Celisana `,
          text: `${this.$metaInfo.title} - Celisana `,
          url: window.location.href
        })
      }
    }
  },
  computed: {
    imageUrl: function () {
      if (!this.store) return
      return process.env.VUE_APP_IMAGES_URL + `/stores/${this.store.id}.jpg`
    },
    ...mapGetters(['getCurrentProvince', 'getCurrentLocation', 'getUserCoords'])
  },
  created () {
    this.isFetching = true
    axios.get(`stores/${this.id}/`)
      .then(({ data }) => {
        this.store = data
        this.storeName = data.name
        this.store.avg_score = round(data.avg_score, 1)
        data.coords = data.coords.split(',')
        if (this.getUserCoords) {
          data.distance = getDistance(this.getUserCoords, data.coords)
        }
      })
      .catch(({ data }) => {
        this.$router.push({ name: 'not-found' })
      })
      .finally(() => {
        this.isFetching = false
      })
  }
}
</script>

<style scoped lang="sass">
@import "src/assets/styles/variables"

.header
  .container
    width: 100%
    .back_button
      font-size: 1.25rem
      font-weight: 600
      line-height: 1.6
      display: flex
      gap: .5rem
      align-items: center
      cursor: pointer
      color: white
      .icon
        color: $secondary
.StoreDetailsView__container
  background-color: $primary
  display: flex
  flex-direction: column
  width: 100%
.StoreDetailsView__details
  display: flex
  flex-direction: column
  width: 100%
  overflow-y: auto
  gap: 1rem
.StoreDetailsView__content
  display: flex
  flex-direction: column
  width: 100%
.StoreDetailsView__banner
  display: flex
  position: relative
  min-height: 251px
  width: 100%
  border-radius: 0
  margin-bottom: 1rem
  overflow: hidden
  background: #242424
  border-radius: $border-radius
  :deep(.button)
    height: 2.5rem
.StoreDetailsView__bannerComments
  position: absolute
  top: 1rem
  right: 1rem
  z-index: 10
.StoreDetailsView__bannerBack
  position: absolute
  top: 1rem
  left: 1rem
  z-index: 10
.StoreDetailsView__share
  position: absolute
  right: 1rem
  bottom: 1rem
  z-index: 10
.StoreDetailsView__bannerGroup
  display: flex
  flex-direction: column
  position: relative
  z-index: 2
  justify-content: flex-end
  width: 85%
.StoreDetailsView__title
  font-size: 1.5rem
  font-weight: 900
  padding: 1rem
  color: #FFF
  text-shadow: 0 2px 0 black
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
.StoreDetailsView__bg
  position: absolute
  left: 0
  top: 0
  height: 251px
  width: 100%
  z-index: 1
  opacity: .7
.StoreDetailsView__score
  display: flex
  flex-wrap: wrap
  overflow: auto
  .field
    margin-bottom: 0
    margin-right: 2rem
.StoreDetailsView__categories
  width: 100%
  .field
    margin-top: 0.5rem
    margin-right: 0
    :deep(.filters)
      width: auto
      display: block
      max-width: 100%
      .filterBtn
        cursor: default
.StoreDetailsView__aside
  width: 100%
  min-width: 310px
  padding: 1rem
  gap: 1rem
  display: flex
  flex-direction: column
.StoreDetailsView__asideLocation
  display: flex
  font-weight: bolder
  align-items: center
  justify-content: space-between
  padding: 1rem 1rem 1rem .75rem
  // margin-bottom: 1rem
  background-color: #efecfe
  border-radius: 10px
  width: 100%
.StoreDetailsView__asideIcon
  display: flex
  align-items: center
  font-size: .9rem
.StoreDetailsView__info
  display: flex
  flex-direction: column
  gap: 1.5rem
  width: 100%
.StoreDetailsView__infoRow
  &.--btns
    display: flex
    flex-wrap: wrap
    justify-content: center
  .button.is-ghost
    color: #ff6d66
  .field.is-grouped > .control:not(:last-child)
    margin-right: 0
.StoreDetailsView__inputInput
  position: relative
  .StoreDetailsView__InputBtn
    right: .8rem
    top: 2.6rem
    position: absolute
    height: 40px
    width: 40px
    button
      height: 42px
      width: 42px
      border-radius: $input-radius
.StoreDetailsView__inputLabel
  align-items: center
  display: flex
  .icon
    margin-right: .25rem
.StoreDetailsView__community
  padding: .75rem
  display: flex
  flex-direction: column
.StoreDetailsView__pane
  padding: 0.5rem 0.5rem 2rem
  background: white
  border-radius: 0 0 35px 35px
.StoreDetailsView__comments
  padding: 0 1rem
.StoreDetailsView__communityContainer
  flex-wrap: wrap
  display: flex
  align-items: center
  justify-content: space-between
  gap: 1rem
.StoreDetailsView__communityUser
  display: flex
  width: 100%
  padding: 1rem
  border-radius: 15px
  color: white
  border: 1px solid #FFF
  a
    color: $secondary
    font-weight: bolder
  .icon
    margin-right: 1rem
    height: 32px
    width: 32px
  .avatar
    height: fit-content
    width: 32px
    margin-right: 1rem
.StoreDetailsView__form
  padding: 0 .25rem
:deep()
  .StoreDetailsView__bg  img
    height: 251px
  .mapComponent
    height: 300px
    margin-bottom: 1rem
    // border: 1px solid #CCC
  .input[disabled]
    background-color: transparent
    padding: 1rem !important
    height: auto
    box-shadow: none
    font-weight: bolder
    font-size: 1.15rem
    color: #7a7a7a
    border-radius: $input-radius
    border: 1px solid #cccccc
  .favorite
    left: 5rem
    top: 1rem
@media (min-width: 768px)
  .StoreDetailsView__container
    border-radius: $border-radius
    padding: .5rem 1rem 2rem
    gap: .75rem
    .header
      display: flex
  .StoreDetailsView__details
    padding: 1rem 1rem 0 0
  .StoreDetailsView__banner
    border-radius: 20px
    margin-bottom: 0
  .StoreDetailsView__title
    font-size: 2rem
  .StoreDetailsView__content
    flex-direction: row
    gap: 1rem
    // height: calc(100vh - 106px)
    // overflow: hidden
  .StoreDetailsView__details
    max-width: 800px
    min-width: 800px
    // background-color: $white
    display: flex
    gap: 1rem
    padding: 0
    border-radius: $border-radius $border-radius 0 0
  .StoreDetailsView__form
    // margin: 0
    padding: 1rem .5rem .5rem
    gap: 2rem
    display: flex
    flex-direction: column
  .StoreDetailsView__pane
    background-color: #FFF
    border-radius: 15px 15px 35px 35px
    padding: 0.5rem
  .StoreDetailsView__infoRow
    &.--btns
      justify-content: flex-end
      button
        // width: auto
        &:last-child
          margin-left: 0.5rem
    &.--col
      display: flex
      justify-content: space-between
      flex-wrap: wrap
      & > .field
        width: calc(50% - .5rem)
  :deep()
    .mapComponent
      height: 100%
      margin-bottom: 0
    .favorite
      left: 1rem
  .StoreDetailsView__score
    flex-wrap: initial
  .StoreDetailsView__categories
    .field
      margin: initial
      :deep(.filters)
        width: auto
        display: flex
        max-width: initial
  .StoreDetailsView__communityUser
    width: calc(50% - .5rem)
  .StoreDetailsView__aside
    height: calc(100vh - 3.5rem)
    padding: 0
    position: sticky
    top: 1rem
    right: 0
    gap: 0
  .StoreDetailsView__asideLocation
    position: absolute
    z-index: 40
    bottom: 2rem
    left: 0
    right: 0
    width: calc(100% - 2rem)
    background-color: #FFF
    font-size: 20px
    border: 1px solid black
    margin: auto
</style>
