<template>
  <div class="categories">
    <b-tag v-for="item in items" :key="item.id"
           class="tag"
           :style="{ 'border-color': item.color }"
           @click="$emit('click', item.id)"
    >
      {{ item.name }}
    </b-tag>
  </div>
</template>

<script>
import categories from '../../constants/recipeCategories'

export default {
  name: 'CategoriesBox',
  props: {
    ids: {
      required: true,
      type: Array
    }
  },
  data () {
    return {
      items: []
    }
  },
  created () {
    this.items = this.ids.map(id => {
      return categories.find(x => x.id === id)
    })
  }
}
</script>

<style scoped lang="sass">
.categories
  display: flex
  flex-wrap: wrap
  gap: .5rem
  .tag
    border: 1px solid
    background: white
    font-weight: bolder
</style>
