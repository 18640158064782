<template>
  <div class="container">
    <div class="section pb-0">
      <b-steps mobile-mode="compact"
               :has-navigation="false"
               v-model="step"
      >
        <b-step-item v-for="(stepItem, stepId) in steps"
                     :key="stepId"
                     :step="stepId + 1"
                     :label="isMobile ? undefined : stepItem.name"
        />
      </b-steps>
    </div>
    <div class="section pt-2">
      <h4 class="title has-text-centered">{{ steps[step].name }}</h4>
      <ValidationObserver ref="validator" v-slot="{ invalid }">
        <component :is="steps[step].component"
                   :recipe="recipe"
                   :is-modify="isModify"
                   @back="step -= 1"
                   @next="step += 1"
        ></component>
        <navigation-buttons :invalid="invalid"
                            :hide-back="!step"
                            :show-save="isLastForm"
                            @next="step++"
                            @back="step--"
                            @save="$emit('save', recipe)"
        >
          <span slot="createBtn" v-if="isModify">Modificar receta</span>
        </navigation-buttons>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import PresentationForm from './PresentationForm'
import IngredientsForm from './IngredientsForm'
import DescriptionForm from './DescriptionForm'
import ImageForm from './ImageForm'
import ResumeForm from './ResumeForm'
import NavigationButtons from './NavigationButtons'

export default {
  props: {
    initialRecipe: {
      required: false,
      type: Object
    }
  },
  name: 'RecipeWizard',
  inject: ['isMobile'],
  components: {
    PresentationForm,
    IngredientsForm,
    DescriptionForm,
    ImageForm,
    ResumeForm,
    NavigationButtons
  },
  data () {
    return {
      step: 0,
      steps: [
        {
          name: 'Presentación',
          component: 'PresentationForm'
        },
        {
          name: 'Ingredientes',
          component: 'IngredientsForm'
        },
        {
          name: 'Descripción',
          component: 'DescriptionForm'
        },
        {
          name: 'Imagen',
          component: 'ImageForm'
        },
        {
          name: 'Resumen',
          component: 'ResumeForm'
        }
      ],
      recipe: {
        categories_ids: [],
        ingredients: []
      }
    }
  },
  computed: {
    isLastForm: function () {
      return this.step === this.steps.length - 1
    },
    isModify: function () {
      return Boolean(this.initialRecipe)
    }
  },
  created () {
    if (this.initialRecipe) {
      this.recipe = Object.assign({}, this.initialRecipe)
    }
  }
}
</script>

<style scoped>

</style>
