<template>
  <form @submit.prevent class="surface">
    <div class="modal-card" style="width: auto">
      <store-item :store="store"></store-item>
      <b-button
        label="Volver"
        icon-left="close"
        class="mb-2"
        @click="close" />
      <b-button
        label="Ir a la tienda"
        icon-left="arrow-right"
        type="is-primary"
        @click="callCallback()"
      />
    </div>
  </form>
</template>

<script>
import StoreItem from './StoreItem'

export default {
  name: 'ModalStoreMap',
  components: {
    StoreItem
  },
  props: {
    store: {
      required: true,
      type: Object
    },
    callback: {
      required: true,
      type: Function
    }
  },
  methods: {
    callCallback: function () {
      this.close()
      this.callback(this.store)
    },
    close: function () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="sass">
:deep()
  .StoreItem
    border-color: #dbdbdb
    box-shadow: none
    margin-bottom: 0.5rem
    &:hover
      background-color: initial
</style>
