<template>
  <div id="home" class="container-fluid p-0">
    <hero></hero>
    <metrics :metrics="stats ? stats.metrics : {}"></metrics>
    <quick-actions></quick-actions>
    <recipe-hero></recipe-hero>
    <div class="container">
      <categories></categories>
      <div class="top__container" :class="{'--is-mobile': isMobile }">
        <template v-if="stats">
          <top-members :members="stats.top_comments"></top-members>
          <top-stores :stores="stats.top_stores"></top-stores>
        </template>
      </div>
    </div>
    <send-by-mail/>
    <footer-component/>
  </div>
</template>

<script>
import axios from 'axios'
import Hero from '@/components/Home/Hero'
import QuickActions from '@/components/Home/QuickActions.vue'
import TopMembers from '@/components/Home/TopMembers'
import Metrics from '@/components/Home/Metrics'
import TopStores from '@/components/Home/TopStores'
import Categories from '@/components/Home/Categories'
import RecipeHero from '@/components/Recipe/RecipeHero'
import FooterComponent from '@/components/Layout/Footer'
import SendByMail from '@/components/Home/SendByMail.vue'
import { getMetaInfo } from '@/modules/meta'

export default {
  name: 'HomeView',
  components: {
    SendByMail,
    Hero,
    QuickActions,
    TopMembers,
    Metrics,
    TopStores,
    FooterComponent,
    Categories,
    RecipeHero
  },
  inject: ['isMobile'],
  metaInfo () {
    return {
      titleTemplate: 'Celisana - Comunidad Gluten Free',
      ...getMetaInfo(
        'Celisana - Comunidad Gluten Free',
        'Busca restaurantes, comercios, noticias y recetas para celíacos.' +
        ' Encontrá alimento sin gluten en nuestro mapa interactivo.',
        'assets/images/hero-01.svg'
      )
    }
  },
  data () {
    return {
      stats: undefined
    }
  },
  created () {
    this.$store.dispatch('cleanLocationSearch')
    axios.get('home/stats/')
      .then(({ data }) => {
        this.stats = data
      })
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles/variables"
#home
  min-height: 100vh
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)), url('../assets/images/hero_bg.png')
  background-size: 900px
// llevar al componente de heroForm
.top__container
  display: flex
  flex-wrap: wrap
  &.--is-mobile
    flex-direction: column
  :deep()
    .top__members
      width: 100%
    .top__stores
      width: 100%
.container-fluid
  padding: 0 1rem
@media (min-width: 768px)
  .top__container
    justify-content: space-between
    :deep()
      .top__members, .top__stores
        width: calc(50% - 1rem)
</style>
