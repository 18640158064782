<template>
  <div class="container">
    <input-text label="Titulo"
                name="title"
                validate="required|max:60"
                icon="chef-hat"
                placeholder="El titulo de tu receta"
                v-model="recipe.title"
    ></input-text>
    <difficulties v-model="recipe.difficulty_id"></difficulties>
    <input-categories v-model="recipe.categories_ids"></input-categories>
  </div>
</template>

<script>
import InputText from '../Common/InputText'
import InputCategories from './InputCategories'
import Difficulties from './Difficulties'

export default {
  name: 'FormPresentation',
  inject: ['isMobile'],
  props: {
    recipe: {
      required: true
    }
  },
  components: {
    InputText,
    InputCategories,
    Difficulties
  }
}
</script>

<style scoped lang="sass">
</style>
