import { render, staticRenderFns } from "./CafecitoLogo.vue?vue&type=template&id=7efa3546&scoped=true&"
import script from "./CafecitoLogo.vue?vue&type=script&lang=js&"
export * from "./CafecitoLogo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7efa3546",
  null
  
)

export default component.exports