<template>
  <div class="container">
    <div class="section">
      <p class="subtitle">Ingrese su nueva contraseña</p>
      <ValidationObserver ref="validator" v-slot="{ invalid }">
        <form @submit.prevent="updatePassword">
          <password-confirm v-model="payload.new"></password-confirm>
          <b-button
            class="is-pulled-right mt-3"
            icon-left="content-save"
            :disabled="isLoading || invalid"
            label="Confirmar"
            type="is-primary"
            native-type="submit"
            :loading="isLoading"
          />
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import PasswordConfirm from '@/components/Users/PasswordConfirm'

export default {
  name: 'ResetPasswordView',
  components: {
    PasswordConfirm
  },
  props: {
    token: {
      required: true
    }
  },
  metaInfo () {
    return {
      title: 'Restablecer contraseña'
    }
  },
  data () {
    return {
      isLoading: false,
      url: 'profile/reset-password/',
      payload: {
        token: this.token
      }
    }
  },
  methods: {
    updatePassword: function () {
      this.isLoading = true
      axios.post(this.url, this.payload)
        .then(({ data }) => {
          this.showSnackbar({
            message: 'Tu contraseña se actualizó correctamente.',
            type: 'is-success'
          })
          this.$router.push({ name: 'home' })
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            this.$refs.validator.setErrors(response.data.errors)
          } else {
            this.showSnackbar({
              message: 'Url inválida',
              type: 'is-danger'
            })
          }
        })
        .finally(() => { this.isLoading = false })
    },
    showSnackbar: function (payload) {
      this.$buefy.snackbar.open({
        duration: 3000,
        message: payload.message,
        type: payload.type,
        position: 'is-bottom-right'
      })
    }
  }
}
</script>

<style scoped>

</style>
