<template>
  <article class="new-card" @click="$router.push({name: 'news-detail', params: { id: item.id, title: item.title }})">
    <b-image
      :src="image"
      :src-fallback="require('../../assets/images/fallback_store.png')"
      :alt="item.title"
      class="recipe__image"
    >
    </b-image>
    <div class="body">
      <div class="title">
        {{ item.title }}
      </div>
      <div class="card_footer">
        <div class="author">
          <figure class="image is-32x32">
            <img class="is-rounded" :src="item.author.image.url" alt="author">
          </figure>
          <span>{{ item.author.displayName }}</span>
        </div>
        <small class="date">
          {{ item.published | moment("from") }}
        </small>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  props: {
    item: {
      required: true,
      type: Object
    }
  },
  computed: {
    image: function () {
      return this.$get(this.item, 'images.0.url', require('../../assets/images/fallback_store.png'))
    }
  }
}
</script>

<style scoped lang="sass">
@import "src/assets/styles/variables"

.new-card
  border-radius: $border-radius
  background: $primary
  cursor: pointer
  background: white
  border: 1px solid black
  box-shadow: 0 5px 0 0 rgba(199, 199, 199, 0.26)
  transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out, background-color 0.2s ease
  display: flex
  flex-direction: column
  width: 100%
  &:hover
    box-shadow: 0 0 0 0 rgba(199, 199, 199, .26)
    background-color: #f4f1ff
  :deep(.recipe__image img)
    border-top-left-radius: $border-radius
    border-top-right-radius: $border-radius
    object-fit: cover
    height: 200px
    width: 100%
  .body
    padding: 1rem
    .title
      font-size: 1.25rem
      line-height: 1.2
      letter-spacing: 0.05rem
      font-weight: bold
      padding: 0.5rem 0 1rem
      margin-bottom: 0.5rem
    .card_footer
      display: flex
      justify-content: space-between
      align-items: center
      .date
        font-size: 0.9rem
        display: flex
        justify-content: flex-end
        color: #6E798C
      .author
        align-items: center
        display: flex
        gap: .5rem
@media (min-width: 768px)
  .new-card
    max-width: calc(50% - 0.5rem)
@media (min-width: 1216px)
  .new-card
    max-width: calc(33% - 0.5rem)
</style>
