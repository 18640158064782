<template>
  <div class="container">
    <WhiteNavbar v-if="isMobile">
      <div class="header">
        {{ $route.meta.title }}
      </div>
    </WhiteNavbar>
    <b-loading :is-full-page="!isMobile"
               v-model="loading"
               :can-cancel="false"
               v-if="loading"
    ></b-loading>
    <div class="section" v-else>
      <div class="items">
        <NewItem
          v-for="item in blogs"
          :key="item.id"
          :item="item"
        >
          {{ item.title }}
        </NewItem>
      </div>
      <div class="more mt-5" v-if="nextToken">
        <b-button
          class="is-primary"
          icon-left="reload"
          :loading="loading"
          @click="getMore"
          :expanded="isMobile"
        >
          Ver más
        </b-button>
      </div>
    </div>
    <div v-if="blogs.length" class="decoration"></div>
  </div>
</template>

<script>
import axios from 'axios'
import NewItem from '@/components/Blog/NewItem.vue'
import WhiteNavbar from '@/components/Layout/WhiteNavbar.vue'
import { getMetaInfo } from '@/modules/meta'

export default {
  components: {
    NewItem,
    WhiteNavbar
  },
  metaInfo () {
    return getMetaInfo(
      'Noticias',
      'Enterate de las últimas noticias sin gluten en Celisana',
      'assets/images/hero2.svg'
    )
  },
  inject: ['isMobile'],
  data () {
    return {
      loading: true,
      blogs: [],
      nextToken: undefined
    }
  },
  methods: {
    getMore: function () {
      this.loading = true
      axios.get('blog/', { params: { token: this.nextToken } })
        .then(({ data }) => {
          this.blogs = this.blogs.concat(data.items)
          this.nextToken = data.nextPageToken
        })
        .catch((e) => {
          this.$buefy.snackbar.open({
            duration: 3000,
            message: 'Ha ocurrido un error. Reintente mas tarde',
            type: 'is-error'
          })
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => this.loading = false)
    }
  },
  mounted () {
    this.getMore()
  }
}
</script>

<style scoped lang="sass">
@import "src/assets/styles/variables"
.decoration
  padding: 1.75rem 0
  background-image: url(../assets/images/pttrn.svg)
  background-position: left bottom
  background-repeat: repeat-x
  background-size: 9rem
.header
  font-size: 1.5rem
  font-weight: 700
  text-align: center
  flex: 1
  padding-right: 4.5rem
.section
  margin-top: 3rem
  min-height: 80vh
.items
  display: flex
  flex-direction: column
  flex-wrap: wrap
  gap: 1rem

@media (min-width: 768px)
  .section
    margin-top: 0
  .items
    flex-direction: row
</style>
