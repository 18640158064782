<template>
  <div>
    <template v-if="isModify">
      <span class="has-text-primary has-text-weight-bold">Imagen Actual</span>
      <div class="preview --bordered">
        <div class="content">
          <img :src="currentImage" height="200" class="image">
        </div>
      </div>
      <b-button @click="changeImage = !changeImage"
                icon-left="menu-down"
                class="mb-3"
      >Cambiar imagen
      </b-button>
    </template>
    <b-message v-else
               type="is-info"
    >
      Esta foto será usada en nuestro buscador de recetas. Busca la mejor que tengas para que tu receta resalte entre las demas!<br>
      Por favor, evita pegar imagenes con copyright o que No sean propias.
    </b-message>
    <transition name="fade" mode="out-in">
      <input-image v-if="!isModify || (isModify && changeImage)"
                   :image-url="recipe.image_url"
                   :image="recipe.image"
                   @add="addImage"
                   @delete="deleteImages"
      ></input-image>
    </transition>
  </div>
</template>

<script>
import InputImage from '../Common/InputImage'

export default {
  name: 'FormImageUpload',
  props: {
    recipe: {
      required: true
    },
    isModify: {
      required: false
    }
  },
  components: {
    InputImage
  },
  data () {
    return {
      changeImage: this.isModify ? this.recipe.image_url || this.recipe.image : 'false'
    }
  },
  methods: {
    addImage: function (prop) {
      this.deleteImages()
      this.recipe[prop.var] = prop.value
    },
    deleteImages: function () {
      delete this.recipe.image
      delete this.recipe.image_url
    }
  },
  computed: {
    currentImage: function () {
      return `${process.env.VUE_APP_IMAGES_URL}/recipes/${this.recipe.id}.jpg`
    }
  }
}
</script>

<style scoped lang="sass">
</style>
