const categories = [
  {
    id: 1,
    name: 'Dulce',
    color: '#18b355'
  },
  {
    id: 2,
    name: 'Salado',
    color: '#ff4141'
  },

  {
    id: 3,
    name: 'Postre',
    color: '#2c93e2'
  },
  {
    id: 4,
    name: 'Plato Principal',
    color: '#118c98'
  },
  {
    id: 5,
    name: 'Aperitivo',
    color: '#d25f02'
  },
  {
    id: 6,
    name: 'Desayuno',
    color: '#d603f8'
  }
]

export default categories
