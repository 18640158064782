<template>
  <div class="no_results">
    <img class="img__not_found" :src="require('../../assets/images/not_found_list.png')" alt="not_found">

    <p class="hits-empty-state-title mt-4">
      No hay resultados para tu busqueda actual.
    </p>
    <p class="hits-empty-state-description">
      Podes crear una nueva receta haciendo click <a class="is-clickable has-text-weight-bold" @click="goNewRecipe">acá</a>
    </p>
  </div>
</template>

<script>
import { needLogin } from '../../modules/auth'

export default {
  name: 'NoResults',
  methods: {
    goNewRecipe: function () {
      needLogin(this, { redirectOnSuccess: 'new-recipe' })
    }
  }
}
</script>

<style scoped lang="sass">
.no_results
  font-size: 1.1rem
  text-align: center
  align-items: center
  display: flex
  flex-direction: column
  width: 100%
  margin: 3rem 0
  .img__not_found
    height: 300px
    padding: 1rem
@media (min-width: 768px)
  .no_results
    min-width: 550px
</style>
