<template>
  <div>
    <b-field label="Subir imagen desde">
      <b-radio-button v-model="imageType"
                      native-value="file"
                      type="is-primary">
        <b-icon icon="image"></b-icon>
        <span>Archivo</span>
      </b-radio-button>

      <b-radio-button v-model="imageType"
                      native-value="url"
                      type="is-primary"
      >
        <b-icon icon="link"></b-icon>
        <span>Url</span>
      </b-radio-button>
    </b-field>
    <input-text v-if="imageType === 'url'"
                v-model="url"
                name="url"
                field-type="is-success"
                label="Url de la imagen"
                icon="link"
                placeholder="Url de la imagen"
                validate="required|url"
                :expanded="true"
                @blur="updateUrl"
    >
    </input-text>
    <ValidationProvider v-else
                        v-slot="{ errors }"
                        name="Imagen"
                        rules="required|size:6200|mimes:image/*"
    >
      <b-field label="Imagen"
               :message="errors[0]"
               :type="errors[0] ? 'is-danger' : ''"
               class="mb-2"
      >
        <b-upload v-model="file" name="url">
          <a class="button is-fullwidth" :class="errors[0] ? 'is-danger': 'is-primary'">
            <b-icon icon="upload"></b-icon>
            <span>{{ file ? file.name : "Subir imagen"}}</span>
          </a>
        </b-upload>
        <b-button v-if="file"
                  type="is-light"
                  class="ml-3"
                  icon-right="delete"
                  @click="deleteImage"
        />
      </b-field>
    </ValidationProvider>
    <template v-if="preview">
      <span class="has-text-primary has-text-weight-bold">Vista previa</span>
      <div class="preview --bordered">
        <div class="content">
          <img :src="preview" height="200" class="image">
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import InputText from '@/components/Common/InputText'

export default {
  name: 'InputImage',
  props: {
    image: {
      required: false
    },
    imageUrl: {
      required: false
    }
  },
  components: {
    InputText
  },
  watch: {
    file: {
      handler (value) {
        if (value) {
          this.preview = URL.createObjectURL(value)
          this.updateImage(value)
        }
      }
    },
    imageType: {
      handler () {
        this.deleteImage()
        this.url = ''
      }
    }
  },
  data () {
    return {
      imageType: this.imageUrl ? 'url' : 'file',
      file: undefined,
      url: '',
      preview: '',
      mounted: false
    }
  },
  methods: {
    deleteImage: function () {
      this.preview = ''
      this.file = undefined
      this.$emit('delete')
    },
    updateImage: function (image) {
      this.$emit('add', {
        var: 'image',
        value: image
      })
    },
    updateUrl: function () {
      this.preview = this.url
      this.$emit('add', {
        var: 'image_url',
        value: this.url
      })
    }
  },
  created () {
    this.file = this.image
    this.url = this.imageUrl
  },
  mounted () {
    this.mounted = true
  }
}
</script>

<style lang="sass">
  .preview
    display: flex
    justify-content: center
    .image
      align-items: center
      max-width: 100%
      max-height: 300px
      border-radius: 1rem
</style>
