<template>
  <div class="no_results">
    <img class="img__not_found" :src="require('../../assets/images/not_found.png')" alt="not_found">

    <p class="hits-empty-state-title mt-4">
      No hay resultados para tu busqueda actual.
    </p>
    <p class="hits-empty-state-description">
      Si deseas agregar un nuevo comercio, presiona <a class="is-clickable has-text-weight-bold" @click="goNewStore">acá</a>
    </p>
  </div>
</template>

<script>
import { needLogin } from '@/modules/auth'

export default {
  name: 'NoResults',
  methods: {
    goNewStore: function () {
      needLogin(this, { redirectOnSuccess: 'new-store' })
    }
  }
}
</script>

<style scoped lang="sass">
.no_results
  height: 100%
  font-size: 1.1rem
  text-align: center
  align-items: center
  display: flex
  flex-direction: column
  width: 100%
  justify-content: center
  .img__not_found
    height: 250px
    padding: 1rem
@media (min-width: 768px)
  .no_results
    min-width: 550px
</style>
