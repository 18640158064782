<template>
  <ValidationProvider v-slot="{ errors }"
                      name="Categorias"
                      rules="required"
                      vid="categories_ids"
  >
    <section class="mb-3">
      <span class="label">Categorias</span>
      <b-field>
        <b-checkbox v-for="category in categoriesTypes"
                    :key="category.id"
                    :value="value"
                    :native-value="category.id"
                    :style="{'color': category.color}"
                    @input="e => $emit('input', e)"
        >
         {{ category.name }}
        </b-checkbox>
      </b-field>
      <p class="help"
         v-text="errors[0]"
         :class="errors[0] ? 'is-danger' : ''"></p>
    </section>
  </ValidationProvider>
</template>

<script>
import categoriesTypes from '../../constants/recipeCategories'

export default {
  name: 'InputCategories',
  props: {
    value: {
      required: true
    }
  },
  data () {
    return {
      categoriesTypes: categoriesTypes
    }
  }
}
</script>

<style scoped lang="sass">
:deep()
  .field .has-addons
    flex-direction: column
    gap: 1rem
</style>
