<template>
  <div class="selectLocation">
    <div class="selectLocation__header">
      <div class="selectLocation__back">
        <b-button size="is-regular"
                  type="is-primary"
                  inverted
                  outlined
                  icon-left="arrow-left"
                  @click="close"
        >
        </b-button>
      </div>
      <div class="subtitle is-3">
        Provincias
      </div>
    </div>
    <div class="selectProvince__list">
      <div  v-for="(province, idx) in provinces"
            :key="idx"
            class="selectLocation__listItem"
            :class="{'--selected': currentProvinceId === province.id }"
            @click="click(province.id)"
      >
        <img :src="getImage(province.id)"
             :alt="province.name"
             class="selectLocation__stateFlag"
        >
        <span class="selectLocation__listItemName">{{ province.name }}</span>
        <div>
          <b-icon class="selected__icon" icon="check"></b-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import provinces from '../../constants/provinces'
import { mapGetters } from 'vuex'

export default {
  name: 'ProvinceSelector',
  data () {
    return {
      provinces
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    getImage: function (id) {
      return require(`../../assets/images/provinces/${id}.png`)
    },
    click: function (id) {
      if (id !== this.currentProvinceId) {
        this.$emit('set-province', id)
      }
      this.close()
    }
  },
  computed: {
    ...mapGetters(['getCurrentSearch']),
    currentProvinceId: function () {
      return this.getCurrentSearch.provinceId
    }
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles/variables"

.selectLocation
  display: flex
  flex-direction: row
  width: 100%
  position: fixed
  height: 100vh
  top: 0
  left: 0
  background-color: $primary
  z-index: 100
  background-size: 75%
  background-repeat: no-repeat
  background-position: center
  background-image: url('../../assets/images/location-pais.svg')
  &__header
    display: flex
    align-items: center
    justify-content: space-between
    min-height: 64px
    padding: 0rem 2.75rem 0rem 0.75rem
  .subtitle
    color: $white
    display: flex
    flex: 1
    justify-content: center
    font-size: 22px
    font-weight: bold
.selectLocation__back
  .button
    height: 2.5rem
    width: 2.5rem
.selectProvince__list
  flex-direction: column
  display: flex
  gap: 1rem
  overflow-y: auto
  margin: 0
  padding: 1rem
  color: $white
.selectLocation__listItem
  display: flex
  background-color: rgba($black, .15)
  // border: 2px solid rgba($black, .1)
  align-items: center
  gap: 1rem
  padding: .5rem
  cursor: pointer
  border-radius: 35px
  width: 100%
  img
    border-radius: $border-radius
    height: 52px
    width: 52px
    object-fit: cover
.selectLocation__listItemName
  font-size: 1.2rem
  font-weight: 900
  letter-spacing: .15px
.selected__icon
  display: none
.--selected
  background-color: transparent
  border: 2px solid $white
  .selected__icon
    display: flex
@media (min-width: 768px)
  .selectLocation
    display: none
</style>
