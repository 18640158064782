<template>
  <div class="container">
    <div class="container__form">
      <div class="--bordered">
        <div class="subtitle">Titulo</div>
        <div>{{ recipe.title }}</div>
        <hr>
        <div class="subtitle">Dificultad</div>
        <div>{{ difficulty.name }}</div>
        <hr>
        <div class="subtitle">Categorias</div>
        <categories-box :ids="recipe.categories_ids"></categories-box>
      </div>
    </div>
    <div class="container__form">
      <div class="--bordered">
        <div class="subtitle">Ingredientes</div>
        <li v-for="(item, idx) in recipe.ingredients"
            :key="`ingredient${idx}`"
        >
          {{ item }}
        </li>
      </div>
    </div>
    <div class="container__form">
      <div class="--bordered">
        <div class="subtitle">Descripción</div>
        <div class="ql-editor" v-html="recipe.description"></div>
      </div>
    </div>
    <div class="container__form">
      <div class="--bordered">
        <div class="subtitle">Imagen</div>
        <div class="is-flex is-justify-content-center">
          <img class="preview" :src="preview" alt="preview">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import difficulties from '../../constants/recipeDifficulties'
import CategoriesBox from '../Recipe/CategoriesBox'

export default {
  name: 'FinishForm',
  components: {
    CategoriesBox
  },
  props: {
    recipe: {
      required: true
    },
    isModify: {
      required: false
    }
  },
  computed: {
    difficulty: function () {
      return difficulties.find(x => x.id === this.recipe.difficulty_id)
    },
    preview: function () {
      let imageUrl = this.recipe.image_url
      if (this.recipe.image) {
        imageUrl = URL.createObjectURL(this.recipe.image)
      }
      if (this.isModify && !imageUrl) {
        imageUrl = `${process.env.VUE_APP_IMAGES_URL}/recipes/${this.recipe.id}.jpg`
      }
      return imageUrl
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped lang="sass">
@import '~quill/dist/quill.core.css'
.--bordered
  background-color: white
.ql-editor
  min-height: initial
.preview
  max-height: 300px
  border-radius: 1rem
</style>
