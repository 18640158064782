<template>
  <div class="container-fluid RecipeHero">
    <div class="container">
      <section class="hero">
        <!-- <div class="lines" v-if="!isMobile">
          <img :src="require('../../assets/images/recipes/rectangle.png')" alt="">
          <img :src="require('../../assets/images/recipes/rectangle.png')" alt="">
        </div> -->
        <div class="hero__container">
          <div class="hero__body">
            <h2 class="title is-2 ">
              Cocina <b>sin tacc</b><br>en tu casa siguiendo las<br>mejores recetas
            </h2>
            <div class="legend">
              <p>También, podés compartir tus recetas<br> personales con toda la comunidad. </p>
            </div>
            <div class="hero__btn">
              <b-button class="search"
                        type="is-primary-inverted is-rounded"
                        @click="$router.push({ name: 'recipes' })"
                        >
                        <!-- :expanded="isMobile" -->
                Encontrá tu receta
              </b-button>
              <b-button class="search"
                        type="is-primary is-inverted is-rounded is-outlined"
                        @click="$router.push({ name: 'add' })"
                        >
                        <!-- :expanded="isMobile" -->
                Compartí tus recetas
              </b-button>
            </div>
          </div>
          <div class="hero__img">
            <img :src="require('../../assets/images/recipes/hero.svg')" alt="recipe_hero">
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecipeHero',
  inject: ['isMobile']
}
</script>

<style scoped lang="sass">
@import "src/assets/styles/variables"
.RecipeHero.container-fluid
  background-color: #5547c6
  padding: 2rem 0
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88.86 88.86"><rect width="86.02" height="86.02" x="1.42" y="1.42" fill="%236458e4" stroke-miterlimit="10" stroke-width="2.83" rx="7.41" ry="7.41"/></svg>')
  background-size: 90px
  background-repeat-y: 3
  box-shadow: inset 0 -160px 0 0 #4d41ae
  overflow: hidden
.hero
  padding: 1rem
  border: 0.25rem solid #fff
  border-radius: 1.5rem
  color: white
  margin: 0 1rem
  &__container
    position: relative
    display: flex
    flex-direction: column
  .hero__btn
    display: flex
    justify-content: center
    width: 100%
    flex-direction: column
    gap: 1rem
    margin-bottom: 1rem
  .hero__img
    transform: scale(1.15) translateY(2rem)
  .hero__body
    z-index: 1
    display: flex
    align-items: flex-start
    flex-direction: column
    gap: 1.5rem
    .title
      color: #FFF
      margin: 0
      b
        color: $secondary
    .legend
      flex-direction: column
      font-size: 1.25rem
      line-height: 2rem
      font-weight: 700
      letter-spacing: 0.4px
      color: white
      br
        display: none
@media (min-width: 768px)
  .hero
    position: relative
    padding: 2rem 0 2rem 4rem
    &__container
      flex-direction: row
      div
        display: flex
        justify-content: center
        width: 100%
    // .hero__img
      // img
        // transform: scale(1.15)
    .hero__btn
      flex-direction: row
    .hero__body
      gap: 1rem
      .legend
        margin-bottom: 1rem
        br
          display: block
    .hero__btn
      display: flex
      gap: 1rem
      justify-content: flex-start
</style>
