<template>
  <section class="section top__stores" v-if="stores.length">
    <div class="title">Top Tiendas</div>
    <div class="subtitle">Tiendas mas comentadas en<br><b>los últimos 7 días</b></div>
    <div class="store_container">
      <store-item v-for="store in stores"
                  :store="store"
                  :key="store.id"
                  @click="click"
      ></store-item>
      </div>
  </section>
</template>

<script>
import StoreItem from './TopStore'

export default {
  name: 'TopStores',
  props: {
    stores: {
      required: true,
      default: () => []
    }
  },
  components: {
    StoreItem
  },
  methods: {
    click: function (store) {
      this.$router.push({ name: 'store', params: { id: store.id } })
    }
  }
}
</script>

<style lang="sass" scoped>
.top__stores
  width: 100%
  display: flex
  flex-direction: column
.title, .subtitle
  text-align: center
.subtitle
  b
    font-weight: 900
    color: #7555f6
</style>
