const stores = [
  {
    id: 1,
    name: 'Resto',
    style: {
      icon: 'silverware-fork-knife',
      color: '#2a7ad0'
    }
  },
  {
    id: 2,
    name: 'Bar',
    style: {
      icon: 'glass-wine',
      color: '#d72525'
    }
  },
  {
    id: 3,
    name: 'Café',
    style: {
      icon: 'coffee',
      color: '#e8af5a'
    }
  },
  {
    id: 4,
    name: 'Delivery',
    style: {
      icon: 'truck-delivery',
      color: '#18b355'
    }
  },
  {
    id: 5,
    name: 'Tienda',
    style: {
      icon: 'store',
      color: '#a145d2'
    }
  },
  {
    id: 6,
    name: 'Take away',
    style: {
      icon: 'basket',
      color: '#40b6b6'
    }
  },
  {
    id: 7,
    name: 'Heladería',
    style: {
      icon: 'ice-cream',
      color: '#759110'
    }
  }
]

export default stores
