<template>
  <div class="navRail" ref="navrail">
    <div class="navRail__logo" @click="go('home')">
      <img class="navRail__logoImg"
           :src="require('../../assets/images/logo.svg')"
           alt="logo"
      >
      <transition name="fade">
        <div class="navRail__title" v-show="isHome">
          Celisana
        </div>
      </transition>
    </div>
    <div class="navRail__items">
      <div v-for="item in menu"
           :key="item.label"
           :class="[
              {
                '--active': $route.name === item.url
              },
              item.class || 'navRail__btn'
           ]"
           @click="item.click ? item.click() : go(item.url, item.urlParams)"
      >
        <b-icon :icon="$route.name === item.url ? item.icon.replace('-outline', '') : item.icon" custom-size="mdi-24px"></b-icon>
        <span class="navRail__btnlabel">{{ item.label }}</span>
        <div class="dot"></div>
      </div>
      <template v-if="getUser">
        <div class="navRail__btn --account">
          <b-dropdown
            append-to-body
            trap-focus
          >
            <template #trigger>
              <div class="has-text-centered">
                <b-icon icon="account-circle-outline" custom-size="mdi-24px"></b-icon>
                <span class="navRail__btnlabel">Cuenta</span>
              </div>
            </template>
            <b-dropdown-item aria-role="menuitem" @click="go('profile', { id: getUser.user })">
              <avatar :member="getUser" class="icon"></avatar>
              Mi Perfil
            </b-dropdown-item>
            <b-dropdown-item value="home" aria-role="menuitem" @click="go('favorites')">
              <b-icon icon="heart-outline"></b-icon>
              Mis favoritos
            </b-dropdown-item>
            <b-dropdown-item value="products" aria-role="menuitem" @click="go('my-account')">
              <b-icon icon="cog-outline"></b-icon>
              Configuración
            </b-dropdown-item>
            <hr class="dropdown-divider" aria-role="menuitem">
            <b-dropdown-item value="logout" aria-role="menuitem" @click="logoutFn()">
              <b-icon icon="logout"></b-icon>
              Logout
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
      <div class="navRail__btn" @click="login()" v-else>
        <b-icon icon="login" custom-size="mdi-24px"></b-icon>
        <span class="navRail__btnlabel">Login</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isEqual } from 'lodash'
import { needLogin, logout } from '@/modules/auth'
import Avatar from '@/components/Common/Avatar'

export default {
  name: 'NavRail',
  components: {
    Avatar
  },
  inject: ['isMobile'],
  data () {
    return {
      logoutFn: logout
    }
  },
  methods: {
    go: function (name, params) {
      if (this.$route.name !== name || !isEqual(this.$route.params, params || {})) {
        this.$router.push({ name, params })
      }
    },
    login: function (redirectOnSuccess = undefined) {
      needLogin(this, { redirectOnSuccess })
    },
    goToResults: function () {
      if (this.$route.name !== 'results') {
        const newSearch = Object.assign({}, this.getCurrentSearch)
        newSearch.types = []
        this.$store.dispatch('search', newSearch)
      }
    }
  },
  computed: {
    ...mapGetters(['getUser', 'getCurrentSearch']),
    isHome: function () {
      return this.$route.name === 'home'
    },
    menu: function () {
      return [
        {
          icon: 'compass-outline',
          label: 'Lugares',
          url: 'results',
          click: this.goToResults
        },
        {
          icon: 'food-takeout-box-outline',
          label: 'Recetas',
          url: 'recipes'
        },
        {
          icon: 'newspaper-variant-outline',
          label: 'Noticias',
          url: 'news'
        },
        {
          icon: 'handshake-outline',
          label: 'Compartí',
          url: 'add'
        }
      ]
    }
  }
}
</script>

<style scoped lang="sass">
@import "src/assets/styles/variables"

.navRail
  bottom: 0
  width: 100%
  height: 70px
  display: flex
  position: fixed
  left: 0
  z-index: 20
  border-radius: 12px 12px 0 0
  background-color: $white
  &__title
    font-size: 2rem
    font-weight: bold
    position: absolute
    left: 80px
    width: 143px
.navRail__logo
  display: none
  cursor: pointer
  height: 70px
  align-items: center
  justify-content: center
  margin-bottom: 1.5rem
  &:hover
    background-color: #f4f1ff
  &:active
    box-shadow: inset 0 0 20px 20px #f4f1ff
:deep(.mdi-24px.mdi:before)
  font-size: 28px
.navRail__items
  display: flex
  height: 100%
  width: 100%
.navRail__btn
  color: var(--color-primary)
  justify-content: center
  align-items: center
  display: flex
  flex-direction: column
  transition: background-color .2s ease
  cursor: pointer
  flex: 1
  flex-basis: 25%
  .dot
    opacity: 0
  &:hover
    background-color: #f4f1ff
  &.--active,&:hover
    color: #7555F6
    transition: color .3s ease-in
    .dot
      transition: background-color .3s ease-in-out
      opacity: 0.5
      height: 4px
      width: 20px
      border-radius: 5px
      background: $primary
      display: flex
      position: absolute
      bottom: 5px
  .icon
    color: var(--color-primary)
:deep(.--account)
  .navRail__btnlabel
    top: -3px
    position: relative
  .icon
    position: relative
    top: 2px
.navRail__btnlabel
  font-weight: 500
  font-size: 12px
  height: $border-radius
  display: flex
  align-items: center
.dropdown-item
  display: flex
  align-items: center
  gap: 1rem
@media (min-width: 768px)
  .navRail
    flex-direction: column
    width: 72px
    max-width: 72px
    top: 0
    height: 100vh
    max-height: 100vh
    background-color: transparent
    border-radius: initial
  .navRail__btn
    height: 72px
    flex-basis: initial
    flex: initial
    &.--active
      .dot
        opacity: 0
  .navRail__items
    border-top: 0
    flex-direction: column
    justify-content: flex-start
  .navRail__logo
    display: flex
</style>
