import { round } from 'lodash'
import store from '@/store'
import axios from 'axios'
import provinces from '@/constants/provinces'

function getDistance (origin, destination) {
  // return distance in meters
  const lon1 = toRadian(origin[1])
  const lat1 = toRadian(origin[0])
  const lon2 = toRadian(destination[1])
  const lat2 = toRadian(destination[0])

  const deltaLat = lat2 - lat1
  const deltaLon = lon2 - lon1

  const a = Math.pow(Math.sin(deltaLat / 2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon / 2), 2)
  const c = 2 * Math.asin(Math.sqrt(a))
  const EARTH_RADIUS = 6371
  const resultMts = c * EARTH_RADIUS * 1000
  return round(resultMts / 1000, 2)
}

function toRadian (degree) {
  return degree * Math.PI / 180
}

const getActualCoords = () => {
  if (navigator.geolocation) {
    const options = { enableHighAccuracy: true, maximumAge: 15 * 1000, timeout: 5 * 1000 }
    navigator.geolocation.watchPosition(
      (position) => {
        store.commit('setUserCoords', [position.coords.latitude, position.coords.longitude])
        store.commit('setEnableGps', true)
      },
      () => {
        store.commit('setEnableGps', false)
      },
      options)
  } else {
    store.commit('setEnableGps', false)
    // eslint-disable-next-line no-console
    console.log('Geolocation is not supported by this browser')
  }
}

const getGeo = () => {
  axios.get('https://get.geojs.io/v1/ip/geo.json')
    .then(({ data }) => {
      store.commit('setUserCoords', [data.latitude, data.longitude])
      if (!store.getters.getCurrentProvince) { // set province only if is anonymous user
        const province = data.region === 'Buenos Aires F.D.' ? 'Capital Federal' : data.region
        const currentProvince = provinces.find(x => x.name.toLowerCase().includes(province.toLowerCase()))
        if (currentProvince) store.commit('setPreferredProvince', currentProvince.id.toString())
      }
    })
    .finally(() => getActualCoords())
}

export { getDistance, getActualCoords, getGeo }
