<template>
  <div class="items_container container">
    <div class="ItemsContainer__container">
      <div class="ItemsContainer__results">
        <sort-actions :is-loading="isLoading">
          <template #desktop>
            <slot name="actions" />
          </template>
        </sort-actions>
        <skeleton-loading v-if="isLoading"></skeleton-loading>
        <div v-else class="items_container__grid">
          <store-item v-for="store in results"
                      :key="store.id"
                      :store="store"
                      @click="goToDetails"
          ></store-item>
          <no-results v-if="!results.length && !isLoading"></no-results>
        </div>
      </div>
      <div class="itemsContainer__side">
        <map-component
          :coords-list="coordsList"
          :center="coordsList.length ? coordsList[0].coords: $store.getters.getUserCoords"
          :zoom="lastSearch.locationId ? 13 : 11"
          @click-item="clickStore"
        />
        <b-pagination
          :total="total"
          :per-page="size"
          :current="page"
          size="is-small"
          :rounded="true"
          icon-prev="arrow-left"
          icon-next="arrow-right"
          aria-next-label="Proxima Pagina"
          aria-previous-label="Pagina Previa"
          aria-page-label="Pagina"
          aria-current-label="Pagina actual"
          @change="e => $emit('change-page', e)"
        >
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import StoreItem from '@/components/StoreResults/StoreItem'
import NoResults from '@/components/StoreResults/NoResults'
import SkeletonLoading from '@/components/StoreResults/SkeletonLoading'
import MapComponent from '@/components/MapComponent'
import SortActions from './SortActions'
import ModalStoreMap from './ModalStoreMap'

export default {
  name: 'ItemsContainer',
  props: {
    results: {
      required: true
    },
    coordsList: {
      required: true
    },
    lastSearch: {
      required: true
    },
    page: {
      required: true
    },
    isLoading: {
      required: true
    },
    total: {
      required: true
    },
    size: {
      required: true
    }
  },
  components: {
    StoreItem,
    MapComponent,
    NoResults,
    SkeletonLoading,
    SortActions
  },
  methods: {
    goToDetails: function (store) {
      this.$store.commit('setLastResults', {
        results: this.results,
        count: this.total,
        page: this.page
      })
      this.$router.push({
        name: 'store',
        params: { id: store.id }
      })
    },
    clickStore: function (store) {
      this.$buefy.modal.open({
        parent: this,
        component: ModalStoreMap,
        props: { store, callback: this.goToDetails },
        hasModalCard: true
      })
    }
  }
}
</script>

<style scoped lang="sass">
@import "src/assets/styles/variables"

.items_container
  .ItemsContainer__header
    display: flex
  .header
    display: flex
  .items_container__grid
    display: flex
    flex-direction: column
    overflow-y: auto
    overflow-x: hidden
    height: calc(100vh - 180px)
    width: auto
    padding-right: .25rem
  .mapComponent
    height: calc(100vh - 144px)
    border-radius: $border-radius
    // border: 1px solid black
.ItemsContainer__container
  display: flex
  gap: 0.5rem
  flex-direction: row
  // background-color: $white
  height: calc(100vh - 132px)
  margin: 0
.ItemsContainer__results
  display: flex
  flex-direction: column
  align-items: flex-start
  background-color: $white
  border-radius: $border-radius $border-radius 15px 15px
  padding: 0
  .actions
    background-color: rgba($primary, .1)
    padding: .5rem
    margin: 0
.filterList__mobile
  width: 100%
  margin-bottom: 1rem
  :deep()
    .filters
      width: 100%
.itemsContainer__side
  display: flex
  flex-direction: column
  width: 100%
  // .pagination
  //   padding: 0
:deep()
  .StoreItem
    border: none
    box-shadow: none
    margin: 0
    &:hover
      border-radius: 15px
      border-color: transparent
  .pagination
    margin: 0 !important
    padding: .5rem 0 0
    // height: 4rem
    display: flex
    align-items: center
    .pagination-link
      height: 2rem
      width: 2rem
      font-weight: 800
      padding: 0
      background-color: transparent
      color: $white
      border: 2px solid $white
      &.pagination-next, &.pagination-previous
        min-width: 6rem
        height: 3.5rem
        background-color: $secondary
        color: $black
        margin-right: 0
        border-color: lighten($secondary, 10%)
      &.pagination-previous
        min-width: initial
        height: 2rem
      &.is-current
        // border-color: lighten($secondary, 20%)
        background-color: $white
        color: $black
        // background-color: darken($secondary, 20%)
  .pagination-list
    justify-content: flex-end
  .mapComponent
    .b-skeleton, .b-skeleton-item
      height: 100%
</style>
