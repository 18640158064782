<template>
  <div class="container" >
    <section class="section">
      <p><b>Atención:</b>
        Todas las denuncias serán revisadas por nuestros moderadores. Crear denuncias falsas no serán tomadas en cuenta
        y en caso de abusos en las denuncias se bloquearán usuarios.</p>
    </section>
    <section class="section">
      <ValidationObserver ref="validator" v-slot="{ invalid }">
        <form @submit.prevent="submit">
          <input-select name="reason"
                        label="Motivo"
                        icon="alert-octagon"
                        :options="reasons"
                        v-model="payload.reason"
          ></input-select>
          <input-text name="notes" label="Observaciones"
                      type="textarea"
                      validate="required"
                      placeholder="Detalla lo maximo posible el motivo de tu solicitud"
                      v-model="payload.notes"
          ></input-text>
          <b-button
            class="is-pulled-right"
            :disabled="isLoading || invalid"
            icon-left="send"
            label="Enviar"
            type="is-primary"
            native-type="submit"
            :loading="isLoading"
          />
        </form>
      </ValidationObserver>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import NeedToken from '@/components/Mixins/NeedToken'
import InputText from '@/components/Common/InputText'
import InputSelect from '@/components/Common/InputSelect'

export default {
  name: 'ComplaintModal',
  props: {
    id: {
      required: true
    }
  },
  components: {
    InputText,
    InputSelect
  },
  mixins: [
    NeedToken
  ],
  metaInfo () {
    return {
      title: 'Solicitud baja'
    }
  },
  data () {
    return {
      isLoading: false,
      payload: {
        reason: 1
      },
      reasons: [
        {
          label: 'El lugar cerró definitivamente',
          id: 1
        },
        {
          label: 'El lugar no tiene alimentos aptos',
          id: 2
        },
        {
          label: 'El lugar no existe',
          id: 3
        },
        {
          label: 'Otro motivo',
          id: 4
        }
      ]
    }
  },
  methods: {
    submit: function () {
      this.isLoading = true
      // eslint-disable-next-line no-return-assign
      axios.post(`stores/${this.id}/complaint/`, this.payload)
        .then(r => {
          this.$buefy.dialog.alert({
            title: 'Solicitud enviada',
            message: 'En las proximos dias nuestros moderadores van a estar revisando tu solicitud. Muchas gracias por tomarte el tiempo para ayudarnos a que esta comunidad sea cada día mas completa y correcta.',
            confirmText: 'Volver'
          })
          this.$router.push({ name: 'home' })
        })
        .catch((e) => {
          this.$buefy.snackbar.open({
            duration: 3000,
            message: e.response.data.message || e.response.data,
            type: 'is-danger',
            position: 'is-bottom-right'
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style scoped>
</style>
