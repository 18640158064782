<template>
  <div>
    <b-message type="is-info">
      Ingrese todos los pasos de tu receta e incluí todos los detalles posibles.
      <br>
      Podes sumar links con algun video para complementar la receta o algunas imagenes que tengas subidas en tus redes sociales.
      <br>
      Por favor evita pegar imagenes con copyright o que No sean propias.
    </b-message>
    <rich-area name="description"
               label="Descripción"
               validate="required"
               v-model="recipe.description"
    ></rich-area>
  </div>
</template>

<script>
import RichArea from '../Common/RichArea'

export default {
  name: 'FormDescription',
  props: {
    recipe: {
      required: true
    }
  },
  components: {
    RichArea
  },
  data () {
    return {}
  }
}
</script>

<style scoped lang="sass">
</style>
