<template>
  <article class="recipe"
           @click="$router.push({ name: 'recipe-details', params: { id: recipe.id }})"
  >
    <b-image
      :src="imageUrl(recipe)"
      :src-fallback="require('../../assets/images/fallback_store.png')"
      class="recipe__image"
      :alt="recipe.title"
    >
    </b-image>
    <div class="recipe__body">
      <div class="recipe__body__head">
        <span class="difficulty">{{ recipe.difficulty.name }}</span>
        <small>{{ recipe.created | moment("from") }}</small>
      </div>
      <div class="recipe__body__title">{{ recipe.title }}</div>
      <div class="recipe__body__categories">
        <categories-box :ids="recipe.categories.map(x => x.id)"></categories-box>
      </div>
    </div>
    <div class="recipe__footer">
      <div class="recipe__footer__author">
        <avatar :member="recipe.author" size="32" :is-clickable="true"></avatar>
        <div class="name">
          {{ recipe.author.name }}
        </div>
      </div>
      <div class="recipe__footer__likes">
        <b-icon type="is-primary is-light"
                  icon="thumb-up-outline"
                  size="is-small"
        >
        </b-icon>
        <span class="likes-count">{{ recipe.likes }}</span>
      </div>
    </div>
  </article>
</template>

<script>
import Avatar from '../Common/Avatar'
import CategoriesBox from '../Recipe/CategoriesBox'

export default {
  props: {
    recipe: {
      required: true,
      type: Object
    }
  },
  components: {
    Avatar,
    CategoriesBox
  },
  methods: {
    imageUrl: function (recipe) {
      return process.env.VUE_APP_IMAGES_URL + `/recipes/${recipe.id}.jpg`
    }
  }
}
</script>

<style scoped lang="sass">
@import "src/assets/styles/variables"

.recipe
  position: relative
  cursor: pointer
  background: white
  border-radius: $border-radius
  border: 1px solid black
  box-shadow: 0 5px 0 0 rgb(199 199 199 / 26%)
  transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out, background-color 0.2s ease
  width: 100%
  display: flex
  flex-direction: column
  &:hover
    box-shadow: 0 0 0 0 rgba(199, 199, 199, .26)
    border-color: $primary
    background-color: #f4f1ff
  &__body
    padding: 1rem 1.5rem
    &__head
      font-size: .9rem
      display: flex
      justify-content: space-between
      color: #6E798C
      .difficulty
        color: $primary
    &__title
      font-size: 1.25rem
      line-height: 1.2
      letter-spacing: .05rem
      font-weight: bold
      padding: .5rem 0 1rem
    &__categories
      margin-bottom: 3.5rem
  &__footer
    width: 100%
    padding: 0 1.5rem
    position: absolute
    bottom: 1.5rem
    display: flex
    align-items: center
    justify-content: space-between
    &__author
      align-items: center
      display: flex
      width: calc(100% - 3rem)
      .name
        font-size: .9rem
        text-overflow: ellipsis
        overflow: hidden
        white-space: nowrap
      :deep(.avatar)
        width: 24px
        margin-right: .5rem
    &__likes
      display: flex
      align-items: center
      .likes-count
        font-size: .85rem
        color: $primary
        padding-left: .25rem
:deep(.recipe__image img)
  border-top-left-radius: $border-radius
  border-top-right-radius: $border-radius
  object-fit: cover
  height: 170px
  width: 100%

@media (min-width: 768px)
  .recipe
    max-width: calc(50% - 0.5rem)
@media (min-width: 1200px)
  .recipe
    max-width: calc(33% - 0.5rem)
</style>
