import axios from 'axios'
import store from '../store'
import Login from '@/components/Users/Login.vue'
import router from '@/router/index'

const setHeaders = (token) => {
  axios.defaults.headers.common.Authorization = 'Token ' + token
}

const logout = () => {
  delete axios.defaults.headers.common.Authorization
  store.dispatch('setUser', { user: undefined, token: undefined, favorites: { stores: [], recipes: [] } })
}

const login = (username, password) => {
  if (axios.defaults.headers.common.Authorization) {
    delete axios.defaults.headers.common.Authorization
  }
  return axios.post('/auth/login/', {
    username: username,
    password: password
  }).then(({ data }) => {
    store.dispatch('setUser', data)
    setHeaders(data.token)
  })
}

const checkToken = (instance) => {
  let token = instance.$route.query.token
  if (token) {
    const isNewUser = instance.$route.query.is_new === '1'
    const oauthUri = instance.$store.getters.getOauthRedirectUri
    instance.$router.replace({})
    store.commit('setOauthRedirectUri', undefined)
    axios.get(`auth/validate/${token}/`)
      .then(({ data }) => {
        store.dispatch('setUser', data)
        setHeaders(token)
        if (isNewUser) {
          instance.$router.push({ name: 'my-account' })
          instance.$buefy.snackbar.open({
            duration: 10000,
            message: 'Completá tu perfil de usuario. Muchas gracias!'
          })
        } else if (oauthUri) { // if has token from oauth
          window.location.href = oauthUri
        }
      })
  } else {
    token = store.getters.getToken
    if (token) {
      setHeaders(token)
    }
  }
}

const needLogin = (instance, props = undefined) => {
  if (!instance.$store.getters.getToken) {
    instance.$buefy.modal.open({
      parent: instance,
      component: Login,
      hasModalCard: true,
      canCancel: true,
      customClass: '',
      trapFocus: true,
      props
    })
  } else if (props && props.redirectOnSuccess) {
    instance.$router.push({ name: props.redirectOnSuccess })
  }
}

const loginOauth = (provider) => {
  let apiUrl = process.env.VUE_APP_API
  let oauthRedirectUri = window.location.href
  if (['home', 'signup'].includes(router.currentRoute.name)) {
    oauthRedirectUri = undefined
  }
  store.commit('setOauthRedirectUri', oauthRedirectUri)

  apiUrl = apiUrl.replace('api/v1', `oauth/${provider}/login/`)
  window.location = apiUrl
}

export { logout, login, checkToken, needLogin, loginOauth }
