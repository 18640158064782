<template>
  <div class="navbar">
    <div class="header__logo" @click="$router.push({ name: 'home' })">
      <img class="header__logoImg"
           :src="require('../../assets/images/logo.svg')"
           alt="logo"
      >
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'RecipeHeader',
  components: {},
  data () {
    return {
      showSelector: false
    }
  }
}
</script>

<style scoped lang="sass">
@import "../../assets/styles/variables"
.navbar
  display: flex
  justify-content: space-between
  padding: 0
  background-color: white
  align-items: center
  position: fixed
  top: 0
  left: 0
  z-index: 40
  width: 100%
.header__logo
  height: 66px
  width: 72px
  display: flex
  align-items: center
  justify-content: center
  max-height: initial
</style>
