<template>
  <div class="navbar" :class="{'--light': isLight}">
    <div class="navbar__body">
      <div class="header__logoContainer" @click="go('home')">
        <div class="header__logo">
          <img class="header__logoImg"
               :src="require('../../assets/images/logo.svg')"
               alt="logo"
          >
        </div>
        <span class="header__logoType">celisana</span>
      </div>
      <b-dropdown
        v-if="getUser"
        class="right__container"
        aria-role="list"
      >
        <template #trigger>
          <div slot="label" class="user_box__label">
            <span v-if="isDesktop">{{ getUser.username }}</span>
            <avatar :member="getUser" size="32" class="ml-2"></avatar>
            <b-icon
              class="ml-1"
              icon="chevron-down"
            >
            </b-icon>
          </div>
        </template>
        <template v-if="isMobile">
          <b-dropdown-item custom >
            <b>{{ getUser.username }}</b>
          </b-dropdown-item>
          <hr class="dropdown-divider">
        </template>
        <b-dropdown-item value="test" @click="go('add')" aria-role="menuitem">
          Nueva colaboración
        </b-dropdown-item>
        <b-dropdown-item @click="go('profile', { id: getUser.user })">
          Mi perfil
        </b-dropdown-item>
        <b-dropdown-item @click="go('favorites')">
          Mis Favoritos
        </b-dropdown-item>
        <b-dropdown-item @click="go('my-account')">
          Configuración
        </b-dropdown-item>
        <hr class="dropdown-divider">
        <b-dropdown-item @click="logout">
          Logout
        </b-dropdown-item>
      </b-dropdown>
      <div v-else class="right__buttons">
        <b-button class="button"
                  @click="login"
                  outlined
                  :inverted="!isLight"
                  type="is-primary is-rounded is-bordered"
                  >
          Ingresa
        </b-button>
        <b-button v-if="$route.name === 'home'"
                  class="ml-2 cta"
                  label="Registrate"
                  type="is-primary is-rounded"
                  @click="go('signup')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Avatar from '@/components/Common/Avatar'
import { needLogin, logout } from '../../modules/auth'

export default {
  name: 'Navbar',
  components: {
    Avatar
  },
  inject: [
    'isMobile'
  ],
  data () {
    return {
      scrollY: 0
    }
  },
  methods: {
    login: function () {
      needLogin(this)
    },
    logout: function () {
      logout()
      this.deleteClassClipped()
    },
    go: function (name, params = {}) {
      if (this.$route.name !== name) {
        this.$router.push({ name, params })
      }
    },
    onScroll: function (e) {
      // TODO meter debounce
      this.scrollY = window.scrollY
    },
    deleteClassClipped: function () {
      document.documentElement.classList.remove('is-clipped-touch')
    }
  },
  computed: {
    ...mapGetters(['getUser']),
    isDesktop: function () {
      return !this.isMobile
    },
    isLight: function () {
      return this.scrollY > 70 || this.$route.name === 'signup'
    }
  },
  created () {
    document.addEventListener('scroll', this.onScroll, { capture: true, passive: true })
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.onScroll)
    this.deleteClassClipped()
  }
}
</script>

<style scoped lang="sass">
@import "@/assets/styles/variables"

.navbar
  position: fixed
  width: 100%
  transition: background-color .3s ease-in-out
  background-color: transparent
  padding-right: .5rem
  &.--light
    background-color: white
    .header__logoContainer
      .header__logo
        filter: initial
      .header__logoType
        color: initial
    .right__container
      .user_box__label
        color: initial
  .button.cta
    background-color: $white
    color: darken($primary, 30%)
  &__body
    width: 100%
    display: flex
    justify-content: space-between
    align-content: center
    .user_box
      &__label
        display: flex
        align-items: center
    .right__buttons
      padding: 0.3rem 0
      display: flex
      align-items: center
  .header__logoContainer
    display: flex
    align-items: center
    cursor: pointer
    .header__logo
      filter: brightness(2) contrast(3)
      height: 66px
      width: 72px
      display: flex
      align-items: center
      justify-content: center
      max-height: initial
    .header__logoType
      font-weight: 800
      color: white
      font-size: 0
      letter-spacing: .4px
  .right__container
    border-radius: 1rem
    transition: border-color .15s ease-in-out
    .user_box__label
      cursor: pointer
      padding: 1rem 0.5rem
      color: white
@media (min-width: 768px)
  .navbar
    .header__logoContainer
      .header__logoType
        font-size: 1.5rem
</style>
