<template>
  <form @submit.prevent class="surface">
    <div class="modal-card" style="width: auto">
      <div class="body">
        <span>Tu comentario se cargó <b>exitosamente</b>.</span>
        <span>¡Muchas gracias por colaborar con nosotros!</span>
      </div>
      <b-button
        type="is-success"
        label="Continuar"
        icon-left="check"
        class="mb-2"
        @click="$emit('close')" />
    </div>
  </form>
</template>

<script>

export default {
  name: 'ModalAddComment'
}
</script>

<style scoped lang="sass">
.body
  margin: 2rem
  font-size: 1.5rem
  display: flex
  flex-direction: column
  span
    text-align: center
</style>
