<template>
  <div class="container-fluid">
    <!-- for SEO-->
    <h1 v-show="false">Lugares cerca de tu ubicación</h1>
    <SearchFormMobile v-if="isMobile"
                      :isLoading="isLoading"
                      :default-value="$route.query"
    >
      <div class="btnGps" @click="showHelpGps">
        <b-icon size="is-small" :icon="getEnableGps? 'crosshairs-gps' : 'crosshairs-question'"></b-icon>
      </div>
    </SearchFormMobile>
    <div class="header__desktop container" v-else>
      <SearchFormResults :default-value="$route.query" />
      <div class="back_button"
           @click="$store.dispatch('search', {})"
      >
        <b-icon icon="arrow-left"></b-icon>
        <div>Volver a lista completa de lugares</div>
      </div>
    </div>
    <section class="container-results">
      <component
        :is="isMobile ? 'items-container-mobile': 'items-container'"
        :results="coordsList"
        :coordsList="coordsList"
        :last-search="{}"
        :zoom="14"
        :is-loading="isLoading"
        :total="coordsList.length"
        :size="coordsList.length"
        :page="1"
      >
        <template #actions>
          <div class="p-2 has-text-weight-bold">
            <span v-text="legendResults"></span>
          </div>
        </template>
        <template #last>
          <b-button icon-left="compass"
                    type="is-secondary"
                    class="mt-3"
                    rounded
                    @click="$store.dispatch('search', {})"
          >
            Volver a lista completa de lugares
          </b-button>
        </template>
      </component>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import { getCoordsList } from '@/modules/storeManager'
import { mapGetters } from 'vuex'
import SearchFormResults from '@/components/Layout/SearchFormResults'
import SearchFormMobile from '@/components/Layout/SearchFormMobile'
import FilterButtons from '@/components/StoreResults/FilterButtons'
import ItemsContainerMobile from '@/components/StoreResults/ItemsContainerMobile'
import ItemsContainer from '@/components/StoreResults/ItemsContainer'
import { sortBy } from 'lodash'
import { getActualCoords } from '@/modules/coords'
import { getMetaInfo } from '@/modules/meta'

export default {
  components: {
    FilterButtons,
    SearchFormMobile,
    SearchFormResults,
    ItemsContainerMobile,
    ItemsContainer
  },
  watch: {
    getEnableGps: {
      handler (newVal, oldVal) {
        if (newVal && !oldVal) {
          this.callApi()
        }
      }
    }
  },
  metaInfo () {
    return getMetaInfo(
      'Lugares cercanos a tí',
      'Encontrá lugares gluten free cercanos a tí en nuestro mapa interactivo',
      'assets/images/hero3.svg'
    )
  },
  inject: ['isMobile'],
  data () {
    return {
      isLoading: true,
      results: []
    }
  },
  methods: {
    showHelpGps: function () {
      this.$buefy.dialog.confirm({
        title: 'Activar ubicación',
        message: `
        ${this.getEnableGps ? '<div class="has-text-primary subtitle">TU GPS YA ESTÁ ACTIVADO</div>' : ''}
        Si tenes inconvenientes con tu ubicación, seguí los siguientes pasos:
        <li>Activa el gps de tu dispositivo.</li>
        <li>Hace click en <b>activar gps</b> o volvé a ingresar al sitio de <span class="has-text-primary has-text-weight-bold">Celisana</span>.</li>
        <li>Cuando el navegador te solicite permisos para acceder a la ubicación, aceptalos.</li>
        <hr>
        <b>LISTO!</b> Con estos pasos deberías poder descubrir los lugares cercanos a tí (hasta 4km de tu ubicación).
        `,
        cancelText: 'Activar GPS',
        canCancel: !this.getEnableGps,
        confirmText: 'Volver',
        onCancel: () => getActualCoords(),
        type: 'is-primary'
      })
    },
    callApi: async function () {
      /** si no tiene coords porque entra por primera vez desde un buscador, esperar a resolver coords **/
      if (!this.getUserCoords) {
        // eslint-disable-next-line promise/param-names
        await new Promise(r => setTimeout(r, 3000))
      }
      this.isLoading = true
      axios.get('stores/nearby/', {
        params: {
          latitude: this.getUserCoords[0],
          longitude: this.getUserCoords[1]
        }
      })
        .then(r => {
          if (r.data.length) {
            this.results = getCoordsList(r.data, this.getUserCoords)
            if (!this.getEnableGps) {
              this.$buefy.snackbar.open({
                duration: 1000 * 4,
                message: '<i>Activá tu GPS para optimizar la búsqueda</i>'
              })
            }
          } else {
            this.$buefy.snackbar.open({
              duration: 1000 * 10,
              message: 'No encontramos resultados cercanos a tí.<br><i>Activá tu GPS para optimizar la búsqueda</i>',
              type: 'is-warning',
              position: 'is-bottom-right'
            })
          }
        })
        .catch((e) => {
          this.$buefy.snackbar.open({
            duration: 1000 * 5,
            message: 'Se produjo un error en la busqueda. Por favor reintente mas tarde',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
          // eslint-disable-next-line no-console
          console.log('error', e)
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => this.isLoading = false)
    }
  },
  computed: {
    ...mapGetters(['getUserCoords', 'getEnableGps']),
    coordsList: function () {
      return sortBy(this.results, ['distance'])
    },
    legendResults: function () {
      const count = this.results.length
      const response = [count]
      let end = 's'
      if (count === 1) {
        end = ''
      }
      response.push(`resultado${end}`)
      response.push(`cercano${end} a tí`)
      return this.isLoading ? 'Buscando...' : response.join(' ')
    }
  },
  mounted () {
    this.callApi()
  }
}
</script>
<style scoped lang="sass">
@import "src/assets/styles/variables"
.header__desktop
  padding: 0.5rem 0 1rem 0
  gap: 1rem
  display: flex
  flex-direction: column
  color: white
  font-size: 2.25rem
  .back_button
    font-size: 1rem
    font-weight: 600
    line-height: 1.6
    display: flex
    gap: .5rem
    align-items: center
    cursor: pointer
    color: white
    .icon
      color: $secondary
.container-results
  padding-top: 66px
:deep()
  .search, .order, .pagination, .dropdown
    display: none
  .ItemsContainer__results
    border-radius: 12px
  .ItemsContainer__container
    padding-bottom: 2rem
  .StoreItem__container
    .StoreItem__counters
      .b-tooltip:first-child
        display: none
      .--distance
        display: flex
  .btnGps
    display: flex
    top: 0
    right: 0
    z-index: 100
    width: 2.5rem
    height: 2.5rem
    align-items: center
    justify-content: center
    font-size: 26px
    background-color: rgba(255, 255, 255, 0.05)
    margin: 0.75rem
    color: white
    border-radius: 0.25rem
@media (min-width: 768px)
  .container-fluid
    padding: 0 1rem
    border-radius: 25px
    background: $primary
  .container-results
    padding-top: 0
  :deep()
    .ItemsContainer__results
      border-radius: $border-radius $border-radius 12px 12px
    .StoreItem__container
      .StoreItem__counters
        .b-tooltip:first-child
          display: initial
</style>
