<template>
  <div class="storeItem" @click="$router.push({name: 'store', params: { id: comment.store.id } })">
    <div class="storeItem__image">
      <b-image
        :src="imageUrl"
        :src-fallback="require('../../assets/images/fallback_store.png')"
        :alt="comment.store.name"
      >
      </b-image>
    </div>
    <div class="storeItem__body">
      <div class="storeItem__head">
        <div class="storeItem__name">
          <b-icon icon="store" custom-size="mdi-16px"></b-icon>
          <b>{{ comment.store.name }}</b>
        </div>
        <b-rate :max="1" :show-score="true" :value="parseFloat(comment.score)" :disabled="true" spaced size="is-regular"></b-rate>
      </div>
      <div class="storeIinfo">
        <p class="storeItem__comment">“{{ comment.comment | limit }}”</p>
        <p class="storeItem__location">{{ comment.created | moment("from") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommentStore',
  filters: {
    limit: function (text) {
      const limit = 126
      if (text.length > limit) {
        text = text.slice(0, limit) + '...'
      }
      return text
    }
  },
  props: {
    comment: {
      required: true
    }
  },
  computed: {
    imageUrl: function () {
      return process.env.VUE_APP_IMAGES_URL + `/stores/${this.comment.store.id}.jpg`
    }
  }
}
</script>

<style scoped lang="sass">
.comment
  overflow: hidden
  text-overflow: ellipsis
  position: relative
  display: block
.storeItem
  border-radius: 10px
  overflow: hidden
  height: 300px
  width: 100%
  position: relative
  justify-content: space-between
  margin: 0 0 1.5rem
.storeItem__image
  width: 100%
  height: 100%
  object-fit: cover
  :deep()
    .image, img
      height: 100%
:deep(.rate-text span)
  font-size: 1.25rem
  font-weight: 600
  color: #FFF
.storeItem__head
  display: flex
  justify-content: space-between
  align-items: center
.storeItem__comment
  font-size: 19px
  color: #FFF
  font-weight: 600
.storeItem__name
  display: flex
  align-items: center
  background-color: white
  padding: .25rem .75rem .25rem .25rem
  border-radius: .25rem
  margin-right: 2rem
  line-height: 1
  .icon
    margin-right: .25rem
.storeItem__location
  color: #FFF
  font-weight: 900
.storeItem__body
  position: absolute
  // background-color: rgba(117, 85, 246, .8)
  background-color: rgba(0, 0, 0, .6)
  top: 0
  left: 0
  width: 100%
  height: 100%
  padding: 1.25rem 1.5rem 2rem
  display: flex
  flex-direction: column
  justify-content: space-between
@media (min-width: 768px)
  .storeItem
    max-width: calc(50% - .5rem)
    margin-bottom: 1rem
@media (min-width: 1200px)
  .storeItem
    max-width: calc(33% - .5rem)
</style>
