<template>
  <div class="SearchFormMobile" :class="{'--has-location': showLocationSelector}">
    <transition name="zoom-out">
      <location-selector v-show="showLocationSelector"
      :default-value="{ locationId: undefined, provinceId: $get(getCurrentSearch, 'provinceId')}"
      @close="showLocationSelector = false"
      ></location-selector>
    </transition>
    <div class="SearchFormMobile__container">
      <div class="header__logo" @click="$router.push({ name: 'home' })">
        <img class="header__logoImg"
             :src="require('../../assets/images/logom.svg')"
             alt="logo"
        >
      </div>
      <div class="btnLocation"
         @click="showLocationSelector = true"
      >
        <div class="btnLocation__icon">
          <b-icon size="is-small" icon="map-marker"></b-icon>
        </div>
        <div class="btnLocation__content">
          <template v-if="$route.name === 'stores-nearly'">
            Cerca de mí
          </template>
          <template v-else>
            <span class="btnLocation__province">
            {{ getCurrentProvince }}
          </span>
            <span v-if="getCurrentLocation"
                  class="btnLocation__location"
            >
            {{ getCurrentLocation }}
          </span>
          </template>
        </div>
      </div>
      <slot>
        <div class="filterList"
             :class="{ 'active': showFiltersSelector }"
             ref="filters"
        >
          <label class="filterList__Btn"
                 :class="{'has-active-filters': hasFilterTypes }"
                 @click="showFiltersSelector = !showFiltersSelector"
          >
            <b-icon size="is-small" icon="tune"></b-icon>
            <div class="marker-filters"></div>
          </label>
          <div class="filterList__container">
            <filter-buttons :is-loading="isLoading"></filter-buttons>
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import LocationSelector from './LocationSelector'
import { mapGetters } from 'vuex'
import FilterButtons from '@/components/StoreResults/FilterButtons.vue'

export default {
  props: {
    isLoading: {
      required: true,
      type: Boolean
    }
  },
  name: 'SearchFormMobile',
  components: {
    FilterButtons,
    LocationSelector
  },
  data () {
    return {
      showLocationSelector: false,
      showFiltersSelector: false
    }
  },
  methods: {
    close (e) {
      if (this.showFiltersSelector && !this.$refs.filters.contains(e.target)) {
        this.showFiltersSelector = false
      }
    }
  },
  computed: {
    ...mapGetters(['getCurrentProvince', 'getCurrentLocation', 'getCurrentSearch']),
    hasFilterTypes: function () {
      return this.$get(this.getCurrentSearch, 'types', []).length
    }
  },
  mounted () {
    document.addEventListener('click', this.close)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.close)
  }
}
</script>

<style scoped lang="sass">
@import "../../assets/styles/variables"

.SearchFormMobile
  display: flex
  flex-direction: column
  background-color: white
  border-bottom: none
  align-items: center
  position: fixed
  top: 0
  left: 0
  z-index: 19
  width: 100%
  &.--has-location
    z-index: 1000
.header__logo
  height: 66px
  width: 72px
  padding: .25rem
  display: flex
  align-items: center
  justify-content: center
  max-height: initial
.btnLocation
  display: flex
  width: calc(100% - 10.5rem)
  justify-content: flex-start
  align-items: center
  gap: 0.5rem
  height: 2.5rem
  padding: 0 0.75rem
  margin: 0 1rem
  border-radius: $border-radius
  border: 1px solid rgba($white, 0.25)
.btnLocation__icon
  // color: $primary
  margin: 2px 0 0
.btnLocation__content
  font-size: 14px
  font-weight: bolder
  line-height: 1
.SearchFormMobile__container
  display: flex
  align-items: center
  width: 100%
  background-color: $primary
  color: $white
.filterList
  display: flex
  justify-content: flex-end
  position: fixed
  z-index: 100
  top: 67px
  right: 0
  width: 0
  &.active
    .filterList__container
      transform: translateX(0)
.filterList__Btn
  display: flex
  position: fixed
  top: 0
  right: 0
  z-index: 100
  width: 2.5rem
  height: 2.5rem
  align-items: center
  justify-content: center
  font-size: 26px
  background-color: rgba($white, 5%)
  margin: .75rem
  color: $white
  border-radius: .25rem
.filterList__container
  height: 100vh
  background-color: #FFF
  transform: translateX(200px)
  transition: transform .2s ease
  border-radius: 2rem 0 0 0
  input
    display: none
.btnLocation__location
  color: $secondary
  font-weight: bolder
::v-deep .filterslist
  display: flex
  flex-direction: column
  gap: 1rem
  padding: 1rem 1rem 1rem 1.5rem
  align-items: center
  .filterBtn
    width: 100%
    margin: 0
    &:not(.--selected)
      .filterBtn__label, .filterBtn__counter, .filterBtn__icon
        color: #000 !important
.marker-filters
  z-index: 20
  display: none
  background: $secondary
  width: 8px
  height: 8px
  border-radius: 8px
  position: absolute
  top: 4px
  right: 4px
.has-active-filters
  .marker-filters
    display: flex
</style>
