<template>
  <div class="items_container__grid">
    <article class="StoreItem" v-for="x in 6" :key="`skeleton-${x}`">
      <header class="StoreItem__image">
        <figure class="b-image-wrapper image">
          <b-skeleton height="140px" width="124px"></b-skeleton>
        </figure>
      </header>
      <div class="StoreItem__body">
        <div class="store__info">
          <span class="name">
            <b-skeleton width="35%" />
          </span>
          <span class="location">
            <b-skeleton width="50%" />
          </span>
        </div>
        <div class="StoreItem__container">
          <div class="StoreItem__counters">
            <span class="mr-3">
              <b-skeleton width="30px"/>
            </span>
            <span class="mr-3">
              <b-skeleton width="30px"/>
            </span>
          </div>
          <div class="types" v-if="!isMobile">
            <b-skeleton circle width="32px" height="32px"></b-skeleton>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: 'SkeletonLoading',
  inject: ['isMobile']
}
</script>

<style scoped lang="sass">
.SkeletonLoading
  width: 100%
  .items_container__grid
    display: flex
    flex-direction: column
    overflow-y: auto
</style>
