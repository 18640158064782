import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_KEY,
  loaderOptions: {
    useRecaptchaNet: true,
    autoHideBadge: true
  }
})

export default {
  methods: {
    async getRecaptchaToken (action) {
      await this.$recaptchaLoaded()
      return await this.$recaptcha(action)
    }
  },
  computed: {
    recaptchaInstance: function () {
      return this.$recaptchaInstance
    }
  },
  beforeDestroy () {
    this.recaptchaInstance.hideBadge()
  },
  async mounted () {
    await this.$recaptchaLoaded()
    this.recaptchaInstance.showBadge()
  }
}
