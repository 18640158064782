<template>
  <div>
    <b-message type="is-warning">
      Por cada ingrediente que ingreses, presiona ENTER o TAB.<br>
      Ingresá todos los ingredientes de tu receta de forma clara y sin olvidar de especificar las medidas/unidades.

    </b-message>
    <ValidationProvider v-slot="{ errors }"
                        name="Ingredientes"
                        rules="required"
                        vid="ingredients"
    >
      <b-field label="Lista de ingredientes"
               :type="errors[0] ? 'is-danger' : ''"
               :message="errors && errors[0] ? errors[0] : `Ingredientes: ${recipe.ingredients.length}`"
               class="pb-3"
      >
        <b-taginput
          v-model="recipe.ingredients"
          close-type="is-primary"
          ellipsis
          placeholder="EJ: 100ml de agua"
          type="is-primary is-light"
          aria-close-label="Borrar ingrediente"
        >
        </b-taginput>
      </b-field>
    </ValidationProvider>
  </div>
</template>

<script>

export default {
  name: 'FormIngredients',
  props: {
    recipe: {
      required: true
    }
  },
  data () {
    return {}
  }
}
</script>

<style scoped lang="sass">
</style>
