import Vue from 'vue'
import Vuex from 'vuex'
import { get } from 'lodash'
import VuexPersistence from 'vuex-persist'
import storeModule from './stores'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default new Vuex.Store({
  state: {
    user: undefined,
    token: undefined,
    preferredProvince: '1',
    userCoords: undefined,
    enableGps: false,
    locations: [],
    oauthRedirectUri: undefined,
    isFirstVisit: true,
    favorites: {
      stores: [],
      recipes: []
    }
  },
  getters: {
    getLocations: (state) => {
      return state.locations
    },
    getUserCoords: (state) => {
      return state.userCoords
    },
    getEnableGps: (state) => {
      return state.enableGps
    },
    getPreferredProvince: (state) => {
      return state.preferredProvince
    },
    getUser: (state) => {
      return state.user
    },
    getToken: (state) => {
      return state.token
    },
    getOauthRedirectUri: (state) => {
      return state.oauthRedirectUri
    },
    getIsFirstVisit: (state) => {
      return state.isFirstVisit
    },
    getFavorites: (state) => {
      return state.favorites
    }
  },
  mutations: {
    setUserCoords (state, coords) {
      state.userCoords = coords
    },
    setEnableGps (state, enable) {
      state.enableGps = enable
    },
    setLocations (state, payload) {
      state.locations.push(payload)
    },
    setUser: (state, user) => {
      state.user = user
    },
    setToken: (state, token) => {
      state.token = token
    },
    setOauthRedirectUri (state, uri) {
      state.oauthRedirectUri = uri
    },
    setIsFirstVisit (state, value) {
      state.isFirstVisit = value
    },
    setPreferredProvince: (state, province) => {
      if (!get(state.storeModule, 'search.provinceId')) {
        state.storeModule.search = { provinceId: province }
      }
      state.preferredProvince = province
    },
    setFavorites: (state, favorites) => {
      if (favorites) {
        state.favorites = favorites
      }
    },
    updateFavorites: (state, context) => {
      state.favorites[context.entity] = context.payload
    }
  },
  actions: {
    setUser (context, payload) {
      context.commit('setUser', payload.user)
      context.commit('setToken', payload.token)
      context.commit('setFavorites', payload.favorites)
      context.dispatch('setUserPreferredProvince')
    },
    setUserPreferredProvince (context) {
      if (context.state.user) {
        context.commit('setPreferredProvince', context.state.user.province_id.toString())
      }
    },
    toggleFavorite (context, { entity, id }) {
      const favorites = context.state.favorites[entity]
      let payload = favorites.concat(id)
      let add = true
      if (favorites.includes(id)) {
        // remove
        payload = favorites.filter(x => x !== id)
        add = false
      }
      context.commit('updateFavorites', { payload, entity })
      return add
    }
  },
  plugins: [vuexLocal.plugin],
  modules: {
    storeModule
  }
})
