<template>
  <div>
    <b-loading :is-full-page="false" v-model="loading"></b-loading>
    <div class="container favorites">
      <recipe-item v-for="(favorite, i) in favorites"
                  :key="i"
                  :recipe="favorite.recipe"
                  @click="e => $router.push({ name: 'recipe', params: { id: e.id} })"
      ></recipe-item>
    </div>
    <div class="has-text-centered">
      <b-button v-if="hasMore"
                icon-left="bookmark-plus"
                class="my-2"
                type="is-primary"
                :loading="loading"
                @click="getFavorites"
      >
        Ver mas
      </b-button>
      <b-message v-if="!totalFavorites && !loading"
                 type="is-info"
      >
        No tenés recetas favoritas hasta el momento.
      </b-message>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import RecipeItem from '../RecipeResults/RecipeItem'

export default {
  name: 'FavoritesView',
  components: {
    RecipeItem
  },
  data () {
    return {
      favorites: [],
      totalFavorites: 0,
      hasMore: false,
      loading: true,
      page: 0
    }
  },
  methods: {
    getFavorites: function () {
      this.loading = true
      this.page += 1
      const params = {
        size: 6,
        page: this.page
      }

      axios.get('recipes/favorites/', { params })
        .then(({ data }) => {
          this.favorites = this.favorites.concat(data.results)
          this.totalFavorites = data.count
          this.hasMore = data.next
        })
        .finally(() => { this.loading = false })
    }
  },
  created () {
    this.getFavorites()
  }
}
</script>

<style scoped lang="sass">
.favorites_box
  background-color: #FFF
  border: 1px solid black
  border-radius: 10px
  width: 100%
.favorites
  display: flex
  align-items: center
  flex-wrap: wrap
  gap: 1rem
  justify-content: center
:deep(.StoreItem)
  width: 100%
@media (min-width: 768px)
  :deep(.StoreItem)
    width: 550px
</style>
