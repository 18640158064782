<template>
  <white-navbar>
    <div class="mobile-header">
      <input-text name="searcher"
                  class="searcher mr-1"
                  placeholder="Buscar"
                  icon="magnify"
                  :icon-right="params.search ? 'close' : ''"
                  :value="params.search"
                  @input="e => $emit('updateSearch', e)"
                  @icon-right-click="$emit('cleanSearch')"
      ></input-text>
      <div class="btn" :class="{ '--has-filters-active': hasFiltersActive}" @click="showFilters = true">
        <b-icon icon="tune"></b-icon>
        <div class="marker-filters"></div>
      </div>
    </div>
    <transition name="zoom-out">
      <modal-filter v-show="showFilters"
                    title="Filtros"
                    @close="showFilters = false"
      >
        <section class="p-3">
          <h3 class="subtitle">
            Dificultad
          </h3>
          <input-select name="difficulty"
                        icon="tune-variant"
                        class="filter"
                        :options="[
                            { id: '', label: 'Todas' },
                            { id: 1, label: 'Simple' },
                            { id: 2, label: 'Moderada' },
                            { id: 3, label: 'Compleja' }
                          ]"
                        :value="params.difficulty"
                        @input="e => $emit('changeSelect', {
                      val: e,
                      key: 'difficulty'
                    })"
          >
          </input-select>
        </section>
        <section class="p-3">
          <h3 class="subtitle">
            Categorias
          </h3>
          <filter-categories v-model="params.categories"
                             @input="$emit('changeFirstPage')"
          ></filter-categories>
          <div class="clean">
            <b-button expanded
                      v-if="hasFiltersActive"
                      type="is-primary is-light"
                      @click="$emit('cleanFilters')"
            >Limpiar filtros</b-button>
          </div>
        </section>
      </modal-filter>
    </transition>
  </white-navbar>
</template>

<script>
import WhiteNavbar from '../Layout/WhiteNavbar'
import inputText from '../Common/InputText'
import inputSelect from '../Common/InputSelect'
import ModalFilter from './ModalFilter'
import FilterCategories from './FilterCategories'

export default {
  name: 'MobileHeader',
  components: {
    WhiteNavbar,
    inputText,
    ModalFilter,
    inputSelect,
    FilterCategories
  },
  props: {
    params: {
      required: true
    }
  },
  data () {
    return {
      showFilters: false
    }
  },
  computed: {
    hasFiltersActive: function () {
      return this.params.categories.length || this.params.difficulty
    }
  }
}
</script>

<style scoped lang="sass">
@import "src/assets/styles/variables"

.mobile-header
  width: calc(100% - 77px)
  display: flex
  align-items: center
  justify-content: flex-end
  .btn
    display: flex
    align-items: center
    padding: 1rem
.clean
  padding-top: 3rem
:deep()
  .categories
    flex-wrap: wrap
    padding: 0
  .searcher input
    border-radius: 15px
  .field
    margin: 0
.marker-filters
  display: none
  background: red
  width: 8px
  height: 8px
  border-radius: 8px
  position: absolute
  top: 1rem
  right: 1rem
.--has-filters-active
  .marker-filters
    display: flex
</style>
