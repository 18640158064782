<template>
  <div>
    <recipe-wizard @save="addRecipe"></recipe-wizard>
    <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
  </div>
</template>

<script>
import axios from 'axios'
import RecipeWizard from '../components/NewRecipe/RecipeWizard'

import NeedToken from '@/components/Mixins/NeedToken'

export default {
  name: 'NewRecipe',
  mixins: [
    NeedToken
  ],
  components: {
    RecipeWizard
  },
  metaInfo () {
    return {
      title: 'Nueva receta'
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    addRecipe: function (recipe) {
      this.isLoading = true
      axios.post('recipes/add/', recipe)
        .then(({ data }) => {
          this.uploadImage(data, recipe)
            .catch(e => {
              // eslint-disable-next-line no-console
              console.error('error upload', e)
            })
            .finally(() => {
              this.isLoading = false
              this.$buefy.snackbar.open({
                message: 'Tu receta se publicó exitosamente. Gracias por colaborar con nosotros!',
                type: 'is-success',
                duration: 10 * 1000
              })
              this.$router.push({ name: 'recipes' })
            })
        })
        .catch(e => {
          this.isLoading = false
          this.$buefy.snackbar.open({
            message: e.response.data.message || e.response.data.errors,
            type: 'is-danger',
            duration: 10 * 1000
          })
        })
    },
    uploadImage: function (response, recipe) {
      let promise
      const image = recipe.image
      if (image) {
        const formData = new FormData()
        formData.append('image', image)
        promise = axios.post(`recipes/add/image/${response.id}/`,
          formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
          .catch(({ response }) => {
            this.$buefy.snackbar.open({
              duration: 3000,
              message: response.data.message,
              type: 'is-danger',
              position: 'is-bottom-right'
            })
          })
      } else {
        promise = new Promise(resolve => resolve('ok'))
      }
      return promise
    }
  }
}
</script>

<style scoped>

</style>
