<template>
  <div class="news-detail">
    <section class="hero is-primary">
      <div class="hero-body">
        <b-button class="button__back"
                  icon-left="arrow-left"
                  type="is-light"
                  @click="goBack"
                  :rounded="true"
        >
          más noticias
        </b-button>
        <b-button v-if="isMobile"
                  class="button__share"
                  icon-left="share-variant"
                  type="is-primary is-light"
                  @click="share"
                  :rounded="true"
        >
        </b-button>
        <p class="title is-3">
          {{ title }}
        </p>
        <div class="subtitle is-6" v-if="post">
          <div class="author">
            <figure class="image is-32x32">
              <img class="is-rounded" :src="post.author.image.url" :alt="title">
            </figure>
            <span class="ml-3">{{ post.author.displayName }}</span>
          </div>
        </div>
      </div>
    </section>
    <div v-if="post" class="post">
      <div class="content" v-html="post.content"></div>
      <div class="date mt-6">
        <small class="has-text-weight-bold">
          Nota publicada {{ post.published | moment("from") }}
        </small>
      </div>
    </div>
    <b-loading v-else
               :is-full-page="false"
               v-model="loading"
               :can-cancel="false"
    ></b-loading>
    <div class="decoration"></div>
  </div>
</template>

<script>
import axios from 'axios'
import { getMetaInfo } from '@/modules/meta'

export default {
  props: {
    id: {
      required: true
    },
    title: {
      required: true
    }
  },
  inject: ['isMobile'],
  metaInfo () {
    return getMetaInfo(
      this.title,
      'Enterate de las últimas noticias gluten free de Argentina en Celisana',
      'assets/images/hero2.svg'
    )
  },
  data () {
    return {
      loading: true,
      post: undefined
    }
  },
  methods: {
    goBack: function () {
      if (window.history.length > 2) {
        this.$router.go(-1)
      } else {
        this.$router.push({ name: 'news' })
      }
    },
    share: function () {
      if (navigator.share) {
        navigator.share({
          title: `${this.$metaInfo.title} - Celisana `,
          text: `${this.$metaInfo.title} - Celisana `,
          url: window.location.href
        })
      }
    }
  },
  mounted () {
    axios.get(`blog/${this.id}/`)
      .then(({ data }) => {
        this.post = data
      })
      .catch((e) => {
        this.$buefy.snackbar.open({
          duration: 3000,
          message: 'Ha ocurrido un error. Reintente mas tarde',
          type: 'is-error'
        })
      })
      // eslint-disable-next-line no-return-assign
      .finally(() => this.loading = false)
  }
}
</script>
<style scoped lang="sass">
@import "src/assets/styles/variables"
.news-detail
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)), url('../assets/images/hero_bg.png')
  background-size: 900px
.hero
  padding-top: 3rem
  letter-spacing: 0.05rem
  background-color: $primary
  background-image: url(../assets/images/pttrn.svg)
  background-position: left bottom
  background-repeat: repeat-x
  background-size: 5rem
  .button
    box-shadow: 5px 10px 38px -8px rgba(0,0,0,0.60)
    z-index: 5
    height: 2.5rem
    position: fixed
    top: 1rem
    left: 1rem
    &__share
      left: initial
      right: 1rem
      width: 2.5rem
      padding: 0
  .author
    display: flex
    align-items: center
    margin-top: 3rem
.post
  margin: 1rem 1rem 3rem
  max-width: 720px
  justify-content: center
  :deep()
    .content
      overflow: auto
      line-height: 2rem
      span
        font-size: 1.25rem !important
        line-height: 2.125rem !important
        margin: 1rem 0 !important
        display: block
      p
        background-color: transparent !important
        margin: 1rem 0 !important
        display: block
      img
        border-radius: $border-radius !important
        margin: 2rem 0
        display: block !important
        width: 100%
        height: 310px
        object-fit: cover
      blockquote
        background-color: transparent
        border-left: .25rem solid $primary !important
        padding: 1rem 1rem 1rem 2rem !important
        margin: 2rem 0 !important
        span
          font-size: 1.5rem !important
          line-height: 2.5rem !important
          margin: 0 !important
      br
        display: none
@media (min-width: 768px)
  .post
    margin: 1rem auto 3rem
  .hero
    .button
      position: absolute
      left: 7rem
</style>
