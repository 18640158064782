<template>
  <div class="store_box">
    <b-loading v-if="!stores.length" :is-full-page="false" v-model="loading"></b-loading>
    <div class="items_container__grid">
      <store-item v-for="(store, i) in stores"
                  :key="i"
                  :store="store"
                  @click="e => $router.push({ name: 'store', params: { id: e.id} })"
      ></store-item>
    </div>
    <b-button v-if="hasMore"
              class="my-2"
              type="is-primary"
              :loading="loading"
              @click="getStores"
              :expanded="isMobile"
    >
      Ver mas
    </b-button>
    <b-message v-if="!totalStores && !loading"
               type="is-info"
    >
      Este usuario no ha creado ningun comercio hasta el momento.
    </b-message>
  </div>
</template>

<script>
import StoreItem from '@/components/StoreResults/StoreItem'
import axios from 'axios'

export default {
  name: 'StoreBox',
  props: {
    member: {
      required: true
    }
  },
  components: {
    StoreItem
  },
  inject: ['isMobile'],
  data () {
    return {
      stores: [],
      totalStores: 0,
      hasMore: false,
      loading: true,
      page: 0
    }
  },
  methods: {
    getStores: function () {
      this.loading = true
      this.page += 1
      const params = {
        size: 6,
        page: this.page
      }
      axios.get(`members/${this.member.user}/stores/`, { params })
        .then(({ data }) => {
          this.stores = this.stores.concat(data.results)
          this.totalStores = data.count
          this.hasMore = data.next
        })
        .finally(() => { this.loading = false })
    }
  },
  created () {
    this.getStores()
  }
}
</script>

<style scoped lang="sass">
.store_box
  min-height: 5rem
:deep(.StoreItem)
  width: 100%
.items_container__grid
  display: flex
  flex-direction: row
  flex-wrap: wrap
  justify-content: space-between
  :deep(.StoreItem)
    margin: 1rem 0
@media (min-width: 1200px)
  :deep(.StoreItem)
    width: calc(50% - 1rem)
    margin: 1rem
</style>
