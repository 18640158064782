<template>
  <div class="selectLocation">
    <transition name="zoom-out">
      <province-selector v-if="showProvinces"
                         @close="showProvinces = false"
                         @set-province="setProvince"
      ></province-selector>
    </transition>
    <div class="selectLocation__header">
      <div class="selectLocation__back">
        <b-button size="is-regular"
                  type="is-primary"
                  inverted
                  outlined
                  icon-left="arrow-left"
                  @click="close"
        >
        </b-button>
      </div>
      <div class="subtitle is-3">
        Buscar por Partido
      </div>
    </div>
    <div class="selectLocation__container">
      <div class="selectLocation__state" @click="showProvinces = !showProvinces">
        <div class="selectLocation__stateContainer">
          <img :src="provinceImage"
               class="selectLocation__stateFlag"
               :alt="getCurrentProvince"
          >
          <div class="selectLocation__stateInfo">
            <div class="selectLocation__stateLabel">Filtrando partidos de</div>
            <div class="selectLocation__stateName">{{ getCurrentProvince }}</div>
          </div>
          <b-icon icon="pencil"></b-icon>
        </div>
      </div>
      <div class="selectLocation__search">
        <b-field>
          <b-input placeholder="Buscar por nombre"
                  type="search"
                  rounded
                  icon="magnify"
                  icon-clickable
                  v-model="locationName"
          >
          </b-input>
        </b-field>
      </div>
      <div class="selectLocation__list">
        <div  v-for="(location, idx) in filteredLocations"
              :key="idx"
              class="selectLocation__listItem"
              @click="setLocation(location.id)"
        >
          <b-icon v-if="location.id === -1"
                  icon="compass"
                  size="is-small mr-3">
          </b-icon>
          <b-icon v-else
                  icon="map-marker"
                  size="is-small mr-3">
          </b-icon>
          <span class="selectLocation__listItemName">{{ location.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProvinceSelector from './ProvinceSelector'
import SearchForm from './SearchForm'
import { mapGetters } from 'vuex'

export default {
  name: 'LocationSelector',
  mixins: [
    SearchForm
  ],
  components: {
    ProvinceSelector
  },
  data () {
    return {
      showProvinces: false,
      locationFilter: ''
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    setProvince: function (id) {
      this.search.provinceId = id
      this.submit()
    },
    setLocation: function (id) {
      this.search.locationId = id
      this.submit()
      new Promise(resolve => setTimeout(resolve, 50))
        .then(() => this.close())
    }
  },
  computed: {
    ...mapGetters(['getCurrentProvince', 'getCurrentLocation']),
    provinceImage: function () {
      return require(`../../assets/images/provinces/${this.search.provinceId || 1}.png`)
    }
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles/variables"

.selectProvince__list
  overflow-y: auto
.selectLocation
  display: flex
  flex-direction: column
  width: 100%
  position: fixed
  height: 100vh
  top: 0
  left: 0
  background-color: $primary
  // color: $white
  z-index: 110
  &__header
    display: flex
    align-items: center
    justify-content: space-between
    min-height: 64px
    padding: 0rem 2.75rem 0rem 0.75rem
  .subtitle
    color: $white
    display: flex
    flex: 1
    justify-content: center
    font-size: 22px
    font-weight: bold
.selectLocation__back
  // padding: .5rem 1rem
  .button
    height: 2.5rem
    width: 2.5rem
.selectLocation__state
  padding: 1rem
  // border: 1px solid $white
  // border-bottom: none
  // border-radius: 55px 55px 0 0
.selectLocation__stateContainer
  padding: 0.5rem
  color: $white
  display: flex
  align-items: center
  border-radius: 50px
  // border: 1px solid $white
  background-color: rgba($black, 12%)
  .icon
    width: 4rem
.selectLocation__stateName
  font-size: 1.35rem
  font-weight: 900
  line-height: 1
.selectLocation__stateCounter
  font-size: 14px
  font-weight: 600
  color: #7555F6
.selectLocation__stateFlag
  height: 2.5rem
  max-width: 2.5rem
  width: 100%
  object-fit: cover
  border-radius: 45px
  overflow: hidden
  display: flex
.selectLocation__stateInfo
  display: flex
  flex-direction: column
  padding-left: 1rem
  width: 100%
  gap: .25rem
.selectLocation__stateLabel
  font-weight: bolder
  font-size: 1rem
  line-height: 1
  color: $secondary
.selectLocation__search
  padding: 1rem
  background-color: #EEEBFC
  border-radius: 40px 40px 0 0
  ::v-deep
    .input
      border-color: rgba(0,0,0,.2)
      padding: .5rem .5rem .5rem 3.5rem !important
      height: 3rem
    .icon
      height: 3rem !important
      width: 3.25rem !important
      text-align: center
.selectLocation__list
  background-color: #FCFBFF
  overflow-y: auto
  height: 100%
// padding: 2rem 0
.selectLocation__container
  display: flex
  flex-direction: column
  gap: 0
  padding: 0
  overflow: auto
  height: 100%
  border: 1px solid #F9F7FE
  border-bottom: none
  border-radius: 50px 50px 0 0
  margin: 0 0.5rem
.selectLocation__listItem
  display: flex
  align-items: center
  padding: 1rem 1.25rem
  gap: .5rem
  border-bottom: 1px solid rgba(0,0,0,.1)
  cursor: pointer
  .icon
    opacity: .4
.selectLocation__listItemName
  font-size: 1.2rem
  font-weight: 900
  letter-spacing: .15px
@media (min-width: 768px)
  .selectLocation
    display: none
</style>
