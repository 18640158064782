<template>
  <article class="StoreItem" @click="$emit('click', store)">
    <header class="StoreItem__image">
      <b-image
        :src="imageUrl"
        :src-fallback="require('../../assets/images/fallback_store.png')"
        :alt="store.name"
      >
      </b-image>
    </header>
    <div class=StoreItem__body>
      <div class="store__info">
        <span class="name">{{ store.name }}</span>
        <span class="location" :title="`${store.address}, ${store.location.name}`">
          {{ store.address }}, {{ store.location.name }}
        </span>
        <div class="StoreItem__counters --distance" v-if="!isMobile">
          <b-tooltip v-if="store.distance"
                     label="Distancia"
          >
            <div class="counters__box">
              <b-icon icon="map-marker" size="is-small"></b-icon>
              <span class="text">{{ store.distance }} km</span>
            </div>
          </b-tooltip>
        </div>
      </div>
      <div class="StoreItem__container">
        <div class="StoreItem__counters">
          <b-tooltip label="Comentarios">
            <div class="counters__box --comment">
              <b-icon icon="comment-text-multiple" size="is-small"></b-icon>
              <span class="text">{{ store.comments_count }}</span>
            </div>
          </b-tooltip>
          <b-tooltip label="Calificación">
            <div class="counters__box --star">
              <b-icon icon="star" size="is-small"></b-icon>
              <span class="text">
                {{ store.comments_count ? store.avg_score : '?' }}
              </span>
            </div>
          </b-tooltip>
          <b-tooltip label="Distancia" v-if="isMobile && store.distance">
            <div class="counters__box --distance">
              <b-icon icon="map-marker" size="is-small"></b-icon>
              <span class="text">{{ store.distance }} km</span>
            </div>
          </b-tooltip>
        </div>
        <div class="types">
          <b-tooltip v-for="type in store.types"
                     :key="type.id"
                     :label="type.name"
          >
            <b-icon :icon="storeType(type.id).style.icon"
                    size="is-small"
                    :style="{'background-color': storeType(type.id).style.color}"
            ></b-icon>
          </b-tooltip>
        </div>
      </div>
    </div>
  </article>

</template>

<script>
import storeTypes from '../../constants/storeTypes'

export default {
  name: 'StoreItem',
  props: {
    store: Object
  },
  inject: ['isMobile'],
  data () {
    return {
      storeTypes: storeTypes
    }
  },
  computed: {
    imageUrl: function () {
      return process.env.VUE_APP_IMAGES_URL + `/stores/${this.store.id}.jpg`
    }
  },
  methods: {
    storeType: function (id) {
      return storeTypes.find(x => x.id === id)
    }
  }
}
</script>

<style lang="sass">
@import "../../assets/styles/variables"

.StoreItem__container
  display: flex
  width: 100%
  justify-content: space-between
  align-items: center
.StoreItem
  display: flex
  border-radius: $input-radius
  border: 1px solid black
  padding: 0
  overflow: hidden
  box-shadow: 0 5px 0 0 rgba(199, 199, 199, .26)
  transition: box-shadow 0.2s ease-in-out, border-color .2s ease-in-out, background-color .2s ease
  cursor: pointer
  margin-bottom: 1rem
  flex-direction: column
  &:hover
    box-shadow: 0px 0 0px 0px rgba(199, 199, 199, .26)
    border-color: $primary
    background-color: #f4f1ff
  .image
    align-items: center
    display: flex
    width: 100%
    height: 100%
    justify-content: center
    padding: 0
    img
      object-fit: cover
      height: 140px
      align-self: flex-start
.StoreItem__body
  padding: 1rem
  width: 100%
  color: #2a2a2a
  justify-content: space-between
  display: flex
  flex-direction: column
  .store__info
    letter-spacing: .08rem
    padding-bottom: 1rem
    display: flex
    flex-direction: column
    width: 100%
    .name
      font-weight: bolder
      width: 100%
      font-size: 1.25rem
      line-height: 1.35
      margin-bottom: 0.5rem
    .location
      color: black
      font-size: 14px
      padding-bottom: 0.5rem
.StoreItem__counters
  display: flex
  align-content: center
  gap: .75rem
  position: relative
  &.--distance
    .icon
      color: #01319d
  .counters__box
    display: flex
    align-items: center
    background: #f5f1ff
    padding: 0.25rem 0.75rem
    border-radius: 4px
    font-weight: 900
    &.--star .icon
      color: #ffc300
    &.--comment .icon
      color: #999
    &.--distance
      display: none
      .icon
        color: #01319d
    .text
      font-size: 14px
    .icon
      margin-right: .75rem
      width: 1rem
.types
  flex-wrap: wrap
  margin-left: .25rem
  gap: 0.25rem
  display: flex
  justify-content: flex-end
  .icon.is-small
    height: 1.75rem
    width: 1.75rem
    border-radius: 50%
    color: #FFF
@media (min-width: 768px)
  .StoreItem
    flex-direction: row
    overflow: visible
    width: 550px
    .image
      width: 140px
      padding: 0.5rem 0.5rem
      img
        border-radius: $input-radius
  .StoreItem__body
    padding: 0.5rem
    .store__info
      .name
        font-size: 1.15rem
        line-height: 1.4
        padding: 0.25rem 0
        margin: 0
</style>
