<template>
  <ValidationProvider v-slot="{ errors }"
                      name="Tipo de comercio"
                      rules="required"
                      vid="types_ids"
  >
    <section class="mb-3">
      <span class="label">Tipo de comercio</span>
      <b-field v-for="store in storeTypes"
               :key="store.id"
      >
        <b-checkbox :value="value"
                    :native-value="store.id"
                    :style="{'color': store.style.color}"
                    @input="e => $emit('input', e)"
        >
          <b-icon :icon="store.style.icon"></b-icon><span class="ml-1">{{ store.name }}</span>
        </b-checkbox>
      </b-field>
      <p class="help"
         v-text="errors[0]"
         :class="errors[0] ? 'is-danger' : ''"></p>
    </section>
  </ValidationProvider>
</template>

<script>
import storeTypes from '../../constants/storeTypes'

export default {
  name: 'StoreTypes',
  props: {
    value: {
      required: true
    }
  },
  data () {
    return {
      storeTypes: storeTypes
    }
  }
}
</script>

<style scoped>

</style>
