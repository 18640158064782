<template>
  <ValidationProvider name="Dificultad" rules="required" vid="difficulty">
    <b-field label="Dificultad" class="mb-2">
      <b-radio-button v-for="difficulty in difficulties"
                      :key="difficulty.id"
                      :value="value"
                      :native-value="difficulty.id"
                      :type="`${difficulty.type} is-light is-outlined`"
                      @input="e => $emit('input', e)"
      >
        <b-icon :icon="difficulty.icon"></b-icon>
        <span>{{ difficulty.name }}</span>
      </b-radio-button>
    </b-field>
  </ValidationProvider>
</template>

<script>
import difficulties from '../../constants/recipeDifficulties'

export default {
  name: 'Difficulties',
  props: {
    value: {
      required: true
    }
  },
  data () {
    return {
      difficulties
    }
  }
}
</script>
