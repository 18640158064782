<template>
  <div class="mapComponent" :id="mapId"></div>
</template>

<script>
import { map, tileLayer, marker, icon, layerGroup, LatLng } from 'leaflet/dist/leaflet'

export default {
  name: 'MapComponent',
  props: {
    center: {
      required: true
    },
    zoom: {
      required: true
    },
    coordsList: {
      required: false,
      type: Array,
      default: () => []
    },
    mapId: {
      required: false,
      default: 'myMap'
    },
    showUser: {
      required: false,
      default: true
    },
    markOnClick: {
      required: false
    },
    syncUser: {
      required: false
    },
    keepCurrentZoom: {
      required: false
    }
  },
  watch: {
    currentCoords: {
      handler () {
        if (!this.syncUser) return
        this.cleanUserLayer()
        this.addUserMarker()
      }
    },
    coordsList: {
      async handler () {
        await this.map.removeLayer(this.layerMarkers)
        this.layerMarkers = layerGroup()
        this.updateMarkers()
      }
    }
  },
  data () {
    return {
      map: undefined,
      layerMarkers: layerGroup(),
      layerUser: layerGroup(),
      icons: {}
    }
  },
  methods: {
    updateMarkers: function () {
      this.addUserMarker()
      this.addMarkers()
      if (this.center) {
        this.map.setView(new LatLng(...this.center), this.keepCurrentZoom ? undefined : this.zoom)
      }
      if (this.markOnClick) {
        this.map.on('click', (e) => {
          this.map.removeLayer(this.layerMarkers)
          this.layerMarkers = layerGroup()
          this.$emit('click-map', `${e.latlng.lat},${e.latlng.lng}`)
          const markerItem = marker(e.latlng, { icon: this.icons.user })
          this.layerMarkers.addLayer(markerItem).addTo(this.map)
        })
      }
    },
    addMarkers: function () {
      this.coordsList.forEach(item => {
        // has override icon
        let storeIcon = item.iconOverride ? this.icons[item.iconOverride] : this.icons.store
        if (item.types && item.types.length) {
          const iconName = item.types[0].name.toLowerCase()
          storeIcon = this.icons[iconName] || this.icons.store
        }
        const markerItem = marker(item.coords, { icon: storeIcon })
        if (item.name) markerItem.bindTooltip(item.name)
        this.layerMarkers.addLayer(markerItem)
        if (item.name) {
          markerItem.on('click', () => {
            this.$emit('click-item', item)
          })
        }
      })
      this.layerMarkers.addTo(this.map)
    },
    addUserMarker: function () {
      if (this.showUser && this.currentCoords) {
        const userMarker = marker(this.currentCoords, { icon: this.icons.user })
          .bindPopup(
            '<strong>Aquí estas tú.</strong><br>Activa el GPS para<br>obtener mayor precisión',
            { closeButton: false }
          )
        this.layerUser.addLayer(userMarker)
        this.layerUser.addTo(this.map)
      }
    },
    cleanUserLayer: function () {
      this.map.removeLayer(this.layerUser)
      this.layerUser = layerGroup()
    }
  },
  computed: {
    currentCoords: function () {
      return this.$store.getters.getUserCoords
    }
  },
  mounted () {
    this.map = map(this.mapId, {
      zoom: this.zoom
    })
    // tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
    //   attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
    // }).addTo(this.map)
    tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
    }).addTo(this.map)

    this.icons.user = icon({
      iconUrl: require('../assets/images/map/user.png'),
      shadowUrl: require('../assets/images/map/shadow.png'),
      className: 'icon-user',
      iconSize: [44, 46],
      popupAnchor: [0, -22],
      shadowSize: [36, 38],
      iconAnchor: [22, 46]
    })
    const iconsAvailable = ['resto', 'bar', 'cafe', 'store', 'heladeria']
    iconsAvailable.forEach(name => {
      this.icons[name] = icon({
        iconUrl: require(`../assets/images/map/${name}.png`),
        shadowUrl: require('../assets/images/map/shadow.png'),
        className: `icon-store icon-${name}`,
        shadowSize: [44, 45],
        iconSize: [44, 45],
        iconAnchor: [22, 45]
      })
    })
    this.updateMarkers()
  }
}
</script>

<style lang="sass">
@import "~leaflet/dist/leaflet.css"
@import "src/assets/styles/variables"

.mapComponent
  z-index: 1
  margin: 0
  border-radius: $border-radius
  .leaflet-marker-icon:hover
    filter: brightness(0.95)
  .icon-user
    z-index: 1 !important
  .icon-store
    z-index: 0 !important
</style>
