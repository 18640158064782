<template>
  <div class="container">
    <store-form ref="form"
                :is-loading="isLoading"
                @submit="callApi"
    ></store-form>
  </div>
</template>

<script>
import axios from 'axios'
import StoreForm from '@/components/NewStore/StoreForm'

export default {
  name: 'NewStore',
  components: {
    StoreForm
  },
  metaInfo () {
    return {
      title: 'Nueva comercio'
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    callApi: function () {
      this.isLoading = true
      axios.post('stores/add/', this.$refs.form.store)
        .then(({ data }) => {
          this.uploadImage(data)
            .then(r => {
              this.$buefy.dialog.alert({
                title: 'Alta exitosa',
                message: 'El comercio se cargó <b>exitosamente</b>.<br> En las próximas horas nuestros moderadores van a ' +
                  'revisar tu solicitud para comprobar que la información cargada sea válida y completa para su alta.' +
                  '<br>¡Muchas gracias por colaborar con nosotros!',
                confirmText: 'Aceptar'
              })
              this.$router.push({ name: 'home' })
            })
            .finally(() => {
              this.isLoading = false
            })
        })
        .catch(({ response }) => {
          this.isLoading = false
          if (response.data.errors) {
            this.$refs.form.$refs.validator.setErrors(response.data.errors)
          } else {
            this.$buefy.snackbar.open({
              duration: 3000,
              message: response.data.message,
              type: 'is-danger',
              position: 'is-bottom-right'
            })
          }
        })
    },
    uploadImage: async function (store) {
      let promise
      const image = this.$refs.form.image
      if (image) {
        const formData = new FormData()
        formData.append('image', image)
        promise = axios.post(`stores/add/image/${store.id}/`,
          formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
          .catch(({ response }) => {
            // eslint-disable-next-line no-console
            console.error('error upload', response.data)
            this.$buefy.snackbar.open({
              duration: 3000,
              message: response.data.message,
              type: 'is-danger',
              position: 'is-bottom-right'
            })
          })
      } else {
        promise = new Promise(resolve => resolve('ok'))
      }
      return promise
    }
  }
}
</script>

<style scoped>

</style>
