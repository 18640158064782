<template>
  <article class="StoreItem" @click="$emit('click', store)">
    <header class="StoreItem__image">
      <b-image
        :src="imageUrl"
        :src-fallback="require('../../assets/images/fallback_store.png')"
        :alt="store.name"
      >
      </b-image>
    </header>
    <div class=StoreItem__body>
      <div class="store__info">
        <span class="name">{{ store.name }}</span>
        <span class="location" :title="`${store.address}, ${store.location.name}`">
          {{ store.address }}, {{ store.location.name }}
        </span>
      </div>
    </div>
  </article>

</template>

<script>
import store from '../StoreResults/StoreItem'

export default {
  name: 'TopStore',
  mixins: [
    store
  ]
}
</script>

<style lang="sass" scoped>
@media (min-width: 768px)
  .StoreItem
    height: 80px
    margin: 1rem 0
    width: 100%
    border-color: #CCC
    :deep()
      .image img
        height: 100%
        width: 100%
      .StoreItem__container
        display: none
      .StoreItem__image
        width: 100px
  .store_container
    margin-top: 2rem
    display: flex
    flex-direction: column
    width: 100%
    align-items: center
  .StoreItem
    .StoreItem__body
      width: 76%
      .location, .name
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
    .image
      width: 100%
      min-width: 100px
</style>
