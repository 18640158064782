<template>
  <div id="app">
    <component :is="showNavbar ? 'navbar' : 'nav-rail'"></component>
    <main :class="{'--has-nav-rail': !showNavbar}">
      <header-view></header-view>
      <transition name="fade" mode="out-in">
        <keep-alive>
          <router-view :key="$route.path" v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
      </transition>
      <transition name="fade" mode="out-in">
        <router-view :key="$route.path" v-if="!$route.meta.keepAlive"></router-view>
      </transition>
    </main>
  </div>
</template>

<script>
import { checkToken } from './modules/auth'
import { getGeo } from '@/modules/coords'
import NavRail from '@/components/Layout/NavRail'
import Navbar from './components/Layout/Navbar'
import HeaderView from './components/Layout/HeaderView'

export default {
  components: {
    NavRail,
    Navbar,
    HeaderView
  },
  metaInfo: {
    title: 'Comunidad Gluten Free',
    titleTemplate: '%s | Celisana'
  },
  provide: {
    isMobile: document.body.clientWidth < 768
  },
  computed: {
    showNavbar: function () {
      return ['signup', 'home'].includes(this.$route.name)
    }
  },
  mounted () {
    checkToken(this)
    getGeo()
    this.$store.dispatch('setUserPreferredProvince')
  }
}
</script>
<style lang="sass">
@import "assets/styles/main"
</style>
