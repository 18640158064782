<template>
  <div class="container-fluid" v-if="getToken">
    <div class="container pb-5">
      <section class="section">
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
        <b-notification type="is-secondary is-light" aria-close-label="Close notification">
          Tu imagen de perfil es obtenida desde el servicio Gravatar. Si no tienes una cuenta en Gravatar asociada a tu email,
          puedes crearla haciendo click <a href="https://es.gravatar.com/" target="_blank"><b>aquí</b></a>
        </b-notification>
        <account-form v-if="member"
                      ref="form"
                      v-model="member"
                      :is-loading="isLoading"
                      @save="saveProfile"
        ></account-form>
      </section>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import NeedToken from '../components/Mixins/NeedToken'
import AccountForm from '../components/Users/AccountForm'

export default {
  name: 'MyAccountView',
  mixins: [
    NeedToken
  ],
  components: {
    AccountForm
  },
  metaInfo () {
    return {
      title: 'Configuración de cuenta'
    }
  },
  data () {
    return {
      member: undefined,
      isLoading: true
    }
  },
  methods: {
    saveProfile: function () {
      this.isLoading = true
      axios.put('profile/', this.member)
        .then(({ data }) => {
          this.showSnackbar({
            message: 'Perfil actualizado correctamente.',
            type: 'is-success'
          })
          this.$store.commit('setUser', data)
        })
        .catch(e => {
          if (e.response.data.errors) {
            this.$refs.form.$refs.validator.setErrors(e.response.data.errors)
            return
          }
          this.showSnackbar({
            message: 'Ocurrió un error al guardar tu perfil. Por favor reintenta mas tarde',
            type: 'is-danger'
          })
        })
        .finally(() => { this.isLoading = false })
    },
    showSnackbar: function (payload) {
      this.$buefy.snackbar.open({
        duration: 4000,
        message: payload.message,
        type: payload.type,
        position: 'is-bottom-right'
      })
    }
  },
  mounted () {
    if (this.getToken) {
      axios.get('profile/')
        .then(({ data }) => {
          this.member = data
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style scoped lang="sass">
</style>
