<template>
  <div class="filters">
    <div class="filterslist">
      <div v-for="type in availableTypes"
           :key="type.id"
           class="filterBtn"
           :class="{'--selected': typesSelected.includes(type.id), '--loading': isLoading}"
           :style="typesSelected.includes(type.id) ? getStyleSelected(type) : getStyle(type)"
           @click="click(type.id)"
      >
        <b-icon class="filterBtn__icon" :icon="type.style.icon" custom-size="mdi-18px"></b-icon>
        <span class="filterBtn__label">{{ type.name }}</span>
        <span class="filterBtn__counter">({{ counts[type.id] || '0' }})</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import storeTypes from '../../constants/storeTypes'
import { mapGetters } from 'vuex'

export default {
  name: 'FilterButtons',
  props: {
    isLoading: {
      required: true
    }
  },
  inject: [
    'isMobile'
  ],
  watch: {
    getCurrentSearch: {
      immediate: true,
      handler (val, old) {
        if (!old || (val.provinceId !== old.provinceId || val.locationId !== old.locationId)) {
          const cacheKey = `${val.provinceId}_${val.locationId || ''}`
          const cacheCounts = this.cacheCounts.find(x => x.key === cacheKey)
          if (cacheCounts) {
            this.counts = cacheCounts.counts
          } else {
            axios.get(`stores/${val.provinceId}/types/`, { params: { location: val.locationId } })
              .then(({ data }) => {
                this.counts = data
                this.cacheCounts.push({ key: cacheKey, counts: data })
              })
          }
        }
      }
    }
  },
  data () {
    return {
      typesSelected: this.getTypes(),
      counts: {},
      cacheCounts: [] // cache counter metrics
    }
  },
  methods: {
    getTypes: function () {
      let types = this.$route.query.types || []
      if (typeof types === 'string') {
        types = [types]
      }
      return types.map(x => (Number(x)))
    },
    click: function (itemId) {
      if (this.typesSelected.includes(itemId)) {
        this.typesSelected = this.typesSelected.filter(x => x !== itemId)
      } else {
        this.typesSelected.push(itemId)
      }
      this.$store.dispatch('applyFilters', { types: this.typesSelected })
    },
    getStyle: function (item) {
      return {
        color: item.style.color
      }
    },
    getStyleSelected: function (item) {
      return Object.assign(this.getStyle(item), { background: item.style.color })
    }
  },
  computed: {
    ...mapGetters(['getCurrentSearch']),
    availableTypes: function () {
      return storeTypes.filter(x => this.typesSelected.includes(x.id) || Object.keys(this.counts).includes(x.id.toString()))
    }
  }
}
</script>

<style scoped lang="sass">
.filterslist
  display: flex
  overflow-x: auto
  gap: 0 .5rem
  align-items: center
.filters
  min-height: 2rem
  display: flex
  width: 100%
.filterBtn
  display: flex
  height: 32px
  align-items: center
  padding: 0 1rem 0 .5rem
  // margin: 0 .5rem
  border-radius: 1.5rem
  border: 2px solid
  font-weight: 900
  font-size: 12px
  transition: background-color .2s ease
  cursor: pointer
  background: transparent
  &:first-child
    margin-left: 0
  &__label, &__counter
    color: white
    width: max-content
  &__counter
    padding-left: 0.25rem
  .icon
    margin-right: .5rem
  &:hover
    background-color: rgba(195, 178, 255, .25)
  .filterBtn__icon
    color: white !important
.--selected
  :deep()
    .filterBtn__icon
      color: white !important
.--loading
  pointer-events: none
</style>
