import { round } from 'lodash'
import { getDistance } from './coords'

const getCoordsList = (data, userCoords) => {
  return data.map(x => {
    x.avg_score = round(x.avg_score, 1)
    x.coords = x.coords.split(',')
    if (userCoords) {
      x.distance = getDistance(userCoords, x.coords)
    }
    return x
  })
}

export { getCoordsList }
