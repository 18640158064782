import { render, staticRenderFns } from "./FavoritesView.vue?vue&type=template&id=54e6d6ae&scoped=true&"
import script from "./FavoritesView.vue?vue&type=script&lang=js&"
export * from "./FavoritesView.vue?vue&type=script&lang=js&"
import style0 from "./FavoritesView.vue?vue&type=style&index=0&id=54e6d6ae&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54e6d6ae",
  null
  
)

export default component.exports