<template>
  <div class="searchForm" :class="{'has-right-click': isHero}">
    <b-button type="is-secondary"
              class="cta"
              rounded
              icon-right="map-marker-radius"
              @click="$router.push({name : 'stores-nearly'})"
    >
      Lugares cerca de mi
    </b-button>
    <div class="field searchByLocation">
      <span class="searchByLocation__title">o buscá en todo el país 👇🏼</span>
      <div class="searchByLocation__container">
        <div class="control">
          <b-select v-model="search.provinceId"
                    rounded
                    class="searchForm__province"
          >
            <option v-for="province in provinces"
                    :key="province.name"
                    :value="province.id"
            >
              {{ province.name }}
            </option>
          </b-select>
        </div>
        <div class="searchForm__city">
          <b-autocomplete
            :loading="!currentLocations.length"
            v-model="locationName"
            :data="filteredLocations"
            field="name"
            rounded
            placeholder="Buscar por partido"
            :open-on-focus="true"
            icon="map-marker"
            icon-size="is-small"
            class="input-search control"
            @select="onChangeLocation"
            @blur="blurLocation"
          >
            <template #empty>No hay resultados</template>
            <template slot-scope="{ option }">
              <div class="is-flex is-align-items-center">
                <b-icon v-if="option.id === -1"
                        icon="compass"
                        size="is-small">
                </b-icon>
                <div class="ml-3">{{ option.name }}</div>
              </div>
            </template>
          </b-autocomplete>
          <b-button type="is-primary"
                    rounded
                    icon-left="magnify"
                    @click="submit"
          >
          Buscar
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import searchForm from './SearchForm'
import { mapGetters } from 'vuex'

export default {
  name: 'SearchFormHero',
  watch: {
    getPreferredProvince: {
      handler (val) {
        this.search.provinceId = val
      }
    }
  },
  inject: ['isMobile'],
  mixins: [
    searchForm
  ],
  computed: {
    ...mapGetters(['getPreferredProvince'])
  },
  data () {
    return {
      truncateFilters: true
    }
  }
}
</script>

<style scoped lang="sass">
@import "src/assets/styles/variables"

.cta
  gap: .5rem
  padding: .5rem 2.5rem .5rem 2rem !important
  // width: 100%
  ::v-deep
    i
      font-size: 1.5rem
.searchByLocation
  display: flex
  flex-direction: column
.searchByLocation__title
  text-align: center
  width: 100%
  display: block
  font-size: 18px
  font-weight: bolder
.searchByLocation__container
  display: flex
  flex-direction: column
  align-items: center
  gap: 1.5rem
  width: 100%
:deep()
  .searchForm__province.control .select
    height: 2rem
    select
      height: 2rem
      background-color: transparent
      background-color: $primary
      border: 1px solid #FFF
      padding: 0 2.5rem 0 1.25rem
      font-size: 0.8rem
      font-weight: bolder
      color: #FFF
    &:after
      top: 1rem
      right: 1rem
      height: 0.5em
      width: 0.5em
      border-width: 2px
      border-color: #FFF !important
  .searchForm__city
    display: flex
    width: 100%
    position: relative
    flex-direction: column
    input
      border-width: 2px
      font-size: 1.35rem
      padding-left: 3rem !important
      // border-color: white
      border-color: $primary
    .control input.input
      padding-right: 5rem
    .autocomplete
      width: 100%
      .control.has-icons-left .icon.is-left
        left: .5rem
    button
      height: 3rem
      width: 3rem
      position: absolute
      right: 0.25rem
      bottom: 0.25rem
      margin: 0
      padding: 0
      font-size: 0
      .icon i
        font-size: 1.5rem
  .dropdown-menu
    padding: 0
    .dropdown-item
      font-size: 1.25rem
      font-weight: bolder
      line-height: 1.5
      &:hover
        background-color: rgba($primary, .1)
@media (min-width: 960px)
  :deep()
    .searchForm__city
      max-width: 480px
      input
        font-size: 1.25rem
        font-weight: bold
        border-top-left-radius: 0
        border-bottom-left-radius: 0
        border-left: 0
      button
        width: 9rem
        margin: 0
        position: absolute
        top: 0.25rem
        right: 0.25rem
        bottom: 0
        font-size: 1rem
        .icon i
          font-size: 1.75rem
    .searchForm__province.control
      .select
        height: 3.25rem
        select
          height: 3.25rem
          background-color: transparent
          border-top-right-radius: 0
          border-bottom-right-radius: 0
          border-right: 0
          font-size: 1rem
        &::after
          top: 1.65rem
  .searchByLocation__container
    flex-direction: row
    gap: 0
  .searchForm
    align-items: flex-start
  .searchByLocation__title
    color: $white
    text-align: left
    padding-left: .75rem
</style>
