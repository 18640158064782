<template>
  <div class="modalFilter">
    <div class="modalFilter__header">
      <div class="modalFilter__back">
        <b-button size="is-large"
                  type="is-light"
                  icon-left="arrow-left"
                  @click="$emit('close')"
        >
        </b-button>
      </div>
      <div class="subtitle is-3">
        {{ title }}
      </div>
    </div>
    <div class="modalFilter__body">
      <slot></slot>
    </div>
    <slot name="footer"></slot>
  </div>
</template>

<script>
export default {
  name: 'ModalFilter',
  props: {
    title: {
      required: true
    }
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles/variables"

.modalFilter
  display: flex
  flex-direction: column
  width: 100%
  position: fixed
  height: 100vh
  top: 0
  left: 0
  background-color: #FCFBFF
  z-index: 100
  &__header
    display: flex
    align-items: center
  &__back
    padding: .5rem 1rem
  &__body
    padding: 2rem
    //height: calc(100vh - 145px)
    overflow-y: auto
</style>
