<template>
  <div class="FounderMember" @click="$router.push({ name: 'profile', params: { id: member.pk }})">
    <div class="card-image">
      <avatar :is-rounded="true" size="48" :member="member"></avatar>
    </div>
    <div class="my-3">
      <div class="content">
        <span class="username has-text-primary">
          <b>
          {{ member.name }}
          </b>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/Common/Avatar'

export default {
  name: 'FounderMember',
  props: {
    member: {
      required: true
    }
  },
  components: {
    Avatar
  }
}
</script>

<style scoped lang="sass">
.FounderMember
  border-radius: 68px
  display: flex
  align-items: center
  text-align: left
  border: 1px solid #e3e3e3
  padding: 0 1.5rem
  max-width: 500px
  box-shadow: 0px 5px 0px 0px rgba(199, 199, 199, .25)
  transition: box-shadow 0.2s ease-in-out
  cursor: pointer
  min-width: 380px
  margin: 1rem 0
  height: 78px
  &:hover
    box-shadow: 0px 0 0px 0px rgba(199, 199, 199, .25)
    border-color: #7555F6
    background-color: #f4f1ff
.username
  width: 100%
  display: block
.card-image
  margin-right: 1.25rem
</style>
