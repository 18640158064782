<template>
  <div class="comments" id="comments">
    <div class="comments__title">
      <b-icon icon="message-reply-text"/>
      <span class="legend">Comentarios:</span>
      <span class="counter">{{ count }}</span>
    </div>

    <div class="comments__box">
      <comment-item v-for="(comment, key) in comments"
                    :key="`comment-${key}`"
                    :comment="comment"
      ></comment-item>
      <b-notification v-if="!comments.length" type="is-primary is-light" :closable="false" class="has-text-weight-bold my-5">
        Este comercio aún no tiene comentarios. ¡Se el primero en comentarlo!
      </b-notification>
    </div>
    <b-button v-if="hasMore"
              class="mb-4"
              type="is-primary"
              :loading="isLoading"
              @click="getComments"
    >
      Ver mas comentarios
    </b-button>
    <add-comment-box @add="addComment"></add-comment-box>
  </div>
</template>

<script>
import axios from 'axios'
import commentItem from './CommentItem'
import addCommentBox from './AddCommentBox'

export default {
  name: 'CommentsBox',
  components: {
    commentItem,
    addCommentBox
  },
  props: {
    store: {
      required: true
    }
  },
  data () {
    return {
      isLoading: true,
      comments: [],
      hasMore: false,
      page: 0,
      count: undefined
    }
  },
  methods: {
    addComment: function (comment) {
      this.comments = [comment].concat(this.comments)
      this.count += 1
    },
    getComments: function () {
      this.isLoading = true
      this.page += 1
      const params = { page: this.page, size: 5 }
      axios.get(`stores/${this.store.id}/comments/`, { params })
        .then(({ data }) => {
          this.count = data.count
          this.comments = this.comments.concat(data.results)
          this.hasMore = Boolean(data.next)
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.log('error comments', e)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  mounted () {
    this.getComments()
  }
}
</script>

<style scoped lang="sass">
@import "src/assets/styles/variables"

.comments
  padding: 1rem
  border: 1px solid white
  border-radius: 15px 15px 35px 35px
  &__title
    display: flex
    align-items: center
    color: $white
    padding: 0.5rem 0 1.5rem 0
    font-size: 1rem
    font-weight: bolder
    .legend
      margin-left: .5rem
    .counter
      margin-left: .5rem
@media (min-width: 768px)
</style>
