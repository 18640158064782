<template>
  <div>
    <mobile-header v-if="isMobile"
                    :params="params"
                    ref="headerMobile"
                    @changeFirstPage="changePage(1)"
                    @updateSearch="updateSearch"
                    @cleanSearch="cleanSearch"
                    @changeSelect="changeSelect"
                    @cleanFilters="cleanFilters"
    >
    </mobile-header>
    <div class="container">
      <section class="section" :class="{'--is-mobile': isMobile }">
        <desktop-filters v-if="!isMobile"
                         :params="params"
                         :total="total"
                         @changeFirstPage="changePage(1)"
                         @updateSearch="updateSearch"
                         @cleanSearch="cleanSearch"
                         @changeSelect="changeSelect"

        ></desktop-filters>
        <div v-if="isMobile" class="counter">
          {{ total }} {{ total === 1 ? 'receta encontrada' : 'recetas encontradas' }}
          <mobile-ordering :params="params"
                           @changeSelect="changeSelect"
          />
        </div>
        <div class="results">
          <b-loading :is-full-page="true"
                     v-model="isLoading"
                     :can-cancel="false"
          ></b-loading>
          <recipe-item v-for="recipe in results"
                       :key="recipe.id"
                       :recipe="recipe"
          />
        </div>
        <no-results v-if="!(total || isLoading)"></no-results>
        <b-pagination
          v-if="total"
          v-show="total > params.size"
          :total="total"
          :per-page="params.size"
          :current="params.page"
          order="is-left"
          size="is-small"
          aria-next-label="Proxima Pagina"
          aria-previous-label="Pagina Previa"
          aria-page-label="Pagina"
          aria-current-label="Pagina actual"
          @change="changePage"
        >
        </b-pagination>
      </section>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { debounce } from 'lodash'
import RecipeItem from '../components/RecipeResults/RecipeItem'
import BackPopState from '../components/Mixins/BackPopState'
import NoResults from '../components/RecipeResults/NoResults'
import DesktopFilters from '../components/RecipeResults/DesktopFilters'
import MobileHeader from '../components/RecipeResults/MobileHeader'
import MobileOrdering from '../components/RecipeResults/MobileOrdering'
import { getMetaInfo } from '@/modules/meta'

export default {
  name: 'RecipesResultsView',
  components: {
    RecipeItem,
    NoResults,
    DesktopFilters,
    MobileHeader,
    MobileOrdering
  },
  inject: ['isMobile'],
  metaInfo () {
    return getMetaInfo(
      'Recetas',
      'Busca tus recetas favoritas en nuestra comunidad. Y no te olvides de sumar las tuyas tambien!',
      'assets/images/hero-01.svg'
    )
  },
  mixins: [
    BackPopState
  ],
  data () {
    return {
      params: {
        search: '',
        size: 6,
        page: 1,
        ordering: '-created',
        difficulty: '',
        categories: []
      },
      total: 0,
      results: [],
      isLoading: true,
      arrayParams: ['categories']
    }
  },
  methods: {
    isNumeric: function (num) {
      return num && !Array.isArray(num) && !isNaN(num)
    },
    getResults: function () {
      // update url
      this.isLoading = true
      const params = new URLSearchParams()
      for (const paramName in this.params) {
        if (this.arrayParams.includes(paramName)) {
          const data = this.params[paramName]
          if (data) {
            const dataList = Array.isArray(data) ? data : [data]
            for (const idx in dataList) {
              params.append(paramName, dataList[idx])
            }
          } else {
            this.params[paramName] = []
          }
        } else {
          params.append(paramName, this.params[paramName])
        }
      }
      window.history.pushState(params.toString(), '', '?' + params.toString())
      axios.get('recipes/list/', { params })
        .then(e => {
          this.results = e.data.results
          this.total = e.data.count
        })
        .finally(() => {
          this.isLoading = false
          if (this.isMobile) {
            this.$refs.headerMobile.showFilters = false
            this.$nextTick(() => {
              window.scroll({
                top: 0,
                behavior: 'smooth'
              })
            })
          }
        })
    },
    changePage: function (page) {
      this.params.page = page
      this.getResults()
    },
    changeSelect: function ({ val, key }) {
      this.params.page = 1
      this.params[key] = val
      this.getResults()
    },
    cleanSearch: function () {
      this.params.search = ''
      this.getResults()
    },
    updateSearch: debounce(function (val) {
      this.params.page = 1
      this.params.search = val
      this.getResults()
    }, 750),
    cleanFilters: function () {
      Object.assign(this.params, {
        search: '',
        difficulty: '',
        page: 1,
        categories: []
      })
      this.getResults()
    }
  },
  created () {
    this.params = Object.assign(this.params, this.$route.query)
    for (const paramName in this.params) {
      const value = this.params[paramName]
      if (this.isNumeric(value)) this.params[paramName] = parseInt(value)
      if (this.arrayParams.includes(paramName) && !Array.isArray(value)) this.params[paramName] = [value]
    }
    this.getResults()
  }
}
</script>

<style scoped lang="sass">
@import "src/assets/styles/variables"

.section
  padding: 1rem
  &.--is-mobile
    margin-top: 66px
    margin-bottom: 1rem
.results
  display: flex
  gap: 1rem
  flex-wrap: wrap
  padding: 1rem 0 2rem
:deep()
  .select:not(.is-multiple)
    height: 2.5rem
  .select select, .input
    font-weight: bold
    height: 2.5rem
    padding: .25rem .25rem .25rem .75rem
  .control.has-icons-left .icon, .control.has-icons-right .icon
    height: 2.5rem
  .control
    .select, select
      width: 100%
  .searcher .input
    font-weight: normal
.counter
  display: flex
  justify-content: space-between
  align-items: center
  color: #4A4A4AFF
  font-size: 1.25rem
  font-weight: 600
  line-height: 1.6
</style>
