<template>
  <div class="filters">
    <div class="filterslist">
      <div v-for="type in store.types"
           :key="type.id"
           class="filterBtn"
           :style="getStyle(type.id)"
      >
        <b-icon class="filterBtn__icon" :icon="getType(type.id).style.icon" custom-size="mdi-18px"></b-icon>
        <span class="filterBtn__label">{{ type.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import storeTypes from '../../constants/storeTypes'

export default {
  props: {
    store: {
      required: true
    }
  },
  name: 'Categories',
  methods: {
    getStyle: function (id) {
      const storeType = this.getType(id)
      return {
        color: storeType.style.color
      }
    },
    getType: function (id) {
      return storeTypes.find(x => x.id === id)
    }
  }
}
</script>

<style scoped lang="sass">
.filterslist
  display: flex
  overflow-x: auto
  // overflow-y: hidden
  gap: 0 1rem
  padding-bottom: .25rem
.filters
  display: flex
  width: 100%
  max-width: calc(100vw - 339px)
.filterBtn
  display: flex
  height: 32px
  align-items: center
  padding: 0 .5rem
  border-radius: 10px
  border: 2px solid
  font-weight: 900
  font-size: 12px
  cursor: pointer
  background: white
  &__label, &__counter
    min-width: max-content
    color: black !important
  .icon
    margin-right: .5rem
</style>
