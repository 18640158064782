<template>
  <ValidationObserver ref="validator" v-slot="{ invalid }">
    <form @submit.prevent="save" id="sign-up-form">
      <div class="createAcc surface">
        <input-text label="Email"
                    name="user"
                    icon="at"
                    validate="required|email"
                    placeholder="Tu email"
                    v-model="payload.user"
        ></input-text>
        <b-field>
          <password-confirm v-model="payload.password"/>
        </b-field>
        <b-field>
          <b-button
            :disabled="isLoading || invalid"
            icon-left="content-save"
            label="Crear cuenta"
            type="is-primary"
            native-type="submit"
            :loading="isLoading"
          />
        </b-field>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import AccountForm from './AccountForm'
import PasswordConfirm from './PasswordConfirm'

export default {
  name: 'SignUpForm',
  mixins: [
    AccountForm
  ],
  components: {
    PasswordConfirm
  }
}
</script>

<style scoped lang="sass">
.createAcc
  display: flex
  flex-direction: column
  max-width: 380px
  margin: 0 auto
  .field:last-child
    display: flex
    justify-content: flex-end
</style>
