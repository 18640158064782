<template>
  <div class="container-fluid">
    <div class="--intro
                container-fluid
                is-flex
                is-justify-content-center
                ">
      <div class="section">
        <h1 class="title
                   is-2
                   has-text-centered
                   ">Hablemos de Celiaquia</h1>
        <img src="../assets/images/about/hero.png" alt="hero">
      </div>
    </div>
    <div class="container-fluid
                has-background-primary
                ">
      <div class="section py-0">
        <p class="subtitle
                  is-4
                  has-text-white
                  has-text-weight-bold
                  has-text-centered
                  ">y como se ha convertido en una condición<br>cada vez más común entre la población.</p>
      </div>
    </div>
    <div class="--intestine
                container-fluid
                has-background-primary-light
                is-flex
                is-justify-content-center
                is-align-items-center
                ">
      <div class="section">
        <p class="subtitle
                  is-4
                  has-text-centered
                  has-color-primary-dark
                  has-text-weight-bold
                  ">
          La celiaquia es una intolerancia permanente al gluten, una proteina que se encuentra en el trigo,
          avena, cebada y centeno (TACC), que afecta el intestino delgado de las personas con
          predisposicion genetica.
        </p>
      </div>
    </div>
    <div class="--childadulthood
                container-fluid
                is-flex
                is-justify-content-center
                is-flex-direction-column
                ">
      <div class="container
                  ">
        <div class="section py-0">
          <div class="columns">
            <div class="column">
              <p class="subtitle
                        is-4
                        has-text-centered
                        has-color-primary-dark
                        has-text-weight-bold
                        ">El gluten afecta la vellosidad del intestino de los celiacos y su capacidad de absorver nutrientes.</p>
            </div>
            <div class="column">
              <p class="subtitle
                        is-4
                        has-text-centered
                        has-color-primary-dark
                        has-text-weight-bold
                        ">La intolerancia puede aparecer en cualquier momento de la vida, desde la niñes o la adultes tardia.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="section py-0">
          <img src="../assets/images/about/childadulthood.png" alt="">
        </div>
      </div>
    </div>
    <div class="--argentina
                container-fluid
                ">
        <div class="section
                    is-flex
                    is-align-items-center
                    is-flex-direction-column
        ">
          <p class="subtitle
                    is-4
                    has-text-centered
                    pb-4
                    ">Un estudio publicado en 2014 muestra que la poblacion celiaca aumento un 500% en los ultimos 25 años</p>
          <img src="../assets/images/about/argentina.png" alt="">
        </div>
    </div>
    <div class="--inargentina
                container-fluid
                ">
      <div class="container">
        <div class="section pt-0">
          <h2 class="subtitle
                     is-2
                     has-text-centered
                     py-5
                     ">en Argentina...</h2>
          <div class="columns">
            <div class="column">
              <img src="../assets/images/about/in-arg-1.png" alt="">
              <p class="subtitle
                        is-4
                        has-text-centered
                        has-color-primary-dark
                        has-text-weight-bold
                        ">1 de cada 169 adultos son celiacos.</p>
            </div>
            <div class="column">
              <img src="../assets/images/about/in-arg-2.png" alt="">
              <p class="subtitle
                        is-4
                        has-text-centered
                        has-color-primary-dark
                        has-text-weight-bold
                        ">En niños el numero sube a 1 de cada 79.</p>
            </div>
            <div class="column">
              <img src="../assets/images/about/in-arg-3.png" alt="">
              <p class="subtitle
                        is-4
                        has-text-centered
                        has-color-primary-dark
                        has-text-weight-bold
                        ">Las mujeren tienden a ser 3 veces propensas a la condicion.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="--double
                container-fluid
                is-flex
                is-justify-content-center
                ">
      <div class="container">
        <div class="section">
          <div class="columns">
            <div class="column">
              <div class="box">
                <b-image :src="require('../assets/images/hero1.png')" alt="hero"></b-image>
              </div>
            </div>
            <div class="column">
              <p class="subtitle
                        is-4
                        has-text-left
                        has-color-primary-dark
                        has-text-weight-bold
                        ">La dificultad en mantener una dieta sin gluten se asocia a multiples factores que incluyen la disponibilidad, el precio, la variedad de alternativas en establecimiento gastronomicos.</p>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <p class="subtitle
                        is-4
                        has-text-left
                        has-color-primary-dark
                        has-text-weight-bold
                        ">Los Celiacos sienten un impacto negativo muy significativo en la calidad de vida en los entornos sociales.<br>Las encuestas en Argentina revelaron que un 70% de celiacos piensa que comer fuera de casa es una de las principales preocupaciones, incluso años despues de haber sido diagnosticados.</p>
            </div>
            <div class="column">
              <div class="box">
                <b-image :src="require('../assets/images/about/hero.png')" alt="about_hero"></b-image>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p class="subtitle is-2 has-text-centered has-text-weight-bold my-4">
      Nuestra mision
    </p>
    <div class="--mision
                container-fluid
                is-flex
                is-justify-content-center
                ">
      <div class="container">
        <div class="section">
          <p class="subtitle
                      is-4
                      has-text-centered
                      has-color-primary-dark
                      has-text-weight-bold
                      ">Integrar la poblacion celiaca en las diferentes areas de la vida social y facilitar su adherencia a una dieta saludable.</p>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="section">
        <p class="subtitle
                  is-2
                  has-text-centered
                  has-text-weight-bold
                  ">
          Quienes somos
        </p>
        <div class="founders__container">
          <p class="subtitle
                  is-5
                  has-text-centered
                  has-color-primary-dark
                  has-text-weight-bold
          ">
            Somos profesionales en tecnología, donde nos toca muy de cerca la condición celiaca. Gran parte de nuestras
            familias y/o amigos, padecen esta condición, y entendemos lo complicado que es salir a comer afuera o bien
            pedir comida por delivery. Todos ellos son nuestra principal motivación para que Celisana salga adelante.
          </p>
          <p class="subtitle
                  is-5
                  has-text-centered
                  has-color-primary-dark
                  has-text-weight-bold"
          >
            Nuestro objetivo es que Celisana sea un medio de encuentro y comunidad, en los que TODOS puedan compartir
            sus experiencias.
          </p>
          <founder-member v-for="founder in founders"
                          :key="founder.name"
                          :member="founder"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FounderMember from '../components/About/FounderMember'
import { getMetaInfo } from '@/modules/meta'

export default {
  name: 'About',
  components: {
    FounderMember
  },
  metaInfo () {
    return getMetaInfo(
      'Quienes somos',
      'Conocé quienes colaboramos para crear la comunidad Celíaca mas grande de Argentina',
      'assets/images/hero-01.svg'
    )
  },
  data () {
    return {
      founders: [
        {
          pk: 3,
          name: 'Andres Haehnel | Founder & Developer',
          gender: 'M',
          image_hash: '5d92f5a26c8abafdca31a04c7312370b'
        },
        {
          pk: 7,
          name: 'Julian Vera | Founder & Designer',
          gender: 'M',
          image_hash: 'c2e6efce8c461db29db2759d0094de21'
        }
      ]
    }
  }
}
</script>

<style scoped lang="sass">
.--intestine
  background: url('../assets/images/about/wave-1.svg') top center, url('../assets/images/about/intestine.svg') 0 2rem, url('../assets/images/about/intestine-b.svg') bottom center, url('../assets/images/about/intestine-2.svg') center center
  background-repeat-y: no-repeat
  background-size: 100% auto, auto, auto
  min-height: 500px
  p
    max-width: 650px
.--intro
  background-image: url('../assets/images/about/conchunf.svg')
  background-repeat-y: no-repeat
  background-position: bottom center
  .section
    padding-bottom: 0
.--childadulthood
  padding-top: 7rem
  background-image: url('../assets/images/about/childadulthood-t.svg'), url('../assets/images/about/childadulthood-bg.svg')
  background-repeat-y: no-repeat
  background-position: 100% top, top center
  img
    transform: translateY(3rem)
.--argentina
  background-image: url('../assets/images/about/argentina-bg.svg')
  background-size: 100% 100%
  background-position: center center
  background-repeat: no-repeat
  .subtitle
    max-width: 680px
    padding: 4rem 0
.--inargentina
  background-color: #F7F5FF
  background-image: url('../assets/images/about/inargentina-b.svg')
  background-repeat-y: no-repeat
  background-repeat-x: repeat
  background-position: bottom center
  padding-bottom: 280px
  .column
    text-align: center
    .subtitle
      max-width: 185px
      margin: 2rem auto 0
.--double
  background-color: #f7f5ff
  p
    max-width: 500px
.--mision
  background-position: top center
  min-height: 600px
  background-repeat: no-repeat
  background-size: auto 100%
  background-image: url('../assets/images/about/celisun-2.svg')
.founders__container
  display: flex
  align-items: center
  flex-direction: column
</style>
