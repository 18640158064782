var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"--double container-fluid is-flex is-justify-content-center"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"section"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"box"},[_c('b-image',{attrs:{"src":require('../assets/images/hero1.png'),"alt":"hero"}})],1)]),_vm._m(6)]),_c('div',{staticClass:"columns"},[_vm._m(7),_c('div',{staticClass:"column"},[_c('div',{staticClass:"box"},[_c('b-image',{attrs:{"src":require('../assets/images/about/hero.png'),"alt":"about_hero"}})],1)])])])])]),_c('p',{staticClass:"subtitle is-2 has-text-centered has-text-weight-bold my-4"},[_vm._v(" Nuestra mision ")]),_vm._m(8),_c('div',{staticClass:"container"},[_c('div',{staticClass:"section"},[_c('p',{staticClass:"subtitle is-2 has-text-centered has-text-weight-bold"},[_vm._v(" Quienes somos ")]),_c('div',{staticClass:"founders__container"},[_c('p',{staticClass:"subtitle is-5 has-text-centered has-color-primary-dark has-text-weight-bold"},[_vm._v(" Somos profesionales en tecnología, donde nos toca muy de cerca la condición celiaca. Gran parte de nuestras familias y/o amigos, padecen esta condición, y entendemos lo complicado que es salir a comer afuera o bien pedir comida por delivery. Todos ellos son nuestra principal motivación para que Celisana salga adelante. ")]),_c('p',{staticClass:"subtitle is-5 has-text-centered has-color-primary-dark has-text-weight-bold"},[_vm._v(" Nuestro objetivo es que Celisana sea un medio de encuentro y comunidad, en los que TODOS puedan compartir sus experiencias. ")]),_vm._l((_vm.founders),function(founder){return _c('founder-member',{key:founder.name,attrs:{"member":founder}})})],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"--intro container-fluid is-flex is-justify-content-center"},[_c('div',{staticClass:"section"},[_c('h1',{staticClass:"title is-2 has-text-centered"},[_vm._v("Hablemos de Celiaquia")]),_c('img',{attrs:{"src":require("../assets/images/about/hero.png"),"alt":"hero"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid has-background-primary"},[_c('div',{staticClass:"section py-0"},[_c('p',{staticClass:"subtitle is-4 has-text-white has-text-weight-bold has-text-centered"},[_vm._v("y como se ha convertido en una condición"),_c('br'),_vm._v("cada vez más común entre la población.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"--intestine container-fluid has-background-primary-light is-flex is-justify-content-center is-align-items-center"},[_c('div',{staticClass:"section"},[_c('p',{staticClass:"subtitle is-4 has-text-centered has-color-primary-dark has-text-weight-bold"},[_vm._v(" La celiaquia es una intolerancia permanente al gluten, una proteina que se encuentra en el trigo, avena, cebada y centeno (TACC), que afecta el intestino delgado de las personas con predisposicion genetica. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"--childadulthood container-fluid is-flex is-justify-content-center is-flex-direction-column"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"section py-0"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('p',{staticClass:"subtitle is-4 has-text-centered has-color-primary-dark has-text-weight-bold"},[_vm._v("El gluten afecta la vellosidad del intestino de los celiacos y su capacidad de absorver nutrientes.")])]),_c('div',{staticClass:"column"},[_c('p',{staticClass:"subtitle is-4 has-text-centered has-color-primary-dark has-text-weight-bold"},[_vm._v("La intolerancia puede aparecer en cualquier momento de la vida, desde la niñes o la adultes tardia.")])])])])]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"section py-0"},[_c('img',{attrs:{"src":require("../assets/images/about/childadulthood.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"--argentina container-fluid"},[_c('div',{staticClass:"section is-flex is-align-items-center is-flex-direction-column"},[_c('p',{staticClass:"subtitle is-4 has-text-centered pb-4"},[_vm._v("Un estudio publicado en 2014 muestra que la poblacion celiaca aumento un 500% en los ultimos 25 años")]),_c('img',{attrs:{"src":require("../assets/images/about/argentina.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"--inargentina container-fluid"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"section pt-0"},[_c('h2',{staticClass:"subtitle is-2 has-text-centered py-5"},[_vm._v("en Argentina...")]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('img',{attrs:{"src":require("../assets/images/about/in-arg-1.png"),"alt":""}}),_c('p',{staticClass:"subtitle is-4 has-text-centered has-color-primary-dark has-text-weight-bold"},[_vm._v("1 de cada 169 adultos son celiacos.")])]),_c('div',{staticClass:"column"},[_c('img',{attrs:{"src":require("../assets/images/about/in-arg-2.png"),"alt":""}}),_c('p',{staticClass:"subtitle is-4 has-text-centered has-color-primary-dark has-text-weight-bold"},[_vm._v("En niños el numero sube a 1 de cada 79.")])]),_c('div',{staticClass:"column"},[_c('img',{attrs:{"src":require("../assets/images/about/in-arg-3.png"),"alt":""}}),_c('p',{staticClass:"subtitle is-4 has-text-centered has-color-primary-dark has-text-weight-bold"},[_vm._v("Las mujeren tienden a ser 3 veces propensas a la condicion.")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column"},[_c('p',{staticClass:"subtitle is-4 has-text-left has-color-primary-dark has-text-weight-bold"},[_vm._v("La dificultad en mantener una dieta sin gluten se asocia a multiples factores que incluyen la disponibilidad, el precio, la variedad de alternativas en establecimiento gastronomicos.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column"},[_c('p',{staticClass:"subtitle is-4 has-text-left has-color-primary-dark has-text-weight-bold"},[_vm._v("Los Celiacos sienten un impacto negativo muy significativo en la calidad de vida en los entornos sociales."),_c('br'),_vm._v("Las encuestas en Argentina revelaron que un 70% de celiacos piensa que comer fuera de casa es una de las principales preocupaciones, incluso años despues de haber sido diagnosticados.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"--mision container-fluid is-flex is-justify-content-center"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"section"},[_c('p',{staticClass:"subtitle is-4 has-text-centered has-color-primary-dark has-text-weight-bold"},[_vm._v("Integrar la poblacion celiaca en las diferentes areas de la vida social y facilitar su adherencia a una dieta saludable.")])])])])
}]

export { render, staticRenderFns }