export default {
  methods: {
    backListener: function (event) {
      this.$router.go(-1)
    }
  },
  mounted () {
    window.addEventListener('popstate', this.backListener, false)
  },
  beforeDestroy () {
    window.removeEventListener('popstate', this.backListener, false)
  }
}
