import axios from 'axios'
import { mapGetters } from 'vuex'
import provinces from '@/constants/provinces'
import { cloneDeep, isEmpty, debounce } from 'lodash'

export default {
  props: {
    defaultValue: {
      required: false
    },
    isHero: {
      required: false
    }
  },
  data () {
    return {
      truncateFilters: false,
      tempLocationId: undefined,
      locationName: '',
      search: {
        provinceId: undefined,
        locationId: undefined
      },
      provinces: provinces
    }
  },
  watch: {
    getToken: {
      handler (token) {
        if (token) {
          this.search.provinceId = this.getPreferredProvince
        }
      }
    },
    currentProvince: {
      async handler (newVal) {
        this.$store.commit('setPreferredProvince', newVal.toString())
        this.search.locationId = undefined
        this.locationName = ''
        // find local cache
        if (!this.$store.getters.getLocations.find(x => x.provinceId === this.search.provinceId)) {
          await axios.get('locations/', {
            params: {
              province: this.search.provinceId
            }
          })
            .then(({ data }) => {
              this.$store.commit('setLocations', {
                provinceId: newVal,
                data: data
              })
            })
        }
        if (this.tempLocationId) { // for reload page
          this.locationName = this.currentLocations.find(x => x.id === parseInt(this.tempLocationId)).name
          this.search.locationId = this.tempLocationId
          this.tempLocationId = undefined
        }
      }
    }
  },
  computed: {
    ...mapGetters(['getToken', 'getPreferredProvince', 'getCurrentSearch']),
    currentProvince: function () {
      return this.search.provinceId
    },
    filteredLocations () {
      return this.currentLocations.filter(option => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.locationName.toLowerCase()) >= 0
        )
      })
    },
    currentLocations: function () {
      const locations = this.$store.getters.getLocations.find(x => x.provinceId === this.search.provinceId)
      return locations ? locations.data : []
    }
  },
  methods: {
    submit: debounce(function () {
      const newSearch = {
        locationId: this.search.locationId,
        provinceId: this.search.provinceId
      }
      if (this.truncateFilters) newSearch.types = []
      this.$emit('submit')
      this.$store.dispatch('search', newSearch)
    }, 100),
    blurLocation: function () {
      setTimeout(() => {
        if (!this.search.locationId) {
          this.locationName = ''
        }
      }, 200)
    },
    onChangeLocation: function (option) {
      const location = option ? option.id : undefined
      if (this.search.locationId !== location) {
        this.search.locationId = location
        this.submit()
      }
    }
  },
  mounted () {
    if (isEmpty(this.defaultValue)) {
      this.search.provinceId = this.getPreferredProvince
      this.tempLocationId = this.$get(this.getCurrentSearch, 'locationId')
    } else {
      this.search = cloneDeep(this.defaultValue)
      if (!this.search.provinceId) { this.search.provinceId = this.getPreferredProvince }
      this.tempLocationId = this.search.locationId
    }
  }
}
