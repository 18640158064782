<template>
  <div class="container-fluid">
    <div class="container">
      <section class="section">
        <div class="profile" v-if="member">
          <div class="profile__header">
            <div class="profile__avatar">
              <span class="profile__avatarImg image is-128x128">
                <avatar :member="member"
                        size="128"
                        @mounted="e => avatarImage = e"
                />
              </span>
              <div class="profile__avatarGroup">
                <span class="profile__avatarName">{{ member.name }} </span>
              </div>
              <b-button v-if="isLoggedUser"
                        @click="$router.push({ name: 'my-account' })"
                        icon-left="cog"
                        class="is-text ml-3"
              >
                Editar perfil
              </b-button>
            </div>
            <div class="profile__joined">
              <b-field label="Se ha unido el " horizontal>
                  <b-input disabled :value="member.created | moment('DD MMMM YYYY')" rounded></b-input>
              </b-field>
            </div>
          </div>
          <div class="profile__bio ql-container ql-snow">
            <div class="profile__bio__body">
              <div class="profile__divider">
                <hr>
                <span class="profile__dividerLabel"><b>Bio</b></span>
                <hr>
              </div>
              <span @click.capture.prevent="clickExternalLink"
                    class="ql-editor"
                    v-html="member.bio"
              ></span>
            </div>
          </div>
          <div class="private-data" v-if="!member.private">
            <b-tabs v-model="activeTab"
                    :animated="false"
                    position="is-centered"
                    :expanded="true"
            >
              <template v-for="tab in tabs">
                <b-tab-item
                  :key="tab.id"
                  :icon="tab.icon"
                  :value="tab.id"
                  :label="tab.label"
                >
                  <keep-alive>
                    <component :is="tab.component"
                               :member="member"
                               v-if="activeTab === tab.id"
                    ></component>
                  </keep-alive>
                </b-tab-item>
              </template>
            </b-tabs>
          </div>
          <div class="private" v-else>
            Perfil privado
          </div>
        </div>
      </section>
      <b-loading :is-full-page="true" v-model="loading" :can-cancel="false"></b-loading>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import CommentsBox from '@/components/Profile/CommentsBox'
import StoreBox from '@/components/Profile/StoreBox'
import RecipeBox from '@/components/Profile/RecipeBox'
import Avatar from '@/components/Common/Avatar'
import { get } from 'lodash'
import { getMetaInfo } from '@/modules/meta'

export default {
  name: 'Profile',
  props: {
    id: {
      required: true
    }
  },
  metaInfo () {
    const member = this.member?.name || '--'
    const image = this.avatarImage || 'assets/images/profile-skeletons/i.jpg'

    return getMetaInfo(
      member,
      `${member} es usuario en Celisana, la comunidad gluten free mas grande de Argentina`,
      image
    )
  },
  components: {
    Avatar,
    CommentsBox,
    StoreBox,
    RecipeBox
  },
  data () {
    return {
      member: undefined,
      avatarImage: undefined,
      loading: true,
      activeTab: 'comments',
      tabs: [
        {
          id: 'comments',
          label: 'Comentarios',
          icon: 'comment',
          component: 'comments-box'
        },
        {
          id: 'stores',
          label: 'Colaboraciones',
          icon: 'store',
          component: 'store-box'
        },
        {
          id: 'reipes',
          label: 'Recetas',
          icon: 'food-takeout-box',
          component: 'recipe-box'
        }
      ]
    }
  },
  methods: {
    clickExternalLink: function (event) {
      if (event.target.href) {
        this.$buefy.dialog.confirm({
          title: 'Estás saliendo de Celisana',
          message: `Vas a ingresar al siguiente sitio: <b>${event.target.href}</b>. ¿Deseas continuar?`,
          cancelText: 'Cancelar',
          confirmText: 'Continuar',
          onConfirm: () => window.open(event.target.href, 'blank')
        })
      }
    }
  },
  computed: {
    isLoggedUser: function () {
      return get(this.$store.getters.getUser, 'user') === parseInt(this.id)
    }
  },
  created () {
    axios.get(`members/${this.id}/`)
      .then(({ data }) => {
        this.member = data
      })
      .catch(({ data }) => {
        this.$router.push({ name: 'not-found' })
      })
      .finally(() => {
        this.loading = false
      })
  }
}
</script>

<style scoped lang="sass">
@import '~quill/dist/quill.snow.css'
.section
  padding-top: 0
.profile
  display: flex
  flex-direction: column
  width: 100%
.profile__avatar
  display: flex
  align-items: center
  flex-direction: column
  width: 100%
  margin-bottom: 1rem
.profile__avatarImg
  transform: translateY(-40px)
.profile__avatarGroup
  display: flex
  flex-direction: column
.profile__avatarName
  font-size: 2rem
  text-align: center
.profile__header
  display: flex
  align-items: flex-start
  justify-content: space-between
  flex-direction: column
  width: 100%
.profile__joined
  width: 100%
:deep()
  .ql-container
    border: 0 !important
.profile__bio__body
  width: 100%
  max-width: 780px
  border: 1px solid
  font-size: 1rem
  border-radius: 10px
  padding: 2rem
  margin: 0 auto
  background-color: white
.field-label
  flex-basis: auto
  align-items: center
  display: flex
  flex: initial
.profile__divider
  display: flex
  align-items: center
  max-width: 500px
  width: 100%
  margin: 0 auto
  text-align: center
  margin-bottom: 1rem
  &Label
    padding: 0 2rem
  hr
    height: 1px
    background-color: black
    flex: 1
.private-data
  background-color: #FFF
  border: 1px solid black
  border-radius: 10px
  width: 100%
.profile__bio
  padding: 2rem 0
  display: flex
  margin: 2rem 0
  background-image: url(../assets/images/waveborder.svg)
  background-size: 100px 20px
  background-repeat-y: space
.private
  text-align: center
  font-size: 1.5rem
  font-weight: bold
@media (min-width: 768px)
  .profile__avatarGroup
    margin-left: 2rem
  .profile__avatar
    flex-direction: row
  .profile__joined
    width: 400px
@media (min-width: 1200px)
  .profile__header
    flex-direction: row
    align-items: center
</style>
