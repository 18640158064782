<template>
  <section class="Hero">
    <div class="Hero__container">
      <div class="container">
        <div class="Hero__searcher">
          <h1 class="title is-4">
            Encontrá alimento <br> <b>Libre de Gluten</b> donde estés
          </h1>
          <!-- <div class="title__container">
            <div class="logo"></div>
          </div> -->
          <search-form :is-hero="true"
          ></search-form>
        </div>
        <img class="Hero__image" :src="require('@/assets/images/hero-01.svg')" alt="hero_01">
      </div>
    </div>
    <!-- <h1 class="title is-3 --mob">
      Encontrá alimento<br><b>Libre de Gluten</b><br>donde estés
    </h1> -->
  </section>
</template>

<script>
import SearchForm from '@/components/Layout/SearchFormHero'

export default {
  name: 'Hero',
  components: {
    SearchForm
  },
  inject: ['isMobile'],
  created () {
    // Clean filters
    this.$store.commit('setLastResults', undefined)
    this.$store.dispatch('applyFilters', { types: [], search: '' })
  }
}
</script>

<style scoped lang="sass">
@import "src/assets/styles/variables"
.Hero
  padding-bottom: 200px
  .title
    // display: none
    z-index: 1
    font-weight: 800
    text-align: center
    line-height: 150%
    margin: 0 0 3rem
    b
      font-weight: 900
      text-shadow: 2px 2px 0 rgba(0,0,0,0.25)
      color: $secondary
    // &.--mob
    //   display: block
.Hero__image
  display: none
::v-deep .searchByLocation
  position: absolute
  bottom: -208px
  width: 100%
  gap: 1.5rem
.Hero__container
  display: flex
  gap: 2rem
  min-height: 560px
  margin-bottom: 2rem
  padding: 1rem 0.75rem
  border-radius: 0 0 2.25rem 2.25rem
  background-image: url('../../assets/images/hero-01.svg'), url('../../assets/images/hero__bg.svg')
  background-position: center bottom
  background-repeat: no-repeat, repeat-x
  background-size: 400px, 830px
  background-color: $primary
  .container
    display: flex
    .Hero__searcher
      display: flex
      flex-direction: column
      justify-content: space-between
      width: 100%
      padding-top: 67px
      h1
        color: $white
.searchForm
  width: 100%
  // max-width: 600px
  display: flex
  flex-direction: column
  gap: 1rem
.title__container
  display: flex
  .logo
    width: 150px
    margin: 0 auto
    position: absolute
    top: 90px
    right: 0
    left: 0
    opacity: 0.7
@media (min-width: 960px)
  ::v-deep
    .searchByLocation
      position: initial
      bottom: initial
      width: 100%
  .Hero
    gap: 1rem
    padding-bottom: 1rem
    .Hero__container
      padding: 0
      gap: 0
      justify-content: center
      align-items: center
      background-image: url('../../assets/images/hero__bg.svg')
      background-position: right bottom
      background-repeat: repeat-x
      background-size: 850px
      height: 510px
      .container
        flex-direction: row
        justify-content: space-between
        height: 100%
        width: 100%
        .Hero__searcher
          justify-content: center
          padding-top: 0
    .Hero__searcher
      display: flex
      flex-direction: column
      justify-content: center
    .title
      display: block
      text-align: left
      color: #FFF
      font-size: 2.25rem
      &.--mob
        display: none
    .Hero__image
      display: flex
      padding-top: 4rem
      ::v-deep img
        overflow: visible
    .title__container
      justify-content: center
      flex-direction: row
</style>
