<template>
  <div class="actions">
    <div class="results-mobile" v-if="isMobile">
      <slot name="mobile"></slot>
      <b-dropdown v-model="search.ordering"
      >
        <template #trigger>
          <div class="order-mobile">
            <b-icon icon="filter-variant"></b-icon>
          </div>
        </template>
        <b-dropdown-item v-for="item in ordering"
                         :value="item.id" :key="item.id"
        >
          <div class="media">
            <b-icon :icon="item.icon" class="media-left"></b-icon>
            <div class="media-content">
              <h3>{{ item.label }}</h3>
            </div>
          </div>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <template>
      <slot name="desktop"></slot>
      <div class="search">
        <input-text name="search"
                    icon="magnify"
                    type="search"
                    placeholder="Buscar en Resultados"
                    v-model="searchText"
                    :expanded="true"
        ></input-text>
      </div>
      <div class="order" v-if="!isMobile">
        <input-select name="sort"
                      icon="format-list-bulleted"
                      v-model="search.ordering"
                      :options="ordering"
                      :disabled="isLoading"
        ></input-select>
      </div>
    </template>

  </div>
</template>

<script>
import InputSelect from '../Common/InputSelect'
import InputText from '../Common/InputText'
import { debounce } from 'lodash'

export default {
  name: 'SortActions',
  props: {
    isLoading: {
      required: true
    }
  },
  components: {
    InputSelect,
    InputText
  },
  watch: {
    search: {
      deep: true,
      handler () {
        this.$store.dispatch('applyFilters', this.search)
      }
    },
    searchText: {
      handler () {
        this.updateSearchText()
      }
    }
  },
  inject: ['isMobile'],
  data () {
    return {
      searchText: this.$route.query.search,
      search: {
        ordering: this.$route.query.ordering || '-avg_score,id',
        search: this.$route.query.search || ''
      },
      ordering: [
        {
          label: 'Mejor puntuados',
          id: '-avg_score,id',
          icon: 'star-outline'
        },
        {
          label: 'Más comentados',
          id: '-comments_count,id',
          icon: 'comment-text-multiple-outline'
        },
        {
          label: 'Recientes',
          id: '-created',
          icon: 'new-box'
        },
        {
          label: 'Alfabéticamente',
          id: 'name',
          icon: 'alphabetical'
        },
        {
          label: 'Partido',
          id: 'location',
          icon: 'map-marker-circle'
        }
      ]
    }
  },
  methods: {
    updateSearchText: debounce(function () {
      this.search.search = this.searchText
    }, 750)
  }
}
</script>

<style scoped lang="sass">
@import "src/assets/styles/variables"

.actions
  display: flex
  flex-direction: column-reverse
  width: 100%
  justify-content: space-between
  margin-bottom: 0.5rem
  .results-mobile
    display: flex
    justify-content: space-between
    font-size: 14px
    height: 3rem
    background-color: lighten($primary, 31%)
    align-items: center
    padding: 0 .75rem
:deep()
  .media
    align-items: center
  .select:not(.is-multiple)
    height: 2.5rem
  .select select, .input
    font-weight: bold
    height: 2.5rem
    padding: .25rem .25rem .25rem .75rem
    border-radius: $border-radius
  .control.has-icons-left .icon, .control.has-icons-right .icon
    height: 2.5rem
  .control
    .select, select
      width: 100%
  .search
    input
      border-color: $primary !important
@media (min-width: 768px)
  .actions
    flex-direction: row
    .search, .order
      width: calc(50% - .5rem)
</style>
