<template>
  <section class="section">
    <b-notification
      type="is-secondary is-light"
      aria-close-label="Close notification"
    >
      Si tenés inconvenientes para {{ isModify ? 'modificar un' : 'crear un nuevo' }} comercio, podes escribirnos a
      <b class="color-primary"><a href="mailto:celisana.ar@gmail.com">celisana.ar@gmail.com</a></b> y nosotros lo daremos de alta a la brevedad.<br>
      <span v-if="!isModify">No te olvides de decirnos el <b>nombre, provincia y barrio</b> del comercio.</span>
    </b-notification>
    <ValidationObserver ref="validator" v-slot="{ invalid }">
      <form @submit.prevent="save">
        <input-text name="store_name"
                    label="Nombre"
                    placeholder="Nombre del comercio"
                    validate="required|min:3"
                    icon="store"
                    field-type="is-success"
                    v-model="store.name"
                    @input="checkEmptyName"
        ></input-text>
        <div class="block" v-if="isModify">
          <b-button
            label="Cambiar dirección"
            type="is-primary"
            @click="showAddress = !showAddress"
            aria-controls="showAddress"
            :icon-right="showAddress ? 'chevron-up': 'chevron-down'"
            outlined
          />
        </div>
        <b-collapse
          aria-id="showAddress"
          animation="slide"
          v-model="showAddress"
        >
          <input-text name="address"
                      label="Dirección"
                      placeholder="Calle y número del comercio"
                      validate="required"
                      field-type="is-success"
                      icon="map"
                      v-model="store.address"
          ></input-text>
          <input-select name="province"
                        label="Provincia"
                        icon="earth"
                        field-type="is-success"
                        v-model="provinceId"
                        :options="provinces"
                        expanded
          >
          </input-select>
          <ValidationProvider v-slot="{ errors }" name="Partido" rules="required" vid="location_id">
            <b-field label="Partido" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
              <b-autocomplete
                :loading="!currentLocations.length"
                v-model="locationName"
                :data="filteredLocations"
                field="name"
                placeholder="Partido"
                :open-on-focus="true"
                icon="map-search"
                clearable
                @select="onChangeLocation"
                @blur="blurLocation"
              >
              </b-autocomplete>
            </b-field>
          </ValidationProvider>
          <input-coords v-model="store.coords"
                        v-if="showAddress"
                        :is-modify="isModify"
                        :store="store"
                        :location-name="locationName"
                        :province="currentProvince"
                        field-type="is-success"
          ></input-coords>
        </b-collapse>
        <input-text v-model="store.telephone"
                    name="telephone_store"
                    icon="phone"
                    :validate="{ regex: /^[0-9+\- ]+$/ }"
                    maxlength="30"
                    label="Teléfono de contacto"
                    field-type="is-success"
                    placeholder="Teléfono de contacto"
                    :expanded="true"
        >
        </input-text>
        <input-text v-model="store.email"
                    name="email_store"
                    icon="at"
                    label="Email"
                    validate="email"
                    field-type="is-success"
                    placeholder="Email del lugar"
                    :expanded="true"
        >
        </input-text>
        <input-text v-model="store.site_url"
                    name="site_url"
                    icon="link"
                    label="Sitio Web"
                    validate="url"
                    field-type="is-success"
                    placeholder="Sitio web del lugar"
                    :expanded="true"
        >
        </input-text>
        <input-text v-model="store.instagram"
                    name="instagram"
                    icon="instagram"
                    label="Instagram"
                    validate="url"
                    field-type="is-success"
                    placeholder="Instagram"
                    :expanded="true"
        >
        </input-text>
        <input-text v-model="store.facebook"
                    name="facebook"
                    icon="facebook"
                    label="Facebook"
                    validate="url"
                    field-type="is-success"
                    placeholder="Facebook"
                    :expanded="true"
        >
        </input-text>
        <store-types v-model="store.types_ids"></store-types>
        <input-image v-if="!isModify"
                     @add="addImage"
        ></input-image>
        <div class="is-flex is-justify-content-end">
          <b-button
            class="my-3"
            icon-left="store-plus"
            :loading="isLoading"
            :disabled="invalid || isLoading"
            label="Guardar cambios"
            type="is-primary"
            native-type="submit"
            :expanded="isMobile"
          />
        </div>
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import { getActualCoords } from '@/modules/coords'
import InputText from '@/components/Common/InputText'
import InputSelect from '@/components/Common/InputSelect'
import StoreTypes from '@/components/NewStore/StoreTypes'
import InputCoords from '@/components/NewStore/InputCoords'
import InputImage from '@/components/Common/InputImage'
import NeedToken from '@/components/Mixins/NeedToken'
import provinces from '@/constants/provinces'
import ModalCheckDuplicate from './ModalCheckDuplicate'

export default {
  name: 'StoreForm',
  props: {
    isLoading: {
      required: true
    },
    initialStore: {
      required: false,
      default () {
        return {
          location_id: '',
          coords: '',
          types_ids: [],
          instagram: '',
          facebook: '',
          telephone: '',
          site_url: '',
          email: '',
          image_url: ''
        }
      }
    },
    initialLocationName: {
      required: false,
      default: ''
    },
    initialProvinceId: {
      required: false,
      type: String,
      default: '1'
    },
    isModify: {
      required: false
    }
  },
  components: {
    InputText,
    InputSelect,
    StoreTypes,
    InputCoords,
    InputImage
  },
  inject: ['isMobile'],
  mixins: [
    NeedToken
  ],
  watch: {
    needSearchDuplicate: {
      handler (val) {
        if (!val) return
        const search = this.store.name.trim()
        axios.get(
          `provinces/${this.provinceId}/stores/all/?ordering=-avg_score%2Cid&provinceId=${this.provinceId}&location=${this.store.location_id}&search=${search}&page=1&size=15`
        )
          .then(({ data }) => {
            if (data.results.length) {
              this.$buefy.modal.open({
                parent: this,
                component: ModalCheckDuplicate,
                props: { stores: data.results },
                hasModalCard: true,
                canCancel: false
              })
            }
          })
      }
    },
    provinceId: {
      async handler (newVal) {
        // find local cache
        this.store.location_id = undefined
        this.locationName = ''
        if (!this.$store.getters.getLocations.find(x => x.provinceId === this.provinceId)) {
          await axios.get('locations/', {
            params: {
              province: this.provinceId
            }
          })
            .then(({ data }) => {
              this.$store.commit('setLocations', {
                provinceId: newVal,
                data: data
              })
            })
        }
      }
    }
  },
  data () {
    return {
      provinces: provinces.map(x => { return { id: x.id, label: x.name } }),
      locationName: this.initialLocationName,
      provinceId: this.initialProvinceId,
      store: this.initialStore,
      image: undefined,
      showAddress: !this.isModify
    }
  },
  computed: {
    ...mapGetters(['getPreferredProvince']),
    currentLocations: function () {
      const locations = this.$store.getters.getLocations.find(x => x.provinceId === this.provinceId)
      return locations ? locations.data : []
    },
    filteredLocations: function () {
      return this.currentLocations.filter(option => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.locationName.toLowerCase()) >= 0
        )
      })
    },
    currentProvince: function () {
      return this.provinces.find(x => x.id === this.provinceId).label
    },
    needSearchDuplicate: function () {
      return this.isModify ? false : ['location_id', 'name'].every(x => this.store[x])
    }
  },
  methods: {
    onChangeLocation: function (option) {
      this.store.location_id = option ? option.id : undefined
      if (!this.store.location_id) {
        this.locationName = ''
      }
    },
    blurLocation: function () {
      setTimeout(() => {
        if (!this.store.location_id) {
          this.locationName = ''
        }
      }, 200)
    },
    showError: function (message, duration = 3000) {
      this.$buefy.snackbar.open({
        duration: duration,
        message: message,
        type: 'is-danger',
        position: 'is-bottom-right'
      })
    },
    save: function () {
      this.$refs.validator.validate()
        .then(result => {
          if (result) {
            this.$emit('submit')
          }
        })
    },
    addImage: function (payload) {
      this.image = undefined
      this.store.image_url = undefined
      if (payload.var === 'image') {
        this.image = payload.value
      } else {
        this.store.image_url = payload.value
      }
    },
    checkEmptyName: function (val) {
      if (!val) {
        this.store.location_id = undefined
        this.locationName = ''
      }
    }
  },
  mounted () {
    this.$refs.validator.validate()
    getActualCoords()
    this.store.provinceId = this.getPreferredProvince
  }
}
</script>

<style scoped>

</style>
