<template>
  <div class="categories">
    <div v-for="category in recipeCategories"
         :key="category.id"
         class="is-clickable"
         :class="{ '--selected': value.includes(category.id.toString()) }"
         @click="click(category.id.toString())"
    >
      <b-field >
        <b-tag :style="getStyle(category)">
          {{ category.name }}
        </b-tag>
      </b-field>
    </div>
  </div>
</template>

<script>
import recipeCategories from '@/constants/recipeCategories'

export default {
  props: {
    value: {
      required: true,
      type: Array
    }
  },
  data () {
    return {
      recipeCategories
    }
  },
  methods: {
    click: function (id) {
      let payload = [...this.value]
      if (payload.includes(id)) {
        payload = payload.filter(x => x !== id)
      } else {
        payload.push(id)
      }
      this.$emit('input', payload)
    },
    getStyle: function (item) {
      if (this.value.includes(item.id.toString())) {
        return { background: item.color, color: 'white' }
      }
      return {
        'border-color': item.color
      }
    }
  }
}
</script>

<style scoped lang="sass">
.categories
  padding: 1rem 0 1rem 1rem
  display: flex
  gap: .5rem
  overflow: hidden
  overflow-x: auto
  .tag
    padding: 1rem
    border-radius: 1.5rem
    border: 2px solid
    background: white
    font-weight: 900
    font-size: 14px
</style>
