<template>
  <article class="comment card">
    <figure class="comment__username">
      <p class="image is-32x32 mr-3">
        <avatar :member="comment.author"
                size="32"
                :is-clickable="true"
        ></avatar>
      </p>
      <a @click="goProfile">{{ comment.author.name }}</a>
    </figure>
    <div class="comment__body">
      <p>{{ comment.comment }}</p>
    </div>
    <div class="comment__footer">
      <small class="comment__footer__from">{{ comment.created | moment("from") }}</small>
      <div class="comment__footer__score">
        <b-icon
          icon="star"
          type="is-warning">
        </b-icon>
        <span class="ml-1">{{ parseFloat(comment.score) }}</span>
      </div>
    </div>
  </article>
</template>

<script>
import Avatar from '@/components/Common/Avatar'

export default {
  name: 'CommentItem',
  components: {
    Avatar
  },
  props: {
    comment: {
      required: true
    }
  },
  methods: {
    goProfile: function () {
      this.$router.push({ name: 'profile', params: { id: this.comment.author.pk } })
    }
  }
}
</script>

<style scoped lang="sass">
@import "src/assets/styles/variables"

.comment
  background: white
  border-radius: $border-radius
  padding: 1rem
  margin-bottom: 0.5rem
  &__username
    display: flex
    color: #7555F6
    align-items: center
    font-weight: bolder
  &__body
    display: flex
    align-items: center
    margin: 1rem 0
  &__footer
    text-transform: capitalize
    display: flex
    justify-content: space-between
    align-items: center
    &__from
      font-weight: bolder
    &__score
      display: flex
      justify-content: space-between
      font-weight: bolder
      color: #808080
</style>
