import { render, staticRenderFns } from "./CommentStore.vue?vue&type=template&id=af95b64a&scoped=true&"
import script from "./CommentStore.vue?vue&type=script&lang=js&"
export * from "./CommentStore.vue?vue&type=script&lang=js&"
import style0 from "./CommentStore.vue?vue&type=style&index=0&id=af95b64a&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af95b64a",
  null
  
)

export default component.exports