const provinces = [
  { id: '1', name: 'Capital Federal' },
  { id: '2', name: 'Buenos Aires' },
  { id: '21', name: 'Catamarca' },
  { id: '15', name: 'Chaco' },
  { id: '10', name: 'Chubut' },
  { id: '14', name: 'Corrientes' },
  { id: '4', name: 'Córdoba' },
  { id: '6', name: 'Entre Ríos' },
  { id: '24', name: 'Formosa' },
  { id: '22', name: 'Jujuy' },
  { id: '12', name: 'La Pampa' },
  { id: '20', name: 'La Rioja' },
  { id: '9', name: 'Mendoza' },
  { id: '19', name: 'Misiones' },
  { id: '13', name: 'Neuquén' },
  { id: '17', name: 'Río Negro' },
  { id: '18', name: 'Salta' },
  { id: '3', name: 'San Juan' },
  { id: '16', name: 'San Luis' },
  { id: '7', name: 'Santa Cruz' },
  { id: '11', name: 'Santa Fe' },
  { id: '23', name: 'Santiago del Estero' },
  { id: '5', name: 'Tierra del Fuego' },
  { id: '8', name: 'Tucumán' }
]

export default provinces
