<template>
  <div class="container">
    <div class="HomeView__feats">
      <div class="HomeView__featsGroup --hero1" @click="$router.push({ name: 'stores-nearly'})">
        <img class="" :src="require('../../assets/images/hero3.svg')" alt="hero_places">
        <span class="HomeView__featsText">Estás bucando comercios cerca de tu ubicación?</span>
        <button class="button is-rounded">Sí, Buscar cerca de mí</button>
      </div>
      <div class="HomeView__featsGroup --hero2" @click="$router.push({ name: 'add'})">
        <img class="" :src="require('../../assets/images/hero1.svg')" alt="hero_1">
        <span class="HomeView__featsText">Queres ayudar a otros compartiendo lugares o recetas</span>
        <button class="button is-rounded">Quiero compartir algo nuevo</button>
      </div>
      <div class="HomeView__featsGroup --hero3" @click="$router.push({ name: 'news'})">
        <img class="" :src="require('../../assets/images/hero2.svg')" alt="hero_news">
        <span class="HomeView__featsText">Informate acerca de las ultimas novedades</span>
        <button class="button is-rounded">Quiero ver las noticias</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuickActions'
}
</script>

<style lang='sass' scoped>
@import "src/assets/styles/variables"
.HomeView__feats
  display: flex
  justify-content: space-between
  flex-direction: column
  padding: 2.5rem 1rem
  box-sizing: content-box
  margin: 1rem 0
.HomeView__featsGroup
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: center
  border-radius: 15px
  padding: 1rem
  margin-bottom: 2rem
  transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out, background-color
  cursor: pointer
  img
    max-height: 280px
  &.--hero1
    background-color: $primary
    box-shadow: inset 0 0 0 6px #503fb2
    .HomeView__featsText
      color: #FFF
      text-align: center
    .button
      background-color: $secondary
      color: darken($primary, 30%)
  &.--hero2
    background-color: #9cdddc
  &.--hero3
    background-color: #ffbaad
  &:hover
    box-shadow: none
.HomeView__featsText
  font-size: 1.25rem
  font-weight: 800
  text-align: center
  padding: 1rem 0
  .--bold
    color: #ecd446
@media (min-width: 768px)
  .HomeView__feats
    gap: 2rem
    flex-direction: initial
    height: 100%
    margin: 1rem 0
  .HomeView__featsText
    // width: 230px
    padding: 1rem .5rem
  .HomeView__featsGroup
    width: 100%
</style>
