<template>
  <form @submit.prevent class="surface">
    <div class="modal-card" style="width: auto">
      <div class="title__question primary">
        Revisá si este comercio ya existe en nuestra base de datos
      </div>
      <div class="container__stores">
        <store-item v-for="(store, key) in stores"
                    :key="key"
                    :store="store"
        ></store-item>
      </div>
      <div class="is-flex is-flex-direction-column pt-3">
        <b-button
          label="Ya existe este comercio"
          icon-left="arrow-left"
          type="is-danger is-light"
          class="mb-1"
          @click="goHome"
        />
        <b-button
          label="Continuar con la carga"
          icon-left="arrow-right"
          type="is-primary"
          class="mb-2"
          @click="close" />
      </div>
    </div>
  </form>
</template>

<script>
import StoreItem from '@/components/StoreResults/StoreItem'

export default {
  name: 'ModalCheckDuplicate',
  components: {
    StoreItem
  },
  props: {
    stores: {
      required: true
    }
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    goHome: function () {
      this.close()
      this.$router.push({ name: 'add' })
      this.$buefy.snackbar.open({
        duration: 10 * 1000,
        message: 'No te desanimes, quedan muchos lugares por agregar. Gracias por colaborar con Celisana!'
      })
    }
  }
}
</script>

<style scoped lang="sass">
.title__question
  background-color: #f0ecff
  margin-bottom: 1rem
  border-radius: .5rem
  padding: 1rem
  text-align: center
  max-width: 565px
  font-size: 1.5rem
  font-weight: bold
.container__stores
  overflow-y: auto
  overflow-x: hidden
  max-height: 350px
  padding-right: 0.5rem
:deep()
  .StoreItem
    border-color: #dbdbdb
    box-shadow: none
    margin-bottom: 0.5rem
    &:hover
      background-color: initial
</style>
