<template>
  <ValidationProvider v-slot="{ errors }" :name="label" :rules="validate" :vid="name">
    <div class="rich-area">
      <b-field :label="label"
               :type="errors[0] ? 'is-danger' : ''"
               :message="errors[0]"
      >
        <vue-editor v-model="htmlContent"
                    id="vue-editor"
                    :editorToolbar="customToolbar"
                    :disabled="disabled"
                    :customImageHandler="imageHandler"
                    ref="editor"
                    :placeholder="placeholder"
                    @input="input">
        </vue-editor>
      </b-field>
    </div>
  </ValidationProvider>
</template>

<script>
import { VueEditor } from 'vue2-editor'

export default {
  name: 'RichArea',
  props: {
    name: { required: true },
    label: { required: false },
    validate: { required: false },
    placeholder: { required: false },
    value: { required: false, default: '' },
    showToolbar: { required: false, default: true },
    extraToolbarItems: { required: false, default: () => [], type: Array },
    disabled: { required: false }
  },
  components: {
    VueEditor
  },
  data () {
    return {
      htmlContent: '',
      customToolbar: [
        [{ font: [] }],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ['link'],
        ['image'],
        ['video'],
        ['clean'] // remove formatting button
      ]
    }
  },
  methods: {
    input: function (payload) {
      this.$emit('input', payload)
    },
    imageHandler: function () {
      const quill = this.$refs.editor.quill
      const range = quill.getSelection()
      const value = prompt('Inserta la url de la imagen')
      if (value) {
        quill.insertEmbed(range.index, 'image', value)
      }
    },
    videoHandler: function () {
      const quill = this.$refs.editor.quill
      let url = prompt('Ingresa URL de video (solo youtube): ')
      url = this.getVideoUrl(url)
      const range = quill.getSelection()
      if (url != null) {
        quill.insertEmbed(range.index, 'video', url)
      }
    },
    getVideoUrl: function (url) {
      const match = url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/) ||
        url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/) ||
        url.match(/^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#&?]*).*/)
      if (match && match[2].length === 11) {
        return ('https') + '://www.youtube.com/embed/' + match[2] + '?showinfo=0'
      }
      return null
    }
  },
  created () {
    this.htmlContent = this.value
    if (!this.showToolbar) {
      this.customToolbar = [
        [{ header: [false] }]
      ]
    }
    if (this.extraToolbarItems.length) {
      this.customToolbar = this.customToolbar.concat(this.extraToolbarItems)
    }
  },
  mounted () {
    const quill = this.$refs.editor.quill
    const toolbar = quill.getModule('toolbar')
    toolbar.addHandler('image', this.imageHandler)
    toolbar.addHandler('video', this.videoHandler)
  }
}
</script>

<style scoped lang="sass">
@import '~quill/dist/quill.core.css'
.rich-area
 margin-bottom: 1rem
</style>
