import { render, staticRenderFns } from "./QuickActions.vue?vue&type=template&id=6674cd40&scoped=true&"
import script from "./QuickActions.vue?vue&type=script&lang=js&"
export * from "./QuickActions.vue?vue&type=script&lang=js&"
import style0 from "./QuickActions.vue?vue&type=style&index=0&id=6674cd40&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6674cd40",
  null
  
)

export default component.exports