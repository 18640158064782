var render = function render(){var _vm=this,_c=_vm._self._c;return _c('white-navbar',[_c('div',{staticClass:"mobile-header"},[_c('input-text',{staticClass:"searcher mr-1",attrs:{"name":"searcher","placeholder":"Buscar","icon":"magnify","icon-right":_vm.params.search ? 'close' : '',"value":_vm.params.search},on:{"input":e => _vm.$emit('updateSearch', e),"icon-right-click":function($event){return _vm.$emit('cleanSearch')}}}),_c('div',{staticClass:"btn",class:{ '--has-filters-active': _vm.hasFiltersActive},on:{"click":function($event){_vm.showFilters = true}}},[_c('b-icon',{attrs:{"icon":"tune"}}),_c('div',{staticClass:"marker-filters"})],1)],1),_c('transition',{attrs:{"name":"zoom-out"}},[_c('modal-filter',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}],attrs:{"title":"Filtros"},on:{"close":function($event){_vm.showFilters = false}}},[_c('section',{staticClass:"p-3"},[_c('h3',{staticClass:"subtitle"},[_vm._v(" Dificultad ")]),_c('input-select',{staticClass:"filter",attrs:{"name":"difficulty","icon":"tune-variant","options":[
                          { id: '', label: 'Todas' },
                          { id: 1, label: 'Simple' },
                          { id: 2, label: 'Moderada' },
                          { id: 3, label: 'Compleja' }
                        ],"value":_vm.params.difficulty},on:{"input":e => _vm.$emit('changeSelect', {
                    val: e,
                    key: 'difficulty'
                  })}})],1),_c('section',{staticClass:"p-3"},[_c('h3',{staticClass:"subtitle"},[_vm._v(" Categorias ")]),_c('filter-categories',{on:{"input":function($event){return _vm.$emit('changeFirstPage')}},model:{value:(_vm.params.categories),callback:function ($$v) {_vm.$set(_vm.params, "categories", $$v)},expression:"params.categories"}}),_c('div',{staticClass:"clean"},[(_vm.hasFiltersActive)?_c('b-button',{attrs:{"expanded":"","type":"is-primary is-light"},on:{"click":function($event){return _vm.$emit('cleanFilters')}}},[_vm._v("Limpiar filtros")]):_vm._e()],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }