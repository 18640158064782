<template>
  <div>
    <div class="mt-5 mb-2">
      <b-radio v-model="coordsRadio"
               name="address"
               native-value="address">
        Buscar por calle
      </b-radio>
      <b-radio v-model="coordsRadio"
               name="map"
               class="ml-2"
               native-value="map">
        Buscar en mapa
      </b-radio>
      <b-radio v-if="!isModify"
               v-model="coordsRadio"
               name="place"
               class="ml-2"
               :disabled="!getUserCoords"
               @input="setUserCoords"
               native-value="place">
        Estoy en el lugar
      </b-radio>
    </div>
    <b-message type="is-info">
      <span v-html="helpText[coordsRadio]"></span>
    </b-message>
    <div v-if="coordsRadio === 'address'"
         class="my-5 mx-3"
    >
      <input-text v-model="city"
                  name="city"
                  label="Localidad/Barrio"
                  :message="searchCoordsLegend"
                  placeholder=""
      ></input-text>
      <b-button :disabled="loadingCity || !store.address"
                :loading="loadingCity"
                icon-left="magnify"
                @click="searchCoords"
      >
        Buscar dirección
      </b-button>
    </div>
    <map-component ref="map"
                   :center="coords ? currentCoords : defaultCoords"
                   :coordsList="[{ coords: currentCoords, iconOverride: 'user' }]"
                   :key="keyMap"
                   :zoom="!coords ? 5 : 12"
                   :show-user="false"
                   :mark-on-click="coordsRadio === 'map'"
                   :keepCurrentZoom="true"
                   @click-map="e => coords = e"
    ></map-component>
    <input-text v-model="coords"
                :disabled="coordsRadio !== 'map'"
                name="coords"
                icon="crosshairs"
                label="Coordenadas"
                validate="required"
                @blur="renderCount++"
    ></input-text>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import MapComponent from '@/components/MapComponent'
import InputText from '@/components/Common/InputText'

export default {
  name: 'InputCoords',
  props: {
    store: {
      required: true
    },
    locationName: {
      required: true
    },
    province: {
      required: true
    },
    value: {
      required: false
    },
    isModify: {
      required: false
    }
  },
  components: {
    MapComponent,
    InputText
  },
  watch: {
    coords: {
      handler (value) {
        this.$emit('input', value)
      }
    }
  },
  data () {
    return {
      coordsRadio: 'address',
      renderCount: 0,
      coords: this.value,
      defaultCoords: ['-35.97631585768143', '-63.53520355263952'],
      city: '',
      loadingCity: false,
      helpText: {
        address: `Una vez ingresada la dirección, provincia y partido del comercio, podrás buscar las coordenadas del lugar.<br>
          Si no obtienes el dirección correcta, podes especificar la localidad o barrio para lograr una busqueda mas exacta.<br>
          En caso de no encontrar la dirección del comercio, intentá con la opcion "Buscar en mapa".`,
        place: 'Si tienes activado la geo-localización en tu dispositivo, se tomará las coordenadas del sitio en el que estés actualmente.',
        map: 'Busca la dirección del comercio en el mapa. Puedes agregar el marcador al mapa con un simple click.<br>' +
          'Tambien puedes ingresar las coordenadas siguiendo estos pasos:' +
          `<li>Entra a <a href="https://google.com.ar/maps/" target="_blank"><b>google maps</b></a> y busca el comercio.</li>
             <li>Hace click con el botón derecho en el sitio o en el área del mapa.</li>
             <li>Selecciona la latitud y la longitud para que se copien automáticamente las coordenadas.</li>
             <li>Una vez cargada las coordenadas checkea en el mapa que la dirección sea la correcta.</li>`
      }
    }
  },
  computed: {
    ...mapGetters(['getUserCoords']),
    currentCoords: function () {
      return this.coords ? this.coords.split(',') : ['', '']
    },
    keyMap: function () {
      return `${this.coordsRadio}_${this.renderCount}`
    },
    searchCoordsLegend: function () {
      const address = this.store.address || 'Sin dirección'
      const location = this.store.location_id ? this.locationName : 'Sin partido'
      return `Búsqueda para: ${address}, ${this.city || location}, ${this.province}`
    }
  },
  methods: {
    setUserCoords: function () {
      this.coords = this.getUserCoords.join(',')
    },
    searchCoords: function () {
      this.loadingCity = true
      const city = this.city || this.locationName
      const params = new URLSearchParams()
      params.append('street', this.store.address)
      params.append('city', this.normalizeString(city))
      params.append('state', this.province)
      params.append('country', 'ar')
      params.append('format', 'jsonv2')
      axios.get('https://nominatim.openstreetmap.org/search.php', {
        params: params
      })
        .then(({ data }) => {
          if (data && data.length) {
            this.coords = `${data[0].lat},${data[0].lon}`
            this.renderCount += 1
          } else {
            this.showErrorCoords()
          }
        })
        .catch(() => { this.showErrorCoords() })
        .finally(() => {
          this.loadingCity = false
        })
    },
    showErrorCoords: function () {
      this.showError('No se encontró ninguna calle para tu búsqueda. Asegurate de cargar correctamente dirección, provincia y localidad', 6000)
    },
    showError: function (message, duration = 3000) {
      this.$buefy.snackbar.open({
        duration: duration,
        message: message,
        type: 'is-danger',
        position: 'is-bottom-right'
      })
    },
    normalizeString: function (string) {
      return string.normalize('NFD')
        .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, '$1$2')
        .normalize()
    }
  }
}
</script>

<style scoped lang="sass">
@import "src/assets/styles/variables"

.mapComponent
  width: 100%
  height: 300px
  border-radius: $input-radius
</style>
