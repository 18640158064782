<template>
  <ValidationObserver ref="validator" v-slot="{ invalid }">
    <form @submit.prevent="changePassword" id="modal-change-password">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Cambio de contraseña</p>
        </header>
        <section class="modal-card-body">
          <input-text label="Contraseña actual"
                      name="current"
                      icon="lock-question"
                      type="password"
                      validate="required|min:8"
                      placeholder="Su contraseña actual"
                      v-model="payload.current"
          ></input-text>
          <password-confirm :is-update="true"
                            v-model="payload.new"
          ></password-confirm>
        </section>
        <footer class="modal-card-foot">
          <b-button
            label="Cerrar"
            @click="$emit('close')"/>
          <b-button
            :disabled="invalid || isLoading"
            label="Cambiar"
            type="is-primary"
            native-type="submit"
            :loading="isLoading"
          />
        </footer>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import axios from 'axios'
import inputText from '@/components/Common/InputText'
import passwordConfirm from './PasswordConfirm'

export default {
  name: 'ChangePasswordModal',
  components: {
    inputText,
    passwordConfirm
  },
  data () {
    return {
      payload: {},
      isLoading: false
    }
  },
  methods: {
    changePassword: function () {
      this.isLoading = true
      axios.post('profile/change-password/', this.payload)
        .then(() => {
          this.$buefy.snackbar.open({
            duration: 3000,
            message: 'Su contraseña se actualizó correctamente',
            type: 'is-success',
            position: 'is-bottom-right'
          })
          this.$emit('close')
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            this.$refs.validator.setErrors(response.data.errors)
          } else {
            this.$buefy.snackbar.open({
              duration: 3000,
              message: response.data.message,
              type: 'is-danger',
              position: 'is-bottom-right'
            })
          }
        })
        .finally(() => { this.isLoading = false })
    }
  }
}
</script>

<style scoped>

</style>
