import Vue from 'vue'
import axios from 'axios'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { logout } from './modules/auth'
import App from './App.vue'
import VueMeta from 'vue-meta'
import { get } from 'lodash'
import addTagManager from './plugins/TagManager'

Vue.use(VueMeta)

Vue.mixin({
  methods: {
    $get: get
  }
})

// plugins
require('./plugins/Buefy')
require('./plugins/VeeValidate')
require('./plugins/VueMoment')
addTagManager(router)

axios.defaults.baseURL = process.env.VUE_APP_API

axios.interceptors.response.use(null, error => {
  const status = error.response.status
  if (status === 403) {
    logout()
  }
  return Promise.reject(error)
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
