import Vue from 'vue'
import isURL from 'validator/lib/isURL'
import { localize, ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, email, min, max, size, mimes, regex } from 'vee-validate/dist/rules'
import es from 'vee-validate/dist/locale/es.json'

extend('email', email)
extend('required', required)
extend('min', min)
extend('max', max)
extend('size', size)
extend('mimes', mimes)
extend('regex', regex)
extend('password', {
  params: ['target'],
  validate (value, { target }) {
    return value === target
  },
  message: 'Las contraseñas no coinciden'
})
extend('url', {
  validate (value) {
    return isURL(value, {
      require_tld: true
    })
  },
  message: 'La url ingresada no es válida'
})

localize('es', es)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
