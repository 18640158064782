<template>
  <div class="container">
    <section  class="section">
      <div class="is-flex is-flex-direction-column has-text-centered is-align-items-center">
        <img :src="require('../assets/images/not_found.png')" alt="not_found">
        <h2 class="title">404</h2>
        <h2 class="title is-5 mb-6">Ups...Esta pagina no existe</h2>
        <div>
          <b-button type="is-primary" @click="$router.push({ name: 'home' })">Volver al inicio</b-button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { getMetaInfo } from '@/modules/meta'

export default {
  name: 'NotFoundView',
  metaInfo () {
    return getMetaInfo(
      'Pagina no encontrada',
      'Pagina no encontrada. Sumate a nuestra comunidad y compartí tus experiencias',
      'assets/images/hero-01.svg'
    )
  }
}
</script>

<style scoped lang="sass">
  img
    height: 300px
    margin-bottom: 3rem
</style>
