<template>
  <section class="categories">
    <h3 class="title">Encontrá comercios por categoría</h3>
    <div class="categories__grid">
      <div v-for="category in StoreTypes"
        :key="category.id"
        class="category"
        @click="clickFilter(category.id)"
        >
        <div class="category__container"
          :class="category.name"
          :style="{ backgroundColor: category.style.color }"
        >
          <b-icon :icon="category.style.icon"
          ></b-icon>
          <div class="category__title">
            {{ category.name }}
          </div>
        </div>
        <span class="category__circle"
          :style="{ backgroundColor: category.style.color }"
          >
        </span>
      </div>
      <!-- :style=(StoreTypes.'{ backgroundColor: category.style.color }') -->
    </div>
  </section>
</template>

<script>
import StoreTypes from '@/constants/storeTypes'

export default {
  name: 'CategoriesHome',
  data () {
    return {
      StoreTypes
    }
  },
  methods: {
    clickFilter: function (id) {
      this.$router.push({
        name: 'results',
        query: {
          provinceId: this.$store.getters.getPreferredProvince,
          types: [id]
        }
      })
    }
  }
}
</script>

<style scoped lang="sass">
.categories
  cursor: pointer
  display: flex
  text-align: center
  flex-direction: column
  align-items: center
  padding: 4rem 1rem
  .title
    margin-bottom: 3rem
    text-align: left
    line-height: 1.3
.categories__grid
  display: grid
  grid-template-columns: repeat(1, 1fr)
  grid-template-rows: repeat(7, 1fr)
  align-items: center
  justify-content: center
  gap: .75rem
  width: 100%
.category
  display: flex
  position: relative
.category__container
  height: 86px
  width: 100%
  align-items: center
  border-radius: 32px
  // gap: .5rem
  padding: 0 1rem
  // box-shadow: inset 0 0 0 0.25rem rgba(255, 255, 255, 0.2), inset 0 -9px 0 0 rgba(0, 0, 0, 0.1)
  background-size: auto 100%, 258px
  background-position: right bottom
  background-repeat: no-repeat, repeat-x
  display: flex
  position: relative
  z-index: 2
  background-image: url('../../assets/images/10--10.svg'), url('../../assets/images/categories__bg.svg')
  &.Resto
    background-image: url('../../assets/images/categories__resto.svg')
  &.Bar
    background-image: url('../../assets/images/categories__bar.svg')
  &.Café
    background-image: url('../../assets/images/categories__cafe.svg')
  &.Delivery
    background-image: url('../../assets/images/categories__delivery.svg')
  &.Tienda
    background-image: url('../../assets/images/categories__tienda.svg')
  &.Heladería
    background-image: url('../../assets/images/categories__heladeria.svg')
  &.Take.away
    background-image: url('../../assets/images/categories__takeaway.svg')
  .icon
    color: #FFF
    font-size: 1.5rem
    width: 3rem
    height: 3rem
    // padding: 1.25rem
.category__title
  font-size: 1.15rem
  font-weight: bold
  color: #FFF
  padding: 1rem 2rem
  border: 1px solid white
  border-radius: 0 50px 50px 0
  border-left: 0
// .category__circle
//   position: absolute
//   top: 0
//   left: 0
//   right: 0
//   margin: auto
//   border-radius: 50%
//   height: 6rem
//   width: 6rem
//   z-index: 0
//   box-shadow: inset 0 0 0 0.5rem rgba(255,255,255,.25)
@media (min-width: 768px)
  .categories
    .categories__grid
      grid-template-columns: repeat(2, 1fr)
      grid-template-rows: repeat(2, 1fr)
      gap: 1.25rem 1rem
      justify-content: center
      align-items: center
      max-width: 900px
      margin: 0 auto
    .category__container
      max-width: 450px
    .title
      text-align: center
</style>
