const metaImage = (url) => {
  if (url && !url.startsWith('http')) {
    return window.location.origin + require(`../${url}`)
  }
  return url
}

const getMetaInfo = (title, description, imageUrl) => {
  return {
    title,
    meta: [
      {
        name: 'description',
        content: description
      },
      {
        property: 'og:title',
        content: title
      },
      {
        property: 'og:url',
        content: window.location.href
      },
      {
        property: 'og:image',
        content: metaImage(imageUrl)
      },
      {
        property: 'og:description',
        content: description
      },
      {
        property: 'twitter:title',
        content: title
      },
      {
        property: 'twitter:image',
        content: metaImage(imageUrl)
      },
      {
        property: 'twitter:url',
        content: window.location.href
      },
      {
        property: 'twitter:description',
        content: description
      },
      {
        property: 'twitter:card',
        content: 'summary_large_image'
      }
    ]
  }
}

export {
  getMetaInfo
}
