<template>
  <div class="buttons is-flex is-justify-content-end pt-3">
    <b-button type="is-default"
              v-show="!hideBack"
              :expanded="isMobile"
              icon-left="chevron-left"
              @click="$emit('back')"
    >
      Atras
    </b-button>
    <b-button v-if="showSave" type="is-primary"
              :expanded="isMobile"
              icon-left="playlist-check"
              @click="$emit('save')"
    >
      <slot name="createBtn">Crear Receta</slot>
    </b-button>
    <b-button v-else
              type="is-primary"
              :disabled="invalid"
              :expanded="isMobile"
              icon-left="chevron-right"
              @click="$emit('next')"
    >
      Continuar
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'NavigationButtons',
  inject: ['isMobile'],
  props: {
    invalid: {
      required: true
    },
    hideBack: {
      required: false
    },
    showSave: {
      required: false
    }
  }
}
</script>

<style scoped lang="sass">
.buttons
  flex-direction: column-reverse
@media (min-width: 768px)
  .buttons
    flex-direction: row
</style>
