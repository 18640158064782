var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desktop-filters"},[_c('div',{staticClass:"is-flex is-justify-content-space-between"},[_c('div',{staticClass:"counter"},[_vm._v(" "+_vm._s(_vm.total)+" "+_vm._s(_vm.total === 1 ? 'receta encontrada' : 'recetas encontradas')+" ")]),_c('filter-categories',{on:{"input":function($event){return _vm.$emit('changeFirstPage')}},model:{value:(_vm.params.categories),callback:function ($$v) {_vm.$set(_vm.params, "categories", $$v)},expression:"params.categories"}})],1),_c('div',{staticClass:"is-flex is-justify-content-space-between"},[_c('input-text',{staticClass:"searcher mr-2",attrs:{"name":"searcher","placeholder":"Buscar por nombre de receta","icon":"magnify","icon-right":_vm.params.search ? 'close' : '',"value":_vm.params.search},on:{"input":e => _vm.$emit('updateSearch', e),"icon-right-click":function($event){return _vm.$emit('cleanSearch')}}}),_c('div',{staticClass:"is-flex"},[_c('input-select',{staticClass:"filter mr-2",attrs:{"name":"difficulty","icon":"tune-variant","placeholder":"Dificultad","options":[
                            { id: '', label: 'Todas' },
                            { id: 1, label: 'Simple' },
                            { id: 2, label: 'Moderada' },
                            { id: 3, label: 'Compleja' }
                          ],"value":_vm.params.difficulty ? _vm.params.difficulty : undefined},on:{"input":e => _vm.$emit('changeSelect', {
                      val: e,
                      key: 'difficulty'
                    })}}),_c('input-select',{staticClass:"filter",attrs:{"name":"ordering","icon":"filter-variant","options":[
                    { id: '-created', label: 'Recientes' },
                    { id: '-likes', label: 'Populares' },
                  ],"value":_vm.params.ordering},on:{"input":e => _vm.$emit('changeSelect', {
                      val: e,
                      key: 'ordering'
                    })}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }