import router from '../router'
import provinces from '../constants/provinces'

const storeModule = ({
  state: {
    search: undefined,
    lastResults: undefined
  },
  getters: {
    getLastResults: (state) => {
      return state.lastResults
    },
    getCurrentSearch: (state) => {
      return state.search
    },
    getCurrentProvince: state => {
      if (!state.search) return undefined
      const current = provinces.find(x => x.id === state.search.provinceId)
      return current ? current.name : undefined
    },
    getCurrentLocation: (state, _, rootState) => {
      if (!state.search) return undefined
      const provinceId = state.search.provinceId
      const provinceData = rootState.locations.find(x => x.provinceId === provinceId)
      if (!provinceData) {
        return undefined
      }
      const location = provinceData.data.find(x => x.id === parseInt(state.search.locationId))
      return location ? location.name : undefined
    }
  },
  mutations: {
    setLastResults (state, list) {
      state.lastResults = list
    },
    setSearch: (state, search) => {
      if (search && !search.provinceId) {
        search.provinceId = 1
      }
      state.search = search
    }
  },
  actions: {
    search (context, searchObject) {
      const newSearch = Object.assign({}, context.state.search, searchObject)
      newSearch.page = 1
      context.commit('setSearch', newSearch)
      if (router.app._route.name !== 'results') {
        context.commit('setLastResults', undefined)
        router.push({ name: 'results', query: newSearch })
      }
    },
    applyFilters (context, search) {
      const newSearch = Object.assign({}, context.state.search, search)
      newSearch.page = 1
      context.commit('setSearch', newSearch)
    },
    cleanLocationSearch (context) {
      if (context.state.search) {
        context.state.search.locationId = undefined
      }
    }
  }
})

export default storeModule
