<template>
  <div class="container">
    <div class="section">
      <p class="subtitle">Ingrese su email y le enviaremos un correo con las indicaciones para restaurar su contraseña.</p>
      <ValidationObserver ref="validator" v-slot="{ invalid }">
        <form @submit.prevent="updatePassword">
          <input-text v-model="payload.username"
                      icon="at"
                      label="Email"
                      name="username"
                      validate="required|email"
          ></input-text>
          <b-button
            class="is-pulled-right mt-3"
            name="submit"
            icon-left="email-send-outline"
            :disabled="isLoading || invalid"
            label="Confirmar"
            type="is-primary"
            native-type="submit"
            :loading="isLoading"
          />
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import InputText from '@/components/Common/InputText'
import Recaptcha from '@/components/Mixins/Recaptcha'
import { getMetaInfo } from '@/modules/meta'

export default {
  name: 'RequestResetPasswordView',
  components: {
    InputText
  },
  metaInfo () {
    return getMetaInfo(
      'Restablecer contraseña',
      'Restablece tu contraseña y enviaremos un email con instrucciones',
      'assets/images/hero3.svg'
    )
  },
  mixins: [
    Recaptcha
  ],
  data () {
    return {
      isLoading: false,
      payload: {}
    }
  },
  methods: {
    updatePassword: async function () {
      this.isLoading = true
      this.payload.token = await this.getRecaptchaToken('recovery_password')
      axios.post('auth/request-reset-password/', this.payload)
        .then(({ data }) => {
          this.showSnackbar({
            message: 'Se ha enviado a tu email instrucciones para restaurar tu contraseña.',
            type: 'is-success'
          })
          this.$router.push({ name: 'home' })
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            this.$refs.validator.setErrors(response.data.errors)
          } else {
            this.showSnackbar({
              message: response.data.message,
              type: 'is-danger'
            })
          }
        })
        .finally(() => { this.isLoading = false })
    },
    showSnackbar: function (payload) {
      this.$buefy.snackbar.open({
        duration: 3000,
        message: payload.message,
        type: payload.type,
        position: 'is-bottom-right'
      })
    }
  }
}
</script>

<style scoped>

</style>
