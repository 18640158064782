<template>
  <ValidationProvider v-slot="{ errors }" :name="label" :rules="validate" :vid="name">
    <b-field :label="label" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
      <b-select :value="value"
                :icon="icon"
                :size="size"
                :placeholder="placeholder"
                :disabled="disabled"
                :multiple="multiple"
                @input="e => $emit('input', e)"
                :expanded="expanded"
      >
        <option
          v-for="option in options"
          :value="option.id"
          :key="option.id">
          {{ option.label }}
        </option>
      </b-select>
    </b-field>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'InputSelect',
  props: {
    options: {
      required: true,
      type: Array
    },
    name: {
      required: true
    },
    value: {
      required: true
    },
    label: {
      required: false
    },
    icon: {
      required: false
    },
    placeholder: {
      required: false
    },
    validate: {
      required: false,
      default: ''
    },
    disabled: {
      required: false
    },
    expanded: {
      required: false
    },
    size: {
      required: false
    },
    multiple: {
      required: false
    }
  }
}
</script>

<style scoped lang="sass">
.field
  margin-bottom: 0.5rem
</style>
