<template>
  <ValidationObserver>
    <input-text :label="label"
                icon="lock"
                name="password"
                type="password"
                validate="required|min:8"
                :placeholder="label"
                v-model="password"
    ></input-text>
    <input-text label="Confirme contraseña"
                icon="lock-check"
                name="confirm"
                type="password"
                validate="required|password:@password"
                placeholder="Confirme su contraseña"
                v-model="confirm"
                @input="e => $emit('input', e)"
    ></input-text>
  </ValidationObserver>
</template>

<script>
import inputText from '@/components/Common/InputText'

export default {
  name: 'PasswordConfirm',
  props: {
    isUpdate: {
      required: false
    }
  },
  components: {
    inputText
  },
  data () {
    return {
      password: '',
      confirm: ''
    }
  },
  computed: {
    label: function () {
      return this.isUpdate ? 'Nueva contraseña' : 'Contraseña'
    }
  }
}
</script>

<style scoped>

</style>
