<template>
  <div class="items_container container">
    <b-tabs v-model="activeTab" position="is-centered" expanded @input="changeTab">
      <b-tab-item label="Lugares" value="stores">
        <div class="ItemsContainer__container">
          <slot name="first"></slot>
          <div class="ItemsContainer__results">
            <sort-actions :is-loading="isLoading">
              <template #mobile>
                <div  class="has-text-weight-bold">
                  <span v-text="legendResults"></span>
                </div>
              </template>
            </sort-actions>
            <!-- <b-pagination
              v-if="total > size"
              :total="total"
              :per-page="size"
              :current="page"
              order="is-left"
              size="is-small"
              aria-next-label="Proxima Pagina"
              aria-previous-label="Pagina Previa"
              aria-page-label="Pagina"
              aria-current-label="Pagina actual"
              @change="e => $emit('change-page', e)"
            >
            </b-pagination> -->
            <skeleton-loading v-if="isLoading"></skeleton-loading>
            <div v-else class="items_container__grid">
              <store-item v-for="store in results"
                          :key="store.id"
                          :store="store"
                          @click="goToDetails"
              ></store-item>
              <no-results v-if="!results.length && !isLoading"
                          class="p-5"
              />
            </div>
            <b-pagination
              v-if="total > size"
              :total="total"
              :per-page="size"
              :current="page"
              :rangeBefore="0"
              :rangeAfter="0"
              order="default"
              size="is-small"
              icon-prev="arrow-left"
              icon-next="arrow-right"
              :rounded="true"
              aria-next-label="Proxima Pagina"
              aria-previous-label="Pagina Previa"
              aria-page-label="Pagina"
              aria-current-label="Pagina actual"
              @change="e => $emit('change-page', e)"
            >
            </b-pagination>
          </div>
          <slot name="last"></slot>
        </div>
      </b-tab-item>
      <b-tab-item label="Mapa" value="map">
        <map-component
          :key="mapRender"
          :sync-user="true"
          :coords-list="coordsList"
          :center="coordsList.length ? coordsList[0].coords : $store.getters.getUserCoords"
          :zoom="zoom || (lastSearch.locationId ? 13 : 11)"
          @click-item="clickStore"
        />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import StoreItem from '@/components/StoreResults/StoreItem'
import NoResults from '@/components/StoreResults/NoResults'
import SkeletonLoading from '@/components/StoreResults/SkeletonLoading'
import MapComponent from '@/components/MapComponent'
import SortActions from './SortActions'
import ModalStoreMap from './ModalStoreMap'

export default {
  name: 'ItemsContainer',
  props: {
    results: {
      required: true
    },
    coordsList: {
      required: true
    },
    lastSearch: {
      required: false
    },
    page: {
      required: true
    },
    isLoading: {
      required: true
    },
    total: {
      required: true
    },
    size: {
      required: true
    },
    zoom: {
      required: false,
      type: Number
    }
  },
  components: {
    StoreItem,
    MapComponent,
    NoResults,
    SkeletonLoading,
    SortActions
  },
  data () {
    return {
      activeTab: 'stores',
      mapRender: 0,
      showMobileFilters: false
    }
  },
  methods: {
    goToDetails: function (store) {
      this.$store.commit('setLastResults', {
        results: this.results,
        count: this.total,
        page: this.page
      })
      this.$router.push({
        name: 'store',
        params: { id: store.id }
      })
    },
    clickStore: function (store) {
      this.$buefy.modal.open({
        parent: this,
        component: ModalStoreMap,
        props: { store, callback: this.goToDetails },
        hasModalCard: true
      })
    },
    changeTab: function (tabValue) {
      if (tabValue === 'map') {
        this.mapRender += 1
      }
    }
  },
  computed: {
    legendResults: function () {
      return this.isLoading ? 'Buscando...' : `${this.total} resultado${this.total === 1 ? '' : 's'}`
    }
  }
}
</script>

<style scoped lang="sass">
@import "src/assets/styles/variables"
:deep()
  .StoreItem
    flex-direction: row
    padding: .5rem
    border-color: rgba($black, 0.2)
    border-width: 1px 0 0 0
    border-radius: 0
    margin: 0
    box-shadow: none
    .image img
      border-radius: 8px
      min-width: 140px
      max-width: 140px
      max-height: 160px
      border: 1px solid #dbdbdb
    .StoreItem__body
      padding: 0.25rem 1rem
    .StoreItem__body .store__info .name
      font-size: 1rem
    .location
      font-size: 12px
      font-weight: 600
    .StoreItem__counters .counters__box
      padding: 0
      background-color: transparent
    .StoreItem__container
      position: relative
    .types
      position: absolute
      left: -155px
      justify-content: flex-start
      bottom: 4px
      width: 140px
      z-index: 10
.items_container
  overflow-x: hidden
  .items_container__grid
    display: flex
    flex-direction: column
    overflow-y: auto
    overflow-x: hidden
    width: 100%
  .mapComponent
    width: 100%
    border: 1px solid #CCC
    border-radius: 0
    display: flex
    margin: 0
    height: calc(100vh - 10rem)
.ItemsContainer__container
  display: flex
  gap: 0.5rem
  flex-direction: column
  background-color: $primary
  padding: 1rem 0.5rem 5rem
.ItemsContainer__results
  display: flex
  flex-direction: column
  align-items: flex-start
  background: $white
  padding: 0
  border-radius: $border-radius $border-radius 12px 12px
  overflow: hidden
  .actions
    margin: 0
:deep()
  .tab-content
    padding: 0 !important
  .pagination
    width: 100%
    display: flex
    margin: 0
    position: fixed
    bottom: 3.5rem
    left: 0
    background: #efebfe
    z-index: 18
    padding: 0
    padding-bottom: 1.5rem
    border-radius: 20px 12px 0 0
    padding-top: 2px
    .pagination-list
      // order: 1
    .pagination-link
      order: 99
      background-color: $primary
      height: 2rem
      width: 2rem
      padding: 0
      font-size: .8rem
      font-weight: bolder
      color: $white
      &.is-current
        background-color: $secondary
        color: $black
        border: none
  .mapComponent
    .b-skeleton-item
      height: 100%
  .tabs
    background-color: $primary-700
    ul
      border-color: transparent
    li a
      font-weight: bolder
      padding: 0
      color: $white
      min-height: 2.75rem
      border-width: .375rem
      border-color: rgba($white, .2)
    li.is-active a
      color: $secondary
      border-color: $secondary !important
      background-color: $primary
  .search
    display: block
    background-color: #efebfe
    padding: .5rem
    input
      border-radius: $border-radius
    .field
      margin: 0
      width: 100%
</style>
