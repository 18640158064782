<template>
  <div class="comments_box">
    <b-loading v-if="!comments.length" :is-full-page="false" v-model="loading"></b-loading>
    <div class="items_container__grid">
      <comment-store v-for="(comment, i) in comments"
                     :key="i"
                     :comment="comment"
      ></comment-store>
    </div>
    <b-button v-if="hasMore"
              class="my-2 --btnMore"
              type="is-primary"
              :loading="loading"
              @click="getComments"
              :expanded="isMobile"
    >
      Ver mas
    </b-button>
    <b-message v-if="!totalComments && !loading"
               type="is-info"
    >
      Este usuario no ha realizado comentarios por el momento.
    </b-message>
  </div>
</template>

<script>
import CommentStore from '@/components/Profile/CommentStore'
import axios from 'axios'

export default {
  name: 'CommentsBox',
  props: {
    member: {
      required: true
    }
  },
  components: {
    CommentStore
  },
  inject: ['isMobile'],
  data () {
    return {
      comments: [],
      totalComments: 0,
      hasMore: false,
      loading: true,
      page: 0
    }
  },
  methods: {
    getComments: function () {
      this.loading = true
      this.page += 1
      const params = {
        size: 6,
        page: this.page
      }
      axios.get(`members/${this.member.user}/comments/`, { params })
        .then(({ data }) => {
          this.comments = this.comments.concat(data.results)
          this.totalComments = data.count
          this.hasMore = data.next
        })
        .finally(() => { this.loading = false })
    }
  },
  created () {
    this.getComments()
  }
}
</script>

<style scoped lang="sass">
.comments_box
  min-height: 5rem
.items_container__grid
  display: flex
  flex-wrap: wrap
  width: 100%
  gap: 0 1rem
.--btnMore
  min-width: 200px
</style>
