import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['getToken'])
  },
  watch: {
    getToken: {
      immediate: true,
      handler (data) {
        if (!data) {
          this.$router.push({ name: 'home' })
          this.$buefy.snackbar.open({
            duration: 3000,
            message: 'Debes estar logueado para tener acceso a esta sección',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
        }
      }
    }
  }
}
