<template>
  <div class="foot">
    <div class="container">
      <div class="foot__linkGroup">
        <div class="foot__link"
             @click="$router.push({ name: 'about' })"
        >
          Quienes somos
        </div>
        <div class="foot__link" @click="$store.dispatch('search')">
          Tiendas
        </div>
        <div class="foot__link" @click="modalIsComming('recetas')">
          Recetas
        </div>
        <div class="foot__link" @click="$router.push({ name: 'news' })">
          Noticias
        </div>
        <div class="foot__link" @click="modalIsComming('comunidad')">
          Comunidad
        </div>
        <div class="foot__link" @click="goLegal">
          Privacidad y Protección de datos
        </div>
      </div>
      <div class="foot__logo">
        <img class="foot__logoImg"
             :src="require('../../assets/images/logo.svg')"
             alt="logo"
        >
        <span class="foot__logoType">celisana</span>
      </div>
      <div class="foot__social">
        <b-button size="is-large"
                  type="is-ghost"
                  icon-left="facebook"
                  @click="goSocialUrl('https://www.facebook.com/celisana.ar')"
        >
        </b-button>
        <b-button size="is-large"
                  type="is-ghost"
                  icon-left="instagram"
                  @click="goSocialUrl('https://www.instagram.com/celisana.ar/')"
        >
        </b-button>
        <cafecito-logo></cafecito-logo>
      </div>
    </div>
  </div>
</template>

<script>
import CafecitoLogo from './CafecitoLogo'

export default {
  name: 'Footer',
  components: {
    CafecitoLogo
  },
  methods: {
    modalIsComming: function (sectionName) {
      window.scrollTo(0, 0)
      this.$buefy.dialog.alert({
        title: 'Proximamente',
        message: '¡En Celisana no paramos de crecer juntos!<br>Esta sección esta en desarrollo para que proximamente puedas seguir compartiendo con nosotros.',
        confirmText: 'Volver',
        type: 'is-primary'
      })
      this.$gtm.trackEvent({
        event: null,
        action: 'click',
        value: sectionName
      })
    },
    goLegal: function () {
      window.location.href = '/legal/'
    },
    goSocialUrl: function (url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<style lang="sass" scoped>
.foot
  padding: 5rem 0 1.5rem
  background-image: url(../../assets/images/pttrn.svg)
  background-repeat: repeat-x
  // margin-top: 4rem
  .container
    display: flex
    flex-wrap: wrap
.foot__logo
  display: flex
  align-items: center
  img
    height: 4rem
.foot__logoType
  font-weight: 900
  font-size: 1.5rem
  margin-left: 1rem
  letter-spacing: .6px
  margin-bottom: 3px
.foot__linkGroup
  display: flex
  flex-direction: column
  width: 100%
  margin-bottom: 2rem
  text-align: center
.foot__link
  font-weight: 900
  font-size: 1.2rem
  margin: 1rem
  cursor: pointer
  &:hover
    color: #7555F6
  &:active
    color: #7855F6
.foot__social
  display: flex
  align-items: center
  margin-left: auto
  button
    color: #7555F6
@media (min-width: 768px)
  .foot__linkGroup
    flex-direction: row
  .foot__link
    margin: 1rem 3.5rem 1rem 0
</style>
