<template>
  <div class="AddCommentBox">
    <b-button type="is-secondary is-rounded"
              native-type="submit"
              icon-left="comment-plus"
              @click="clickAdd"
    >
      Subir comentario
    </b-button>
    <b-collapse v-model="showAddComment"
                animation="slide"
    >
      <ValidationObserver v-slot="{ invalid }"
                          ref="form"
      >
        <div class="AddCommentBox__container --bordered" id="comment_box_add">
          <input-text label="Comentario"
                      type="textarea"
                      name="comment"
                      placeholder="Contanos que te pareció el lugar"
                      validate="required"
                      v-model="payload.comment"
                      maxlength="500"
          ></input-text>
          <div class="AddCommentBox__container__score">
            <b-field label="Puntaje">
              <b-rate :show-score="true" v-model="payload.score" spaced size="is-medium"></b-rate>
            </b-field>
            <b-button type="is-secondary"
                      class="submit"
                      native-type="submit"
                      icon-left="send"
                      size="is-default"
                      :loading="isLoading"
                      :disabled="invalid || isLoading"
                      @click="saveComment()"
                      rounded
            >
              Enviar
            </b-button>
          </div>
        </div>
      </ValidationObserver>
    </b-collapse>

  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import { needLogin } from '@/modules/auth'
import inputText from '@/components/Common/InputText'
import ModalAddComment from './ModalAddComment'

export default {
  name: 'AddCommentBox',
  components: {
    inputText
  },
  data () {
    return {
      payload: {},
      isLoading: false,
      showAddComment: false
    }
  },
  methods: {
    initPayload: function () {
      this.payload = {
        score: 5,
        comment: ''
      }
    },
    saveComment: function () {
      this.isLoading = true
      axios.post(`stores/${this.$parent.store.id}/comments/`, this.payload)
        .then(({ data }) => {
          this.$buefy.modal.open({
            parent: this,
            component: ModalAddComment,
            hasModalCard: true
          })
          this.initPayload()
          this.$refs.form.reset()
          this.$emit('add', data)
        })
        .catch(({ response }) => {
          this.$buefy.snackbar.open({
            duration: 3000,
            message: response.data.message || 'Ha ocurrido un error al subir su comentario.<br> Por favor intente mas tarde',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
        })
        .finally(() => { this.isLoading = false })
    },
    clickAdd: function () {
      if (!this.getToken) {
        needLogin(this)
        return
      }
      this.showAddComment = !this.showAddComment
      if (this.showAddComment) {
        setTimeout(() => {
          document.getElementById('comment_box_add').scrollIntoView({
            behavior: 'smooth'
          })
        }, 200)
      }
    }
  },
  computed: {
    ...mapGetters(['getToken'])
  },
  created () {
    this.initPayload()
  }
}
</script>

<style scoped lang="sass">
@import "src/assets/styles/variables"

.collapse
  width: 100%
.AddCommentBox
  display: flex
  justify-content: flex-end
  align-items: flex-end
  flex-direction: column
.AddCommentBox__container
  margin-top: 1rem
  border-radius: $border-radius
  width: 100%
  background: white
  &__score
    display: flex
    flex-direction: column
    align-content: flex-end
    .submit
      margin-top: 1rem
  :deep()
    .rate
      justify-content: space-evenly
@media (min-width: 768px)
  .AddCommentBox__container
    &__score
      flex-direction: row
      justify-content: space-between
      .submit
        margin-top: 0
</style>
