<template>
  <ValidationObserver ref="validator" v-slot="{ invalid }">
    <form @submit.prevent="save">
      <div class="columns">
      <div class="column is-one-quarter">
        <input-text label="Email"
                    name="user"
                    icon="at"
                    disabled
                    v-model="$store.getters.getUser.username"
        ></input-text>
      </div>
      <div class="column is-one-quarter">
        <input-text label="Nombre"
                    name="name"
                    icon="card-account-details"
                    validate="required|max:45"
                    placeholder="Tu nombre de perfil público"
                    v-model="payload.name"
        ></input-text>
      </div>
      <div class="column is-one-quarter">
        <input-select v-model="payload.gender"
                      validate="required"
                      placeholder="Seleccione"
                      label="Género"
                      name="gender"
                      icon="account"
                      :options="genders"
                      expanded
        >
        </input-select>
      </div>
      <div class="column is-one-quarter">
        <input-select v-model="payload.province_id"
                      icon="earth"
                      name="province_id"
                      label="Provincia"
                      :options="provinces"
                      expanded
        >
        </input-select>
      </div>
      </div>
      <rich-area label="Bio"
                 name="bio"
                 validate="required"
                 placeholder="Cuentanos acerca de tí"
                 v-model="payload.bio"
      ></rich-area>
      <b-field>
        <b-switch v-model="payload.private">
          Historial Privado
        </b-switch>
      </b-field>
      <div class="footer__buttons">
        <b-dropdown aria-role="list" class="more_options">
          <template #trigger="{ active }">
            <b-button icon-left="dots-vertical"
                      label="Mas acciones"
                      :icon-right="active ? 'menu-up' : 'menu-down'"
                      :expanded="isMobile"
            />
          </template>
          <b-dropdown-item aria-role="listitem" @click="changePassword">
            <div class="media">
              <b-icon class="media-left" icon="lock-reset"></b-icon>
              <div class="media-content">
                <h3>Cambiar contraseña</h3>
              </div>
            </div>
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem" @click="deleteAccount">
            <div class="media">
              <b-icon class="media-left" icon="delete"></b-icon>
              <div class="media-content">
                <h3>Eliminar cuenta</h3>
              </div>
            </div>
          </b-dropdown-item>
        </b-dropdown>
        <b-button
          :disabled="isLoading || invalid"
          icon-left="content-save"
          label="Guardar cambios"
          type="is-primary"
          native-type="submit"
          :loading="isLoading"
          :expanded="isMobile"
        />
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import axios from 'axios'
import inputText from '@/components/Common/InputText'
import richArea from '@/components/Common/RichArea'
import inputSelect from '@/components/Common/InputSelect'
import changePasswordModal from './ChangePasswordModal'
import provinces from '@/constants/provinces'
import { logout } from '@/modules/auth'

export default {
  name: 'AccountForm',
  props: {
    value: {
      required: true
    },
    isLoading: {
      required: true
    }
  },
  components: {
    inputText,
    inputSelect,
    richArea
  },
  inject: ['isMobile'],
  watch: {
    value: {
      immediate: true,
      handler (data) {
        this.payload = data
      }
    }
  },
  data () {
    return {
      payload: {},
      provinces: provinces.map(x => { return { id: x.id, label: x.name } }),
      genders: [
        {
          id: 'M',
          label: 'Masculino'
        },
        {
          id: 'F',
          label: 'Femenino'
        },
        {
          id: 'I',
          label: 'Indefinido'
        }
      ]
    }
  },
  methods: {
    save: function () {
      this.$refs.validator.validate()
        .then((result) => {
          if (result) {
            this.$emit('save')
          }
        })
    },
    changePassword: function () {
      this.$buefy.modal.open({
        parent: this,
        component: changePasswordModal,
        hasModalCard: true,
        canCancel: false,
        customClass: '',
        trapFocus: true
      })
    },
    deleteAccount: function () {
      this.$buefy.dialog.prompt({
        title: 'Eliminar cuenta',
        message: '<b>ATENCION: Tu perfil y todos tus comentarios van a ser borrados!</b><br>Confirma tu email para borrar tu cuenta:',
        inputAttrs: {
          placeholder: 'usuario@email.com'
        },
        type: 'is-danger',
        hasIcon: true,
        icon: 'close-circle',
        trapFocus: true,
        closeOnConfirm: false,
        onConfirm: (value, { close }) => this.callDelete(value, close)
      })
    },
    callDelete: function (email, close) {
      if (email !== this.$store.getters.getUser.username) {
        this.$buefy.toast.open({
          message: 'Tu email no es correcto',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return 1
      }
      this.$buefy.toast.open({ message: 'eliminando...', position: 'is-bottom' })
      const loadingComponent = this.$buefy.loading.open({})
      axios.get('profile/delete-account/')
        .catch(e => {
          this.$buefy.toast.open({
            message: e.response.data,
            position: 'is-bottom',
            type: 'is-danger'
          })
        })
        .finally(() => {
          this.$buefy.toast.open('Tu cuenta fue eliminada de Celisana')
          logout()
          close()
          loadingComponent.close()
        })
    }
  }
}
</script>

<style scoped lang="sass">
.footer__buttons
  display: flex
  flex-direction: column
  :deep()
    .dropdown-trigger
      width: 100%
      margin-bottom: 1rem
@media (min-width: 768px)
  .footer__buttons
    flex-direction: row
    justify-content: space-between
    :deep()
      .dropdown-trigger
        margin-bottom: 0
</style>
