import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/HomeView.vue'
import ResultsView from '@/views/StoreResultsView.vue'
import StoreView from '@/views/StoreDetailsView.vue'
import ProfileView from '@/views/ProfileView.vue'
import NotFoundView from '@/views/NotFound.vue'
import CollaborationView from '@/views/CollaborationView'
import NewsView from '@/views/NewsView.vue'
import NewsDetailView from '@/views/NewsDetailView.vue'
import StoreNearlyView from '@/views/StoreNearlyView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/stores',
    name: 'results',
    component: ResultsView
  },
  {
    path: '/stores/nearly',
    name: 'stores-nearly',
    component: StoreNearlyView
  },
  {
    path: '/stores/:id',
    name: 'store',
    props: true,
    component: StoreView
  },
  {
    path: '/profile/:id',
    name: 'profile',
    props: true,
    component: ProfileView,
    meta: {
      title: ' '
    }
  },
  {
    path: '/my-account',
    name: 'my-account',
    meta: {
      title: 'Configuración de cuenta'
    },
    component: () => import(/* webpackChunkName: "my-account" */ '@/views/MyAccountView.vue')
  },
  {
    path: '/favorites',
    name: 'favorites',
    component: () => import(/* webpackChunkName: "my-account" */ '@/views/FavoritesView.vue'),
    meta: {
      title: 'Favoritos'
    }
  },
  {
    path: '/my-account/request/reset-password',
    name: 'request-reset-password',
    meta: {
      title: 'Restablecer contraseña'
    },
    component: () => import(/* webpackChunkName: "reset-password" */ '@/views/RequestResetPasswordView.vue')
  },
  {
    path: '/my-account/reset-password/:token',
    name: 'reset-password',
    props: true,
    meta: {
      title: 'Restablecer contraseña'
    },
    component: () => import(/* webpackChunkName: "reset-password" */ '@/views/ResetPasswordView.vue')
  },
  {
    path: '/add-store',
    name: 'new-store',
    meta: {
      title: 'Nuevo comercio'
    },
    component: () => import(/* webpackChunkName: "add-store" */ '@/views/NewStoreView.vue')
  },
  {
    path: '/stores/:id/update',
    name: 'update-store',
    props: true,
    meta: {
      title: 'Solicitud de cambio'
    },
    component: () => import(/* webpackChunkName: "add-store" */ '@/views/UpdateStoreView.vue')
  },
  {
    path: '/stores/:id/complaint',
    name: 'complaint-store',
    props: true,
    component: () => import(/* webpackChunkName: "add-store" */ '@/views/ComplaintView.vue'),
    meta: {
      title: 'Solicitud de baja'
    }
  },
  {
    path: '/signup/',
    name: 'signup',
    component: () => import(/* webpackChunkName: "signup" */ '@/views/SignUpView.vue')
  },
  {
    path: '/about-us/',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '@/views/AboutView.vue')
  },
  {
    path: '/signup/activate/:token',
    name: 'activate-account',
    props: true,
    component: () => import(/* webpackChunkName: "signup" */ '@/views/ActivateAccountView.vue')
  },
  {
    path: '/recipes',
    name: 'recipes',
    meta: {
      title: 'Recetas',
      onlyDesktop: true
    },
    component: () => import(/* webpackChunkName: "recipes" */ '@/views/RecipesResultsView.vue')
  },
  {
    path: '/recipe/:id',
    name: 'recipe-details',
    props: true,
    component: () => import(/* webpackChunkName: "recipes" */ '@/views/RecipeDetailsView.vue')
  },
  {
    path: '/new-collaboration',
    name: 'add',
    component: CollaborationView
  },
  {
    path: '/new-recipe',
    name: 'new-recipe',
    meta: {
      title: 'Nueva Receta'
    },
    component: () => import(/* webpackChunkName: "add-recipes" */ '@/views/NewRecipeView.vue')
  },
  {
    path: '/recipe/:id/modify',
    name: 'modify-recipe',
    meta: {
      title: 'Modificar Receta'
    },
    props: true,
    component: () => import(/* webpackChunkName: "add-recipes" */ '@/views/ModifyRecipeView.vue')
  },
  {
    path: '/news/',
    name: 'news',
    meta: {
      title: 'Noticias',
      onlyDesktop: true,
      keepAlive: true
    },
    component: NewsView
  },
  {
    path: '/news/:id/:title',
    name: 'news-detail',
    props: true,
    component: NewsDetailView
  },
  {
    path: '/not-found',
    name: 'not-found',
    component: NotFoundView,
    meta: {
      title: 'Pagina no encontrada'
    }
  },
  {
    path: '*',
    redirect: 'not-found'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  next()
  setTimeout(() => window.scrollTo(0, 0), 100)
})
export default router
