<template>
  <div class="container">
    <nav class="HomeView__numbers level is-mobile">
      <div class="level-item has-text-centered">
        <div class="metrics__item">
          <b-image class="metrics__itemImg" :src="require('@/assets/images/hero-feat-stores.png')" alt="hero_feat" />
          <div class="metrics__itemGroup">
            <p class="title counter" :total="metrics.stores">
              0
            </p>
            <p class="metrics__itemLabel">
              Comercios
            </p>
          </div>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div class="metrics__item">
          <b-image class="metrics__itemImg" :src="require('@/assets/images/hero-feat-comments.png')" alt="hero_comments"></b-image>
          <div class="metrics__itemGroup">
            <p class="title counter" :total="metrics.comments">
              0
            </p>
            <p class="metrics__itemLabel">
              Comentarios
            </p>
          </div>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div class="metrics__item">
          <b-image class="metrics__itemImg" :src="require('@/assets/images/hero-feat-members.png')" alt="hero_members"></b-image>
          <div class="metrics__itemGroup">
            <p class="title counter" :total="metrics.members">
              0
            </p>
            <p class="metrics__itemLabel">
              Miembros
            </p>
          </div>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div class="metrics__item">
          <b-image class="metrics__itemImg" :src="require('@/assets/images/hero-feat-recipes.png')" alt="hero_recipes"></b-image>
          <div class="metrics__itemGroup">
            <p class="title counter" :total="metrics.recipes">
              0
            </p>
            <p class="metrics__itemLabel">
              Recetas
            </p>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'Metrics',
  props: {
    metrics: {
      required: true
    }
  },
  watch: {
    metrics: {
      handler () {
        this.$nextTick(() => {
          const counters = document.querySelectorAll('.counter')
          const speed = 300
          counters.forEach(counter => {
            const animate = () => {
              const value = +counter.getAttribute('total')
              const data = +counter.innerText

              const time = value / speed
              if (data < value) {
                counter.innerText = Math.ceil(data + time)
                setTimeout(animate, 10)
              } else {
                counter.innerText = value
              }
            }
            animate()
          })
        })
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/styles/variables"

.HomeView__numbers
  flex-wrap: wrap
  .title
    font-weight: normal
    font-size: 2.5rem
    color: #7555F6
.metrics__itemLabel
  font-size: 1.25rem
  font-weight: 800
.level-item
  flex-basis: 50%
  margin: 0 0 1rem !important
.metrics__item, .metrics__itemGroup
  display: flex
  flex-direction: column
.metrics__itemGroup
  margin-top: -1.5rem
  z-index: 1
.metrics__itemImg
  background-color: $white
  border-radius: 50%
  padding: 2rem
  ::v-deep img
    width: 56px
@media (min-width: 768px)
  .level-item
    flex-basis: 25%
  .metrics__itemImg
    ::v-deep img
      width: 80px
</style>
