<template>
  <div class="desktop-filters">
    <div class="is-flex is-justify-content-space-between">
      <div class="counter">
        {{ total }} {{ total === 1 ? 'receta encontrada' : 'recetas encontradas' }}
      </div>
      <filter-categories v-model="params.categories"
                         @input="$emit('changeFirstPage')"
      ></filter-categories>
    </div>
    <div class="is-flex is-justify-content-space-between">
      <input-text name="searcher"
                  class="searcher mr-2"
                  placeholder="Buscar por nombre de receta"
                  icon="magnify"
                  :icon-right="params.search ? 'close' : ''"
                  :value="params.search"
                  @input="e => $emit('updateSearch', e)"
                  @icon-right-click="$emit('cleanSearch')"
      ></input-text>
      <div class="is-flex">
        <input-select name="difficulty"
                      icon="tune-variant"
                      class="filter mr-2"
                      placeholder="Dificultad"
                      :options="[
                              { id: '', label: 'Todas' },
                              { id: 1, label: 'Simple' },
                              { id: 2, label: 'Moderada' },
                              { id: 3, label: 'Compleja' }
                            ]"
                      :value="params.difficulty ? params.difficulty : undefined"
                      @input="e => $emit('changeSelect', {
                        val: e,
                        key: 'difficulty'
                      })"
        >
        </input-select>
        <input-select name="ordering"
                      icon="filter-variant"
                      class="filter"
                      :options="[
                      { id: '-created', label: 'Recientes' },
                      { id: '-likes', label: 'Populares' },
                    ]"
                      :value="params.ordering"
                      @input="e => $emit('changeSelect', {
                        val: e,
                        key: 'ordering'
                      })"
        >
        </input-select>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from '../Common/InputText'
import InputSelect from '../Common/InputSelect'
import FilterCategories from './FilterCategories'

export default {
  name: 'DesktopFilters',
  props: {
    params: {
      required: true
    },
    total: {
      required: true
    }
  },
  components: {
    InputText,
    InputSelect,
    FilterCategories
  }
}
</script>

<style scoped lang="sass">
.desktop-filters
  display: flex
  flex-direction: column
  .counter
    display: flex
    align-items: center
    color: #4A4A4AFF
    font-size: 1.5rem
    font-weight: 600
    line-height: 1.6
  .searcher
    width: 50%
  .filter
    width: 200px
</style>
