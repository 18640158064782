<template>
  <div v-if="getUser" class="favorite"
  >
    <b-button :size="size"
              :rounded="true"
              type="is-danger is-light"
              :loading="isLoading"
              :disabled="isLoading"
              :icon-right="!isLoading ? (isFavorite ? 'heart' : 'heart-outline') : ''"
              @click="toggleFavorite"
    ></b-button>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'Favorite',
  props: {
    entityId: {
      required: true,
      type: Number
    },
    entityName: {
      required: true,
      type: String
    },
    size: {
      required: false,
      default: ''
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    toggleFavorite: function () {
      this.isLoading = true
      const operation = this.isFavorite ? 'delete' : 'add'
      axios.post(`${this.entityName}/favorites/${this.entityId}/`, {
        operation
      })
        .then(() => {
          this.updateState()
        })
        .catch(({ response }) => {
          if (response && response.data.code === '3') {
            // error sync
            this.updateState()
          } else {
            this.$buefy.snackbar.open({
              duration: 3000,
              message: 'Ocurrió un error. Por favor vuelva a intentar más tarde.',
              type: 'is-danger',
              position: 'is-bottom-right'
            })
          }
        })
        .finally(() => {
          this.isLoading = false
          this.$buefy.snackbar.open({
            duration: 3000,
            message: `${operation === 'add' ? 'Agregado a tus favoritos' : 'Removido de tus favoritos'} `,
            type: operation === 'add' ? 'is-success' : 'is-warning',
            position: 'is-bottom-right'
          })
        })
    },
    updateState: function () {
      this.$store.dispatch('toggleFavorite', {
        entity: this.entityName,
        id: this.entityId
      })
    }
  },
  computed: {
    ...mapGetters([
      'getUser',
      'getFavorites'
    ]),
    isFavorite: function () {
      return this.getFavorites[this.entityName].includes(this.entityId)
    }
  }
}
</script>

<style scoped lang="sass">
.favorite
  position: absolute
  z-index: 10
</style>
