<template>
  <div class="container">
    <div class="section">
      <div class="favorites_box">
        <b-tabs v-model="activeTab"
                :animated="false"
                position="is-centered"
                :expanded="true"
        >
          <template v-for="tab in tabs">
            <b-tab-item
              :key="tab.id"
              :icon="tab.icon"
              :value="tab.id"
              :label="tab.label"
            >
              <keep-alive>
                <component :is="tab.component"
                           v-if="activeTab === tab.id"
                ></component>
              </keep-alive>
            </b-tab-item>
          </template>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NeedToken from '../components/Mixins/NeedToken'
import StoreFavoritesList from '@/components/Favorites/StoreFavoritesList'
import RecipeFavoritesList from '@/components/Favorites/RecipeFavoritesList'

export default {
  name: 'FavoritesView',
  mixins: [
    NeedToken
  ],
  metaInfo () {
    return {
      title: 'Favoritos'
    }
  },
  components: {
    StoreFavoritesList,
    RecipeFavoritesList
  },
  data () {
    return {
      activeTab: 'stores',
      tabs: [
        {
          id: 'stores',
          label: 'Tiendas',
          icon: 'store',
          component: 'store-favorites-list'
        },
        {
          id: 'recipes',
          label: 'Recetas',
          icon: 'food-takeout-box',
          component: 'recipe-favorites-list'
        }
      ]
    }
  },
  methods: {},
  computed: {
    ...mapGetters(['getUser'])
  }
}
</script>

<style scoped lang="sass">
.favorites_box
  background-color: #FFF
  border: 1px solid black
  border-radius: 10px
  width: 100%
  align-items: center
.favorites
  display: flex
  flex-direction: column
  align-items: center
:deep()
  .tab-content
    min-height: 5rem
</style>
