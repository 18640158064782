var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-dropdown',{attrs:{"value":_vm.params.ordering},on:{"input":e => _vm.$emit('changeSelect', {
                    val: e,
                    key: 'ordering'
                  })},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('b-icon',{attrs:{"icon":"filter-variant"}})]},proxy:true}])},_vm._l(([
                    { id: '-created', icon: 'new-box', label: 'Recientes', description: 'Ordenar las recetas por fecha' },
                    { id: '-likes', icon: 'thumb-up-outline', label: 'Populares', description: `Ordenar las recetas por ME GUSTA` },
                  ]),function(item){return _c('b-dropdown-item',{key:item.id,attrs:{"value":item.id}},[_c('div',{staticClass:"media"},[_c('b-icon',{staticClass:"media-left",attrs:{"icon":item.icon}}),_c('div',{staticClass:"media-content"},[_c('h3',[_vm._v(_vm._s(item.label))]),_c('small',[_vm._v(_vm._s(item.description))])])],1)])}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }