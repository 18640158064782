<template>
  <div class="searchFormResults" :class="{'has-right-click': isHero}">
    <div class="container">
      <div class="control">
        <b-select v-model="search.provinceId"
                  @input="submit"
                  class="searchFormResults__drop --prov"
        >
          <option v-for="province in provinces"
                  :key="province.name"
                  :value="province.id"
          >
            {{ province.name }}
          </option>
        </b-select>
      </div>
      <div class="searchFormResults__drop --partido">
        <b-autocomplete
          :loading="!currentLocations.length"
          v-model="locationName"
          :data="filteredLocations"
          field="name"
          placeholder="Buscar por partido"
          :open-on-focus="true"
          class="input-search control"
          @select="onChangeLocation"
          @blur="blurLocation"
          clearable
        >
          <!-- :icon-right="isHero ? 'magnify' : undefined"
          :icon-right-clickable="Boolean(isHero)" -->
          <template #empty>No hay resultados</template>
          <template slot-scope="{ option }">
            <div class="is-flex is-align-items-center">
              <b-icon v-if="option.id === -1"
                      icon="compass"
                      size="is-small">
              </b-icon>
              <div class="ml-3">{{ option.name }}</div>
            </div>
          </template>
        </b-autocomplete>
      </div>
    </div>
  </div>
</template>

<script>
import searchForm from './SearchForm'

export default {
  name: 'SearchFormResults',
  mixins: [
    searchForm
  ]
}
</script>

<style scoped lang="sass">
@import "src/assets/styles/variables"

.search-form
  color: white
  display: flex
  .select_province
    select
      border: 0 !important
      box-shadow: none !important
      color: var(--color-primary)
    &.--large
      min-width: 350px
:deep()
  .searchFormResults__drop
    height: 2.25rem
    // padding: 0 1rem
    .icon i:before
      font-size: 1rem
    .select select, input
      height: 2.25rem
      padding: 0 1rem
      border: none
      outline: none
      box-shadow: none
      font-weight: bold
      background-color: transparent
      font-size: 1rem
    .select, select
      height: 2.25rem
    .select
      padding: 0 2rem 0 0
    .select:not(.is-multiple):not(.is-loading)::after
      right: 1rem
    &.--partido
      min-width: 20rem
      .control
        border-radius: $border-radius
        background-color: $white
        .icon.is-right
          height: 2.3rem
    &.--prov
      &.control
        border: 1px solid $white
        background-color: transparent
        .select
          select
            color: $white
          &::after
            border-color: $white
.searchFormResults
  display: flex
  align-items: baseline
  .container
    display: flex
    width: 100%
    gap: 1rem
    .control
      border-radius: $border-radius
</style>
