<template>
  <div class="sendByMail">
    <div class="container">
      <div class="sendByMail__head">
        <div class="sendByMail__title">
          ¿Conoces lugares pero no tenés tiempo para contribuir?
        </div>
        <div class="sendByMail__subtitle">
          Podes enviarnos brevemente los datos por mail y nosotros lo subimos por vos.
        </div>
      </div>
      <div class="sendByMail__footer">
        <div class="sendByMail__footerLegend">
          ⚠️ No te olvides de decirnos el nombre, provincia y barrio/localidad del comercio.
        </div>
        <a href="mailto:celisana.ar@gmail.com">
          <button class="button is-rounded is-primary is-fullwidth"
                  v-text="isMobile ? 'Envianos comercios por mail' : 'Escribinos a celisana.ar@gmail.com'"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['isMobile']
}
</script>

<style scoped lang="sass">
@import "src/assets/styles/variables"

.sendByMail
  display: flex
  padding: 1rem
  justify-content: center
  margin-bottom: 2rem
  .container
    display: flex
    flex-direction: column
    justify-content: space-between
    min-height: 650px
    padding: 1.5rem 1rem 1rem
    background-image: url("../../assets/images/sendbymail.svg")
    background-size: auto 650px
    background-position: bottom
    background-repeat: no-repeat
    background-color: lighten($primary, 30%)
    border-radius: $section-radius
.sendByMail__head
  display: flex
  flex-direction: column
  gap: 1rem
  max-width: 500px
.sendByMail__title
  font-size: 1.75rem
  font-weight: bolder
.sendByMail__subtitle
  font-size: 1.2rem
.sendByMail__footer
  display: flex
  flex-direction: column
  max-width: 500px
  gap: 2rem
.sendByMail__footerLegend
  font-size: 1.2rem
  font-weight: bolder
  text-align: center
@media (min-width: 768px)
  .sendByMail
    .container
      padding: 2rem 3rem
      min-height: initial
      width: 100%
      display: flex
      justify-content: center
      background-position: right -200px
      background-size: auto 670px
      gap: 2rem
  .sendByMail__footer
    align-items: flex-start
  .sendByMail__footerLegend
    text-align: left
</style>
