<template>
  <div class="recipe_box">
    <b-loading v-if="!recipes.length" :is-full-page="false" v-model="loading"></b-loading>
    <div class="items_container__grid">
      <recipe-item v-for="(recipe, i) in recipes"
                  :key="i"
                  :recipe="recipe"
                  @click="e => $router.push({ name: 'store', params: { id: e.id} })"
      ></recipe-item>
    </div>
    <b-button v-if="hasMore"
              class="my-2"
              type="is-primary"
              :loading="loading"
              @click="getRecipes"
              :expanded="isMobile"
    >
      Ver mas
    </b-button>
    <b-message v-if="!totalRecipes && !loading"
               type="is-info"
    >
      Este usuario no ha creado ninguna receta hasta el momento.
    </b-message>
  </div>
</template>

<script>
import RecipeItem from '@/components/RecipeResults/RecipeItem'
import axios from 'axios'

export default {
  name: 'RecipeBox',
  props: {
    member: {
      required: true
    }
  },
  components: {
    RecipeItem
  },
  inject: ['isMobile'],
  data () {
    return {
      recipes: [],
      totalRecipes: 0,
      hasMore: false,
      loading: true,
      page: 0
    }
  },
  methods: {
    getRecipes: function () {
      this.loading = true
      this.page += 1
      const params = {
        author: this.member.user,
        size: 6,
        page: this.page
      }
      axios.get('recipes/list/', { params })
        .then(({ data }) => {
          this.recipes = this.recipes.concat(data.results)
          this.totalRecipes = data.count
          this.hasMore = data.next
        })
        .finally(() => { this.loading = false })
    }
  },
  created () {
    this.getRecipes()
  }
}
</script>

<style scoped lang="sass">
.recipe_box
  margin-top: 1rem
  min-height: 5rem
:deep(.StoreItem)
  width: 100%
.items_container__grid
  display: flex
  flex-direction: row
  flex-wrap: wrap
  justify-content: space-between
  gap: 1rem
@media (min-width: 1200px)
  :deep(.StoreItem)
    width: calc(50% - 1rem)
    margin: 1rem
</style>
