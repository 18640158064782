const difficulties = [
  {
    id: 1,
    name: 'Simple',
    type: 'is-success',
    icon: 'circle-slice-2'
  },
  {
    id: 2,
    name: 'Media',
    type: 'is-primary',
    icon: 'circle-slice-4'
  },
  {
    id: 3,
    name: 'Compleja',
    type: 'is-danger',
    icon: 'circle-slice-7'
  }
]

export default difficulties
