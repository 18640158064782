<template>
  <div class="CollaborationView">
    <!-- FOR SEO -->
    <h1 v-show="false">Nueva colaboracion</h1>

    <section class="CollaborationView__container">
      <h5 class="CollaborationView__title">¿Que tipo de contribución vas a realizar?</h5>
      <div class="CollaborationView__options">
        <div class="CollaborationView__card">
          <img :src="require('../assets/images/new-collaboration/store.svg')" alt="new_store">
          <b-button @click="goTo('new-store')">Registrar un comercio</b-button>
        </div>
        <div class="CollaborationView__card">
          <img :src="require('../assets/images/new-collaboration/recipe.svg')" alt="new_recipe">
          <b-button @click="goTo('new-recipe')">Crear una receta</b-button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { needLogin } from '@/modules/auth'
import { getMetaInfo } from '@/modules/meta'

export default {
  name: 'CollaborationView',
  metaInfo () {
    return getMetaInfo(
      'Nueva colaboración',
      'Compartí con Celisana lugares o recetas con toda la comunidad',
      'assets/images/hero1.svg'
    )
  },
  methods: {
    goTo: function (redirectOnSuccess = undefined) {
      needLogin(this, { redirectOnSuccess })
    }
  }
}
</script>

<style scoped lang="sass">
@import "../assets/styles/variables"

.CollaborationView
  display: flex
  flex: 1
  width: 100%
  padding: 1.75rem
  background-color: $primary
  background-image: url(../assets/images/pttrn.svg), url(../assets/images/hero__bg.svg)
  background-position: left top, left bottom
  background-repeat: repeat-x
  background-size: 10rem, auto 50%
  align-items: center
  overflow-y: hidden
  justify-content: center
  &__container
    display: flex
    height: 100%
    width: 100%
    max-width: 960px
    padding: 1rem 0.75rem 2rem
    flex-direction: column
    gap: 1rem
    border-radius: 1rem
    border: 1px solid $primary
    background: white
  &__options
    display: flex
    flex-direction: column
    width: 100%
    flex: 1
    gap: 1rem
  &__title
    font-size: 1.25rem
    line-height: 1.55rem
    padding: 0 1rem
    text-align: center
    font-weight: 900
  &__card
    position: relative
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    border-radius: 8px
    background: $primary
    color: white
    border: 1px solid black
    flex: 1
    overflow: hidden
    img
      height: 100%
      object-fit: cover
    button
      height: 3rem
      position: absolute
      bottom: .75rem
      border: 1px solid black
      font-size: 1rem
      box-shadow: 0px 5px 0px 0px rgba(93, 93, 93, 0.50)
@media (min-width: 768px)
  .CollaborationView
    border-radius: $border-radius
    &__container
      height: auto
      min-height: 400px
    &__options
      flex-direction: row
    &__title
      font-size: 1.5rem
      margin: 1rem 0
</style>
