<template>
  <div class="container-fluid">
    <!-- FOR SEO -->
    <h1 v-show="false">Lugares gluten free de {{ this.fullLocation }}</h1>
    <SearchFormMobile v-if="isMobile"
                      :isLoading="isLoading"
                      :default-value="$route.query"
    />
    <div class="header__top" v-else>
      <div class="header">
        <div class="container">
          <SearchFormResults :default-value="$route.query" />
          <div class="header__container">
            <span class="results_count">
              <b-skeleton v-if="isLoading" width="150px" />
              <span v-else>
                {{ total }} <b>{{ total === 1 ? 'Resultado' : 'Resultados' }}</b>
              </span>
            </span>
            <div class="filter__container">
              <filter-buttons :is-loading="isLoading"></filter-buttons>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="container-results">
      <component
        :is="isMobile ? 'items-container-mobile': 'items-container'"
        :results="results"
        :isLoading="isLoading"
        :coordsList="coordsList"
        :lastSearch="lastSearch"
        :page="page"
        :total="total"
        :size="size"
        @change-page="changePage"
      >
      </component>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import { mapGetters } from 'vuex'
import { sortBy } from 'lodash'
import ItemsContainer from '@/components/StoreResults/ItemsContainer'
import ItemsContainerMobile from '@/components/StoreResults/ItemsContainerMobile'
import SearchFormMobile from '@/components/Layout/SearchFormMobile'
import SearchFormResults from '@/components/Layout/SearchFormResults'
import FilterButtons from '@/components/StoreResults/FilterButtons'
import { getCoordsList } from '@/modules/storeManager'
import BackPopState from '../components/Mixins/BackPopState'
import { getMetaInfo } from '@/modules/meta'

export default {
  name: 'StoreResultsView',
  inject: [
    'isMobile'
  ],
  components: {
    ItemsContainer,
    ItemsContainerMobile,
    SearchFormResults,
    SearchFormMobile,
    FilterButtons
  },
  mixins: [
    BackPopState
  ],
  metaInfo () {
    return getMetaInfo(
      `Lugares gluten free de ${this.fullLocation}`,
      `Lugares gluten free de ${this.fullLocation}. Encontrá más lugares en nuestro mapa interactivo`,
      'assets/images/hero2.svg'
    )
  },
  data () {
    return {
      lastSearch: {},
      isLoading: false,
      results: [],
      total: undefined,
      page: 1
    }
  },
  watch: {
    getCurrentSearch: {
      deep: true,
      handler () {
        this.callApi(this.getCurrentSearch)
      }
    }
  },
  methods: {
    changePage: function (page) {
      this.page = page
      this.lastSearch.page = page
      this.callApi(this.lastSearch)
    },
    updateUrl: function (params) {
      const locationId = params.get('location')
      params.delete('location')
      params.append('locationId', locationId)
      window.history.pushState(params.toString(), '', '?' + params.toString())
    },
    callApi: function (payload) {
      this.lastSearch = Object.assign({}, payload)
      // when user is backing from store details page
      if (this.$store.getters.getLastResults) {
        const data = this.$store.getters.getLastResults
        this.page = data.page
        this.total = data.count
        this.results = data.results
        this.$store.commit('setLastResults', undefined)
      } else {
        this.page = parseInt(payload.page) || 1
        const params = new URLSearchParams()
        params.append('ordering', payload.ordering || '-avg_score,id')
        params.append('provinceId', payload.provinceId)
        params.append('location', payload.locationId || '')
        if (payload.search) {
          params.append('search', payload.search)
        }
        if (payload.types && payload.types.length > 0) {
          if (payload.types.length > 1) {
            payload.types.forEach(x => {
              params.append('types', x)
            })
          } else {
            params.append('types', payload.types)
          }
        }
        params.append('page', this.page)
        params.append('size', this.size)
        this.isLoading = true
        axios.get(`provinces/${payload.provinceId}/stores/${payload.locationId || 'all'}/`, {
          params
        })
          .then(({ data }) => {
            this.total = data.count
            this.results = getCoordsList(data.results, this.$store.getters.getUserCoords)
          })
          .catch((e) => {
            this.$buefy.snackbar.open({
              duration: 3000,
              message: 'Se produjo un error en la busqueda. Por favor reintente mas tarde',
              type: 'is-danger',
              position: 'is-bottom-right',
              cancelText: 'Cerrar'
            })
            // eslint-disable-next-line no-console
            console.log('error', e)
          })
          .finally(() => {
            this.updateUrl(params)
            this.isLoading = false
            if (this.isMobile) {
              this.$nextTick(() => {
                window.scroll({
                  top: 0,
                  behavior: 'smooth'
                })
              })
            }
          })
      }
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentSearch',
      'getCurrentLocation',
      'getCurrentProvince'
    ]),
    coordsList: function () {
      return sortBy(this.results, ['distance'])
    },
    fullLocation: function () {
      let fullLocation
      if (this.getCurrentProvince) {
        fullLocation = [this.getCurrentLocation, this.getCurrentProvince].filter(x => x).join(' ')
      }
      return fullLocation
    },
    size: function () {
      return this.isMobile ? 30 : 75
    }
  },
  created () {
    if (this.$store.getters.getIsFirstVisit) {
      this.$store.commit('setIsFirstVisit', false)
      this.$buefy.snackbar.open({
        message: 'Activa tu ubicación para una mejor experiencia',
        duration: 10 * 1000
      })
    }
    this.$store.commit('setSearch', this.$route.query)
  }
}
</script>

<style lang="sass">
.header > .container
  display: flex
  flex-direction: column
  gap: .75rem
</style>

<style lang="sass" scoped>
@import "src/assets/styles/variables"

.container-fluid
  background-color: $primary
  background-size: auto 45vh
  background-repeat: repeat no-repeat
  background-position: bottom
  border-radius: $border-radius $border-radius 0 0
  height: 100%
.header
  &__container
    align-items: center
    overflow-x: auto
    .results_count
      min-width: 10rem
      min-height: 2rem
      border: none
      color: #21243d
      font-size: 1.25rem
      font-weight: 600
      line-height: 1.6
      display: flex
      align-items: center
      color: $secondary
      b
        color: #FFF
.container-results
  margin-top: 66px
.header__container
  gap: 1rem
  height: 2.5rem
.filter__container
  min-height: 2rem
@media (min-width: 768px)
  .filter__container
    align-items: center
    overflow-x: auto
  .header
    &__container
      display: flex
  .container-results
    margin-top: 0
  .container-fluid
    gap: 0.75rem
    display: flex
    flex-direction: column
    padding: .5rem 1rem
    border-radius: $border-radius
</style>
