<template>
  <transition name="fade" mode="out-in">
    <div class="header__top" v-if="show">
      <div class="container-fluid">
        <div class="container">
          <h1 class="title">
            {{ $route.meta.title }}
          </h1>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'HeaderView',
  inject: ['isMobile'],
  computed: {
    show: function () {
      return this.isMobile && this.$route.meta.onlyDesktop ? false : this.$route.meta.title
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../assets/styles/variables"

.header__top
  display: flex
  flex-direction: column
  width: 100%
  min-height: 105px
  background-color: $primary
  padding: 0.25rem
  //background-image: url('../../assets/images/hero__bg.svg')
  background-size: auto 80%
  background-position: bottom left
  background-repeat: repeat no-repeat
  justify-content: center
  .title
    padding: 2rem 1rem
    width: 100%
    color: $white
@media (min-width: 768px)
  .header__top
    padding: 0
    min-height: initial
</style>
